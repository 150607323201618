export const onboardingSteps = t => [
  { label: t('dossier.steps.1') },
  { label: t('dossier.steps.2') },
  { label: t('dossier.steps.3') },
  { label: t('dossier.steps.4') },
];

export const prepareField = field => {
  return { ...field, ref: null, innerRef: field.ref };
};
