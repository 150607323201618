import classNames from 'classnames';
import { Password as PasswordImpl } from 'primereact/password';
import styles from './styles.module.scss';

export const Password = ({
  className,
  inputClassName,
  value,
  title,
  placeholder,
  onChange,
  readOnly,
  error,
  filled,
  id,
  disabled = false,
  special = false,
  innerRef,
  ...props
}) => {
  return (
    <div>
      <PasswordImpl
        id={id}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        readOnly={readOnly}
        aria-describedby={id + '-help'}
        inputClassName={classNames(styles.Password, inputClassName)}
        className={classNames(
          styles.PasswordContainer,
          error && styles.Error,
          filled && styles.Filled,
          disabled && styles.Disabled,
          special && styles.Special,
          className
        )}
        disabled={disabled}
        ref={innerRef}
        {...props}
      />
      {error?.message && <span className="Body Small Regular Red500">{error.message}</span>}
    </div>
  );
};
