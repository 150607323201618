import { checkAlt } from 'assets';
import classNames from 'classnames';
import { Checkbox as Check } from 'primereact/checkbox';
import styles from '../styles.module.scss';

export const Box = ({ id, checked, onChange, onClick, innerRef, onBlur, ...props }) => {
  return (
    <div>
      <div
        className={classNames(styles.Checkbox__Box, checked && styles.Checked)}
        role="checkbox"
        tabIndex={0}
        onKeyDown={onClick}
        aria-checked
        onClick={onClick}
      >
        <img src={checkAlt} alt="" className={styles.Vector} />
      </div>
      <Check
        id={id}
        className={styles.RealCheckbox}
        inputRef={innerRef}
        checked={checked}
        onChange={onChange}
        onBlur={onBlur}
        {...props}
      />
    </div>
  );
};
