import classNames from 'classnames';
import styles from './styles.module.scss';

export const SlidingPanel = ({ children, active, className, greyedOut }) => {
  const panel = (
    <div className={classNames(styles.Panel__Wrap, className, { [styles.active]: active })}>
      <div className={styles.Panel}>{children}</div>
    </div>
  );

  return (
    <div
      className={classNames(
        greyedOut && [
          'p-dialog-mask',
          'p-dialog-center',
          'p-component-overlay',
          'p-component-overlay-enter',
          'p-dialog-visible',
          'z-5',
        ]
      )}
    >
      {panel}
    </div>
  );
};
