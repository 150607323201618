import { Left } from 'assets';
import classNames from 'classnames';
import { EmailModule, TipBanner } from 'components';
import { eventTypes } from 'constants/constants';
import { useHistoryImpl } from 'hooks/utils/useHistoryImpl';
import { useTranslation } from 'hooks/utils/useTranslation';
import { useState } from 'react';
import styles from './styles.module.scss';
import { useCompany } from 'hooks/state/useCompany';
import { routesPaths } from 'routes/routesPaths';
import { useSelector } from 'react-redux';
import { selectCalendar } from 'state/slices/calendarSlice';

export const EmailBuilder = ({ campaign, parentType }) => {
  const t = useTranslation();
  const [showTip, setShowTip] = useState(true);
  const { selectedDate } = useSelector(selectCalendar);
  const { selectedShareToken: token, selectedCompany } = useCompany();
  const { push } = useHistoryImpl();

  const onClickBack = () =>
    push(
      routesPaths.calendar +
        '?' +
        new URLSearchParams({
          ...selectedDate,
          company: selectedCompany,
          ...(token && { token }),
        }).toString()
    );

  return (
    <>
      <div
        className={classNames(
          styles.EmailBuilder,
          styles[eventTypes[parentType || eventTypes.campaign] || 'other']
        )}
      >
        <div className={styles.Header}>
          <button className={styles.Back} onClick={onClickBack}>
            <img src={Left} alt="Back" />
          </button>
          <div className={styles.CampaignInfo}>
            <div className={classNames(styles.Parent, styles.Title, 'Body Bold Medium')}>
              {campaign?.[parentType]?.name}
            </div>
            <div className={styles.TitleSection}>
              <div className={styles.Dot} />
              <div className="Title Large ">
                {[campaign?.title, campaign?.[parentType]?.name].filter(Boolean).join(' - ')}
              </div>
            </div>
            <div className="Subtitle Regular Blue600">{campaign?.description}</div>
          </div>
        </div>
        <TipBanner
          text={t('emailLayout.builder.tip')}
          active={showTip}
          onClose={() => setShowTip(false)}
        />
        <div className={styles.Modules}>
          {campaign.modules.map(module => (
            <EmailModule module={module} key={module.pk} campaignId={campaign.pk} />
          ))}
        </div>
      </div>
    </>
  );
};
