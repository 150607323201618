import { PrivacyPolicy, TermsConditions } from 'assets';
import {
  Button,
  FeatureFlagAsync,
  GoogleButton,
  Input,
  Layout,
  LoaderHOC,
  Password,
  TextCheckbox,
} from 'components';
import { ToggleQuestion } from 'components/form/ToggleQuestion';
import { featureFlagKeys } from 'constants/featureFlags';
import { useSignupForm } from 'hooks/form/useSignupForm';
import { signupSchemaKeys } from 'hooks/schemas/useSignupSchema';
import { useTranslation } from 'hooks/utils/useTranslation';
import { Card } from 'primereact/card';
import { Controller } from 'react-hook-form';
import { Link, Redirect } from 'react-router-dom';
import { routesPaths } from 'routes/routesPaths';
import { prepareField } from 'utils/onboardingSteps';
import styles from './styles.module.scss';

export const Signup = () => {
  const t = useTranslation();
  const {
    extraFields,
    googleSignup,
    authenticated,
    isLoading,
    terms,
    toggleTerms,
    onSubmit,
    form: { control },
  } = useSignupForm();

  if (authenticated) {
    return <Redirect to={routesPaths.index} />;
  }

  return (
    <Layout className={styles.Layout} menuTheme="light">
      <div className={styles.Container}>
        <Card
          title={t('signup.labels.title')}
          subTitle={t('signup.labels.subtitle')}
          className={styles.Card}
        >
          <div className="button-container">
            <LoaderHOC isLoading={isLoading}>
              <FeatureFlagAsync featureKey={featureFlagKeys.BB_MANUAL_SIGN_IN}>
                {extraFields && (
                  <ToggleQuestion title={t('signup.labels.walkthrough')} className={'mb-5'} />
                )}
                <form className="mb-3" onSubmit={onSubmit}>
                  <Controller
                    name={signupSchemaKeys.email}
                    control={control}
                    render={({ field, fieldState }) => (
                      <Input
                        id={field.name}
                        special
                        inputClassName={'w-full mb-2'}
                        error={fieldState.error}
                        placeholder={t('signup.labels.email')}
                        {...prepareField(field)}
                      />
                    )}
                  />
                  <Controller
                    name={signupSchemaKeys.password1}
                    control={control}
                    render={({ field, fieldState }) => (
                      <Password
                        id={field.name}
                        special
                        className={'w-full mb-2'}
                        inputClassName="w-full"
                        feedback={false}
                        error={fieldState.error}
                        placeholder={t('signup.labels.password1')}
                        {...prepareField(field)}
                      />
                    )}
                  />
                  <Controller
                    name={signupSchemaKeys.password2}
                    control={control}
                    render={({ field, fieldState }) => (
                      <Password
                        id={field.name}
                        special
                        className={'w-full mb-3'}
                        inputClassName="w-full"
                        feedback={false}
                        error={fieldState.error}
                        placeholder={t('signup.labels.password2')}
                        {...prepareField(field)}
                      />
                    )}
                  />
                  <Button
                    disabled={!terms}
                    type="submit"
                    title={'Sign Up'}
                    className="w-full mt-3"
                    size="Small"
                  />
                </form>
                <div className="OrSeparator">
                  <div className="start" />
                  <div className="middle Body Small Regular">OR</div>
                  <div className="end" />
                </div>
              </FeatureFlagAsync>
              <GoogleButton
                onClick={googleSignup}
                title={t('signup.labels.google')}
                disabled={!terms}
              />
              <div className={styles.SignIn}>
                <span>{t('signup.labels.hasAccount')}</span>
                <Link to={routesPaths.login} className={styles.Link}>
                  {t('signup.labels.signin')}
                </Link>
              </div>
              <TextCheckbox
                title={t('signup.labels.terms')}
                className="mb-0"
                checked={terms}
                onChange={toggleTerms}
              />
              {extraFields && (
                <TextCheckbox title={t('signup.labels.marketingEmails')} className="mb-0" />
              )}
            </LoaderHOC>
          </div>
        </Card>
        <div>
          <span className="Body Regular Small">{t('signup.agreeTerms1')}</span>
          <a href={TermsConditions} className="Body Bold Small Orange400 no-underline">
            {t('signup.terms')}
          </a>
          <span className="Body Regular Small">{t('global.and')}</span>
          <a href={PrivacyPolicy} className="Body Bold Small Orange400 no-underline">
            {t('signup.policy')}
          </a>
          <span className="Body Regular Small">{t('signup.agreeTerms2')}</span>
        </div>
      </div>
    </Layout>
  );
};
