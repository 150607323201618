import { combineReducers } from 'redux';

import { api } from 'services/api';
import authReducer from './slices/authSlice';
import calendarReducer from './slices/calendarSlice';
import settingsReducer from './slices/settingsSlice';

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  auth: authReducer,
  settings: settingsReducer,
  calendar: calendarReducer,
});

export default rootReducer;
