import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
});

export const actions = settingsSlice.actions;

export default settingsSlice.reducer;
