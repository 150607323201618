import { chevronDown, trashAlt } from 'assets';
import { useDeleteMonthlySettingsEvent } from 'hooks/calendar/useDeleteMonthlySettingsEvent';
import { useTranslation } from 'hooks/utils/useTranslation';
import { ConfirmDialog } from '../ConfirmDialog';
import styles from './styles.module.scss';

export const ExpandItem = ({ text, children, open, onClick, listName, itemId }) => {
  const t = useTranslation();
  const { onDeleteItem, onClickDelete, showDialog, onCloseDialog } = useDeleteMonthlySettingsEvent({
    listName,
    itemId,
  });

  if (open) return children;

  return (
    <div className={styles.ExpandItem}>
      <div className="Body Bold Large">{text}</div>
      <button className={styles.Btn} onClick={onClickDelete}>
        <img src={trashAlt} alt="delete" />
      </button>
      <button className={styles.Btn} onClick={onClick}>
        <img src={chevronDown} alt="expand" />
      </button>
      <ConfirmDialog
        visible={showDialog}
        title={t('monthlySettings.deleteEvent.confirm.title')}
        onClose={onCloseDialog}
        onConfirm={onDeleteItem}
        cancelTitle={t('monthlySettings.deleteEvent.confirm.cancel')}
        confirmTitle={t('monthlySettings.deleteEvent.confirm.confirm')}
      />
    </div>
  );
};
