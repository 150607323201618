import './App.scss';
import { RouteFromPath } from 'components';
import { useTranslation } from 'hooks/utils/useTranslation';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { routes } from 'routes';
import { selectAuth } from 'services/auth/auth';

function App() {
  const t = useTranslation();
  const { authenticated } = useSelector(selectAuth);

  return (
    <>
      <Helmet>
        <title>{t('global.pageTitle')}</title>
      </Helmet>
      <ToastContainer />
      <BrowserRouter>
        <Switch>
          {routes.map(route => (
            <RouteFromPath key={`route-${route.path}`} {...route} authenticated={authenticated} />
          ))}
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
