import { maxNumberField } from 'constants/constants';
import { z } from 'zod';

export const monthlySettingsSchemaKeys = {
  number_of_campaigns: 'number_of_campaigns',
  number_of_flows: 'number_of_flows',
};

export const useMonthlySettingsSchema = () => {
  return {
    schema: z.object({
      number_of_campaigns: z.number().max(maxNumberField),
      number_of_flows: z.number().max(maxNumberField),
    }),
    defaultValues: {
      number_of_campaigns: null,
      number_of_flows: null,
    },
  };
};
