import { Expand } from 'assets';
import { ImageViewer, Overlay } from 'components';
import { componentStates, posthogEvents, posthogOptions } from 'constants/posthog';
import { useTranslation } from 'hooks/utils/useTranslation';
import { posthog } from 'posthog-js';
import { useState } from 'react';
import styles from './styles.module.scss';

export const EmailDesignExample = ({ designExample, index }) => {
  const t = useTranslation();
  const [open, setOpen] = useState(false);
  const onClickPreview = () => {
    posthog.capture(
      posthogEvents.pages.emailLayout.module.CLICK_PREVIEW_DESIGN_EXAMPLE,
      posthogOptions({ componentState: open ? componentStates.CLOSE : componentStates.OPEN })
    );
    setOpen(!open);
  };

  return (
    <>
      <div className={styles.EmailDesignExample}>
        <div className={styles.Preview}>
          <img
            className={styles.Image}
            src={designExample.preview}
            alt={t('a11y.alternativePreview')}
          />
        </div>
        <button className={styles.ViewImage} onClick={onClickPreview}>
          <img src={Expand} alt={t('a11y.expand')} />
        </button>
        <div className={styles.Index}>{index}</div>
      </div>
      <Overlay active={open}>
        <ImageViewer images={[designExample.url]} onClose={onClickPreview} />
      </Overlay>
    </>
  );
};
