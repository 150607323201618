import { maxNumberField } from 'constants/constants';
import { z } from 'zod';

export const promotionsSchemaKeys = {
  name: 'name',
  start_date: 'start_date',
  end_date: 'end_date',
  aggressiveness: 'aggressiveness',
  offer: 'offer',
  on_sale: 'on_sale',
  tease_prior_to_launch: 'tease_prior_to_launch',
  monthly_setting: 'monthly_setting',
};

export const usePromotionsSchema = () => {
  return {
    schema: z
      .object({
        name: z.string().min(1),
        start_date: z.string(),
        end_date: z.string(),
        aggressiveness: z.number().max(maxNumberField),
        offer: z.number(),
        on_sale: z.number(),
        tease_prior_to_launch: z.boolean(),
      })
      .refine(
        data => {
          return data.end_date.localeCompare(data.start_date) >= 0;
        },
        { message: 'End date should be after start date', path: ['end_date'] }
      ),
    defaultValues: {
      name: '',
      start_date: null,
      end_date: null,
      aggressiveness: 1,
      offer: null,
      on_sale: null,
      tease_prior_to_launch: false,
    },
  };
};
