import {
  FILETYPES_COLLECTION_CATALOG,
  FILETYPES_PRODUCT_CATALOG,
  maxBrandGoals,
  maxBrandWords,
  MAX_LENGTH_COMPANY_NAME,
  MAX_LENGTH_COMPANY_URL,
  MAX_LENGTH_USER_NAME,
  MIN_AMOUNT_BRAND_GOALS,
  MIN_AMOUNT_BRAND_WORDS,
  MIN_AMOUNT_INDUSTRY_TYPES,
  MIN_LENGTH_COMPANY_NAME,
  MIN_LENGTH_COMPANY_URL,
  MIN_LENGTH_USER_NAME,
} from 'constants/constants';
import { z } from 'zod';

export const settingsSchemaKeys = {
  user_name: 'user_name',
  user_role: 'user_role',
  company_name: 'company_name',
  annual_revenue: 'annual_revenue',
  sku: 'sku',
  company_url: 'company_url',
  brand_words: 'brand_words',
  brand_goals: 'brand_goals',
  industry_types: 'industry_types',
  industries: 'industries',
  bundle_offerings: 'bundle_offerings',
  subscription_offerings: 'subscription_offerings',
  product_catalog: 'product_catalog',
  collection_catalog: 'collection_catalog',
};

export const useSettingsSchema = defaultSettings => {
  return {
    schema: z.object({
      user_name: z.string().max(MAX_LENGTH_USER_NAME).min(MIN_LENGTH_USER_NAME),
      user_role: z.number(),
      company_name: z.string().max(MAX_LENGTH_COMPANY_NAME).min(MIN_LENGTH_COMPANY_NAME),
      annual_revenue: z.number(),
      sku: z.number(),
      company_url: z.string().max(MAX_LENGTH_COMPANY_URL).min(MIN_LENGTH_COMPANY_URL),
      brand_words: z.number().array().min(MIN_AMOUNT_BRAND_WORDS).max(maxBrandWords),
      brand_goals: z.number().array().min(MIN_AMOUNT_BRAND_GOALS).max(maxBrandGoals),
      industry_types: z.number().array().min(MIN_AMOUNT_INDUSTRY_TYPES),
      industries: z.number().array(),
      bundle_offerings: z.boolean(),
      subscription_offerings: z.boolean(),
      product_catalog: z
        .any()
        .refine(
          files => !files?.length || FILETYPES_PRODUCT_CATALOG.includes(files?.[0]?.type),
          'CSV files are accepted.'
        ),
      collection_catalog: z
        .any()
        .refine(
          files => !files?.length || FILETYPES_COLLECTION_CATALOG.includes(files?.[0]?.type),
          'CSV files are accepted.'
        ),
    }),
    defaultValues: {
      user_name: defaultSettings?.user_name || '',
      user_role: defaultSettings?.user_role || undefined,
      company_name: defaultSettings?.company_name || '',
      annual_revenue: defaultSettings?.annual_revenue || undefined,
      sku: defaultSettings?.sku || undefined,
      company_url: defaultSettings?.company_url || '',
      brand_words: defaultSettings?.brand_words || [],
      brand_goals: defaultSettings?.brand_goals || [],
      industry_types: defaultSettings?.industry_types || [],
      industries: defaultSettings?.industries || [],
      bundle_offerings: defaultSettings?.bundle_offerings || false,
      subscription_offerings: defaultSettings?.subscription_offerings || false,
      product_catalog: null,
      collection_catalog: null,
    },
  };
};
