import en from './en/en.json';
import en_dossier from './en/dossier.json';
import en_monthlySettings from './en/monthly_settings.json';
import en_emailLayout from './en/email_layout.json';
import en_a11y from './en/a11y.json';
import en_campaignDetails from './en/campaign_details.json';
import en_calendar from './en/calendar.json';
import en_eventDetails from './en/event_details.json';
import en_login from './en/login.json';
import en_signup from './en/signup.json';
import en_notFound from './en/notFound.json';
import en_settings from './en/settings.json';
import en_recoverPassword from './en/recover_password.json';

export const locales = {
  en: {
    ...en,
    a11y: en_a11y,
    dossier: en_dossier,
    monthlySettings: en_monthlySettings,
    emailLayout: en_emailLayout,
    campaignDetails: en_campaignDetails,
    calendar: en_calendar,
    eventDetails: en_eventDetails,
    login: en_login,
    signup: en_signup,
    notFound: en_notFound,
    settings: en_settings,
    passwordRecovery: en_recoverPassword,
  },
};
