import { useHistory } from 'react-router-dom';

export const useHistoryImpl = () => {
  const { push, go, goBack, goForward, ...history } = useHistory();
  const intercomUpdate = 'update';

  const updateIntercom =
    func =>
    (...params) => {
      func(...params);
      window.Intercom(intercomUpdate);
    };

  return {
    ...history,
    push: updateIntercom(push),
    go: updateIntercom(go),
    goBack: updateIntercom(goBack),
    goForward: updateIntercom(goForward),
  };
};
