import styles from './styles.module.scss';
import { Dropdown as Select } from 'primereact/dropdown';
import classNames from 'classnames';

export const Dropdown = ({
  options,
  value,
  onChange,
  placeholder,
  optionValue,
  disabled,
  special,
  className,
  id,
  title,
  error,
  filled,
  appendTo = 'self',
  innerClassName,
  innerRef,
  ...props
}) => {
  return (
    <span
      className={classNames(
        styles.DropdownContainer,
        error && styles.Error,
        filled && styles.Filled,
        disabled && styles.Disabled,
        special && styles.Special,
        className
      )}
    >
      <label htmlFor={id}>{title}</label>
      <Select
        id={id}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        options={options}
        appendTo={appendTo}
        optionValue={optionValue}
        className={classNames(styles.Dropdown, innerClassName)}
        disabled={disabled}
        ref={innerRef}
        {...props}
      />
    </span>
  );
};
