import { Figma, Height, Layout, Right, Share, Width, zoomIn } from 'assets';
import classNames from 'classnames';
import { Button, EmailBrief, ImageViewer, Overlay, ShareDialog } from 'components';
import { useTranslation } from 'hooks/utils/useTranslation';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useExportLayoutMutation } from 'services/emailLayout';
import { ProgressBar } from 'primereact/progressbar';
import styles from './styles.module.scss';
import { posthog } from 'posthog-js';
import { componentStates, posthogEvents, posthogOptions } from 'constants/posthog';
import { FeatureFlag } from 'components/common/FeatureFlag';
import { featureFlagKeys } from 'constants/featureFlags';
import { useCompany } from 'hooks/state/useCompany';
const { EXPORT_FIGMA, CLICK_PREVIEW } = posthogEvents.pages.emailLayout;

export const EmailMenu = ({ campaign, parentType, campaignId }) => {
  const t = useTranslation();
  const [exportLayout, { isLoading }] = useExportLayoutMutation();
  const mustCompleteBrief = !campaign.modules?.length;
  const [showBrief, setShowBrief] = useState(mustCompleteBrief);
  const [preview, setPreview] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const { isOwnedCompany } = useCompany();

  const details = [
    {
      label: t('emailLayout.menu.modules'),
      img: Layout,
      value: campaign.modules?.length || 0,
      order: 1,
    },
    {
      label: t('emailLayout.menu.width'),
      img: Width,
      value: `${campaign.width || '???'}px`,
      order: 2,
    },
    {
      label: t('emailLayout.menu.height'),
      img: Height,
      value: `${campaign.height || '???'}px`,
      order: 3,
    },
  ];

  const onClickExport = () => {
    exportLayout(campaign.pk);
    toast.info(t('emailLayout.export.success'), {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2500,
      hideProgressBar: true,
    });
    posthog.capture(EXPORT_FIGMA, posthogOptions({ campaignId: campaign.pk }));
  };

  const onClickPreview = () => {
    setPreview(true);
    posthog.capture(CLICK_PREVIEW, posthogOptions({ componentState: componentStates.OPEN }));
  };

  const onCloseImageViewer = () => {
    setPreview(false);
    posthog.capture(CLICK_PREVIEW, posthogOptions({ componentState: componentStates.CLOSE }));
  };

  return (
    <>
      <div className={styles.EmailMenu}>
        <div className={styles.EmailDetails}>
          <div className="Title ExtraSmall Blue500 mb-3">{t('emailLayout.menu.title')}</div>
          {details.map(detail => (
            <div className={classNames(styles.Detail, 'mb-2')} key={detail.order}>
              <img src={detail.img} alt="module detail" />
              <span className="Body Bold Medium">{detail.label}</span>
              <span className="Body Regular Medium">{detail.value}</span>
            </div>
          ))}
          <Button
            disabled={!isOwnedCompany}
            palette="Primary"
            theme="Dark"
            size="Small"
            title={t('emailLayout.menu.emailBrief')}
            icon={Right}
            className={styles.EmailBrief__CTA}
            iconClassName={styles.EmailBrief__CTA__Icon}
            onClick={() => setShowBrief(true)}
          />
        </div>
        <div className={classNames('Separator', styles.Separator)} />
        <Button
          palette="Primary"
          theme="Orange"
          size="Small"
          title={t('emailLayout.menu.export')}
          icon={Figma}
          iconPos="Left"
          iconClassName={styles.EmailBrief__CTA__Icon}
          className={styles.Menu__CTA}
          onClick={onClickExport}
          disabled={isLoading}
        />
        <FeatureFlag featureKey={featureFlagKeys.BB_SHARE_EMAIL_LAYOUT}>
          <Button
            palette="Secondary"
            theme="Dark"
            size="Small"
            title={t('emailLayout.menu.share')}
            icon={Share}
            iconPos="Left"
            iconClassName={styles.EmailBrief__CTA__Icon}
            className={styles.Menu__CTA}
            onClick={() => {
              setShowShare(true);
            }}
          />
        </FeatureFlag>
        <Button
          palette="Secondary"
          theme="Dark"
          size="Small"
          title={t('emailLayout.menu.preview')}
          icon={zoomIn}
          iconPos="Left"
          iconClassName={styles.EmailBrief__CTA__Icon}
          className={styles.Menu__CTA}
          onClick={onClickPreview}
        />
      </div>
      <EmailBrief
        active={showBrief}
        onClose={() => setShowBrief(false)}
        campaign={campaign}
        parentType={parentType}
        mustComplete={mustCompleteBrief}
        campaignId={campaignId}
      />
      <Overlay active={preview}>
        <ImageViewer images={campaign.modules.map(mod => mod.url)} onClose={onCloseImageViewer} />
      </Overlay>
      {showShare && <ShareDialog visible={showShare} onHide={() => setShowShare(false)} />}
      <ProgressBar mode="indeterminate" className="GlobalProgressBar" hidden={!isLoading} />
    </>
  );
};
