import { createApi } from '@reduxjs/toolkit/query/react';
import { tagTypes } from 'constants/constants';
import { baseQueryWithReauth } from 'utils/customQueries';

// initialize an empty api service that we'll inject endpoints into later as needed
export const api = createApi({
  reducerPath: 'api',
  tagTypes: Object.values(tagTypes),
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});
