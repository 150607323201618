import { PrivacyPolicy, TermsConditions } from 'assets';
import {
  Button,
  FeatureFlagAsync,
  GoogleButton,
  Input,
  Layout,
  LoaderHOC,
  RecoveryEmailCard,
  TextCheckbox,
} from 'components';
import { Password } from 'components/common/Password';
import { featureFlagKeys } from 'constants/featureFlags';
import { useLoginForm } from 'hooks/form/useLoginForm';
import { manualLoginSchemaKeys } from 'hooks/schemas/useManualLoginSchema';
import { Card } from 'primereact/card';
import { Controller } from 'react-hook-form';
import { Link, Redirect } from 'react-router-dom';
import { routesPaths } from 'routes/routesPaths';
import { prepareField } from 'utils/onboardingSteps';
import styles from './styles.module.scss';

export const Login = () => {
  const {
    form: { control },
    onSubmit,
    t,
    terms,
    onChangeTerms,
    authenticated,
    isLoading,
    googleLogin,
    showRecover,
    setShowRecover,
  } = useLoginForm();

  if (authenticated) {
    return <Redirect to={routesPaths.index} />;
  }

  return (
    <Layout className={styles.Layout} menuTheme="light">
      <div className={styles.Container}>
        <Card title={t('login.labels.welcome')} className={styles.Card}>
          <div className="button-container">
            <LoaderHOC isLoading={isLoading}>
              <FeatureFlagAsync featureKey={featureFlagKeys.BB_MANUAL_SIGN_IN}>
                <form className="mb-3" onSubmit={onSubmit}>
                  <Controller
                    name={manualLoginSchemaKeys.email}
                    control={control}
                    render={({ field, fieldState }) => (
                      <Input
                        id={field.name}
                        special
                        inputClassName={'w-full mb-3'}
                        error={fieldState.error}
                        placeholder={t('login.labels.email')}
                        {...prepareField(field)}
                      />
                    )}
                  />
                  <Controller
                    name={manualLoginSchemaKeys.password}
                    control={control}
                    render={({ field, fieldState }) => (
                      <Password
                        id={field.name}
                        special
                        className={'w-full mb-3'}
                        inputClassName="w-full"
                        feedback={false}
                        error={fieldState.error}
                        placeholder={t('login.labels.password')}
                        {...prepareField(field)}
                      />
                    )}
                  />
                  <Button
                    type="submit"
                    title={t('login.labels.cta')}
                    className="w-full mt-3"
                    size="Small"
                    disabled={!terms}
                  />
                </form>
                <div className="OrSeparator">
                  <div className="start" />
                  <div className="middle Body Small Regular uppercase">{t('global.or')}</div>
                  <div className="end" />
                </div>
              </FeatureFlagAsync>
              <GoogleButton
                onClick={googleLogin}
                title={t('login.labels.google')}
                disabled={!terms}
              />
              <span className={styles.Register}>
                {t('login.labels.newBackbone')}
                <Link to={routesPaths.signup} className={styles.Link}>
                  {t('login.labels.register')}
                </Link>
              </span>
              <FeatureFlagAsync featureKey={featureFlagKeys.BB_MANUAL_SIGN_IN}>
                <button className={styles.ForgotPassword} onClick={() => setShowRecover(true)}>
                  <div className="Body Medium Bold Orange400">
                    {t('login.labels.forgotPassword')}
                  </div>
                </button>
                <RecoveryEmailCard visible={showRecover} onHide={() => setShowRecover(false)} />
              </FeatureFlagAsync>
              <TextCheckbox
                checked={terms}
                onChange={onChangeTerms}
                title={t('signup.labels.terms')}
                className="mb-0 mt-3"
              />
            </LoaderHOC>
          </div>
        </Card>
        <div>
          <span className="Body Regular Small">{t('signup.agreeTerms1')}</span>
          <a href={TermsConditions} className="Body Bold Small Orange400 no-underline">
            {t('signup.terms')}
          </a>
          <span className="Body Regular Small">{t('global.and')}</span>
          <a href={PrivacyPolicy} className="Body Bold Small Orange400 no-underline">
            {t('signup.policy')}
          </a>
          <span className="Body Regular Small">{t('signup.agreeTerms2')}</span>
        </div>
      </div>
    </Layout>
  );
};
