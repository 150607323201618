import { tagTypes } from 'constants/constants';
import { endpoints } from 'constants/endpoints';
import { CONTENT_TYPE, MULTIPART_FORMDATA } from 'constants/headers';
import { api } from 'services/api';

const settingsApi = api.injectEndpoints({
  endpoints: builder => ({
    getSettingsOptions: builder.query({
      query: () => ({
        url: endpoints.SETTINGS_OPTIONS,
        method: 'GET',
      }),
      transformResponse: res => {
        return Object.keys(res).reduce((acc, key) => {
          acc[key] = res[key].map(({ pk, ...other }) => ({
            value: pk,
            label: other[Object.keys(other).find(key => key !== 'icon')],
            icon: other['icon'],
          }));
          return acc;
        }, {});
      },
      providesTags: [tagTypes.SETTINGS_OPTIONS],
    }),
    saveSettings: builder.mutation({
      query: ({ body }) => ({
        url: endpoints.SAVE_DOSSIER,
        method: 'POST',
        body,
        headers: { [CONTENT_TYPE]: MULTIPART_FORMDATA },
      }),
    }),
    getProductTypesOptions: builder.query({
      query: industryId => ({
        url: endpoints.PRPODUCT_TYPES_OPTIONS + '?industry=' + industryId,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => {
        return result
          ? [{ type: tagTypes.PRODUCT_TYPES_OPTIONS, id: arg }]
          : [tagTypes.PRODUCT_TYPES_OPTIONS];
      },
    }),
    getCompanySettings: builder.query({
      query: id => ({ url: `${endpoints.SAVE_DOSSIER}${id}/`, method: 'GET' }),
      providesTags: [tagTypes.COMPANY_SETTINGS],
    }),
    updateCompanySettings: builder.mutation({
      query: ({ body, companyId }) => ({
        url: `${endpoints.SAVE_DOSSIER}${companyId}/`,
        method: 'PATCH',
        body,
        headers: { [CONTENT_TYPE]: MULTIPART_FORMDATA },
      }),
      invalidatesTags: [tagTypes.COMPANY_SETTINGS],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetSettingsOptionsQuery,
  useSaveSettingsMutation,
  useGetProductTypesOptionsQuery,
  useGetCompanySettingsQuery,
  useUpdateCompanySettingsMutation,
  endpoints: {
    getSettingsOptions: { matchFulfilled: getSettingsOptionsFulfilled },
    saveSettings: { matchFulfilled: saveSettingsFulfilled },
    getProductTypesOptions: { matchFulfilled: getProductTypesOptionsFulfilled },
    getCompanySettings: { matchFulfilled: getCompanySettingsFulfilled },
    updateCompanySettings: { matchFulfilled: updateCompanySettingsFulfilled },
  },
} = settingsApi;

export const selectSettings = state => state.settings.options;
