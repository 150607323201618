import { Right } from 'assets';
import classNames from 'classnames';
import { eventTypes } from 'constants/constants';
import { useTranslation } from 'hooks/utils/useTranslation';
import { useDispatch } from 'react-redux';
import { setSelectedEvent } from 'state/slices/calendarSlice';
import styles from './styles.module.scss';

export const CampaignLink = ({ campaign, event }) => {
  const t = useTranslation();
  const dispatch = useDispatch();

  return (
    <div
      className={classNames(
        styles.CampaignLink,
        styles[eventTypes[event.type || eventTypes.campaign] || 'other']
      )}
    >
      <div className={styles.Dot} />
      <div className="Body Bold Medium Blue600">{campaign.title}</div>
      <button
        className={styles.Link}
        onClick={() => {
          dispatch(
            setSelectedEvent({
              title: campaign.title,
              description: campaign.description,
              start: campaign.start,
              end: campaign.end,
              pk: campaign.pk,
              parent: {
                title: event.title,
                event_type: event.type,
                start: event.start,
                end: event.end,
              },
              type: eventTypes.campaign,
            })
          );
        }}
      >
        <img src={Right} alt={t('a11y.goToCampaign')} />
      </button>
    </div>
  );
};
