import { EmailBuilder, EmailMenu, Layout, LoaderHOC } from 'components';
import { eventTypes } from 'constants/constants';
import { useCompany } from 'hooks/state/useCompany';
import { useQuery } from 'hooks/utils/useQuery';
import { ErrorPage } from 'pages/ErrorPage';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetCampaignLayoutQuery } from 'services/emailLayout';
import { setSelectedCompany, setSelectedDate } from 'state/slices/calendarSlice';
import { prepareDateString } from 'utils/calendarHelper';
import styles from './styles.module.scss';

export const EmailLayout = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const query = useQuery();
  const token = query.get('token');
  const { selectedCompany: company, isOwnedCompany } = useCompany();
  const {
    data: campaign,
    isLoading,
    isError,
    isSuccess,
  } = useGetCampaignLayoutQuery({ id, token });
  const parentType = useMemo(() => {
    return campaign ? Object.keys(campaign).find(key => eventTypes[key] && !!campaign[key]) : null;
  }, [campaign]);
  const notAccessible = !isOwnedCompany && isSuccess && !campaign?.modules?.length;

  useEffect(() => {
    dispatch(setSelectedCompany({ company, token }));
  }, [company, token, dispatch]);

  useEffect(() => {
    if (campaign?.start_date) {
      const date = prepareDateString(campaign.start_date);
      dispatch(setSelectedDate({ year: date.getFullYear(), month: date.getMonth() + 1 }));
    }
  }, [campaign, dispatch]);

  if (isError || notAccessible) return <ErrorPage />;

  return (
    <Layout menuTheme="light">
      <div className={styles.EmailLayout}>
        <div className={styles.EmailLayout__Container}>
          <LoaderHOC isLoading={isLoading}>
            <EmailBuilder campaign={campaign} parentType={parentType} />
            <EmailMenu campaign={campaign} parentType={parentType} campaignId={id} />
          </LoaderHOC>
        </div>
      </div>
    </Layout>
  );
};
