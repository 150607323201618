import { Tooltip } from 'components';
import classNames from 'classnames';
import { useTranslation } from 'hooks/utils/useTranslation';
import { useRef } from 'react';
import styles from './styles.module.scss';

export const ToggleQuestion = ({
  title,
  value,
  onChange,
  tooltipDescription,
  tooltipPosition,
  tooltipIconLeft = false,
  className,
  disabled = false,
}) => {
  const t = useTranslation();
  const ref = useRef(null);

  const onClick = () => ref.current?.click();

  return (
    <div
      className={classNames(
        styles.ToggleQuestion,
        { [styles.Active]: value, [styles.Disabled]: disabled },
        className
      )}
    >
      <span className="Body Regular Medium mr-1 text-left">{title}</span>
      {tooltipDescription && (
        <Tooltip
          description={tooltipDescription}
          position={tooltipPosition}
          className={classNames({ 'ml-auto': !tooltipIconLeft })}
        />
      )}
      <div className={classNames(styles.Switch__Container, { 'ml-auto': tooltipIconLeft })}>
        <span
          className={classNames(
            'Body Small',
            value ? 'Regular' : 'Bold',
            value && styles.TextInactive
          )}
        >
          {t('global.no')}
        </span>
        <label className={styles.Switch}>
          <input
            ref={ref}
            type="checkbox"
            checked={value}
            onChange={e => !disabled && onChange(e)}
          />
          <button
            type="button"
            onClick={onClick}
            className={classNames(styles.Slider, styles.Round)}
          />
        </label>
        <span
          className={classNames(
            'Body Small',
            value ? 'Bold' : 'Regular',
            !value && styles.TextInactive
          )}
        >
          {t('global.yes')}
        </span>
      </div>
    </div>
  );
};
