import classNames from 'classnames';
import { FileUpload, Tooltip } from 'components';
import { FILETYPES_COLLECTION_CATALOG, FILETYPES_PRODUCT_CATALOG } from 'constants/constants';
import { settingsSchemaKeys } from 'hooks/schemas/useSettingsSchema';
import { useTranslation } from 'hooks/utils/useTranslation';
import { i18nToLink } from 'utils/formatHelper';
import styles from '../styles.module.scss';
const productCatalogLink = process.env.REACT_APP_PRODUCT_CATALOG_URL;
const collectionCatalogLink = process.env.REACT_APP_COLLECTION_CATALOG_URL;
const catalogGuideLink = process.env.REACT_APP_CATALOG_GUIDE_URL;

export const UploadCatalogs = ({ register, resetField, watch, ...props }) => {
  const t = useTranslation({ keyPrefix: 'dossier.aboutYou.card.catalogsSection.' });

  return (
    <>
      <div className={classNames('Title Small pb-3', styles.Title)}>
        {t('title')} <span className="Body Large Regular mr-2">{t('optional')}</span>
        <Tooltip className="inline" autoHide={false}>
          {t('tooltip', { link: i18nToLink(catalogGuideLink, 'Body', 'Small') })}
        </Tooltip>
      </div>

      <div className="flex flex-column">
        <span className={'Body Large white-space-preline'}>
          {t('catalogDescription', {
            link1: i18nToLink(productCatalogLink, 'Body', 'Large'),
            link2: i18nToLink(collectionCatalogLink, 'Body', 'Large'),
            link3: i18nToLink(catalogGuideLink, 'Body', 'Large'),
          })}
        </span>
      </div>
      <div className={classNames(styles.Catalogs, 'mt-4')}>
        <FileUpload
          title={t('productCatalogTitle')}
          register={register}
          name={settingsSchemaKeys.product_catalog}
          accept={FILETYPES_PRODUCT_CATALOG.join(', ')}
          resetField={resetField}
          watch={watch}
        />
        <FileUpload
          title={t('collectionCatalogTitle')}
          register={register}
          name={settingsSchemaKeys.collection_catalog}
          accept={FILETYPES_COLLECTION_CATALOG.join(', ')}
          resetField={resetField}
          watch={watch}
        />
      </div>
    </>
  );
};
