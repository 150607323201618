export const SUPPORTED_LANGUAGES = ['en'];
export const DEFAULT_LANGUAGE = 'en';

// REGEX
export const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

// AUTH
export const AUTH_USER_KEY = 'user';

// Tag Types, used for storing with RTK without actually storing ourselves
export const tagTypes = {
  USER_INFO: 'USER_INFO',
  SETTINGS_OPTIONS: 'SETTINGS_OPTIONS',
  PRODUCT_TYPES_OPTIONS: 'PRODUCT_TYPES_OPTIONS',
  PROMOTION_OPTIONS: 'PROMOTION_OPTIONS',
  PRODUCT_LAUNCH_OPTIONS: 'PRODUCT_LAUNCH_OPTIONS',
  MONTHLY_SETTINGS: 'MONTHLY_SETTINGS',
  CALENDAR_EVENTS: 'CALENDAR_EVENTS',
  EVENT_DETAILS: 'EVENT_DETAILS',
  CAMPAIGN_EMAIL: 'CAMPAIGN_EMAIL',
  COMPANY_SETTINGS: 'COMPANY_SETTINGS',
  SHARE_TOKEN: 'SHARE_TOKEN',
};

// Tag Type Ids to avoid typos

export const getMonthlySettingTagTypeId = (company, month, year) =>
  [company, month, year].join('--');

export const getCacheCalendarEventsTag = (company, month, year) => ({
  type: tagTypes.CALENDAR_EVENTS,
  id: [company, month, year].join('-'),
});

// Max amount of entries
export const maxBrandWords = 5;
export const maxBrandGoals = 3;

// Max number that can be entered in a field
export const maxNumberField = 2147483647;
export const MAX_LENGTH_COMPANY_URL = 50;
export const MIN_LENGTH_COMPANY_URL = 1;
export const MAX_LENGTH_USER_NAME = 50;
export const MIN_LENGTH_USER_NAME = 1;
export const MAX_LENGTH_COMPANY_NAME = 50;
export const MIN_LENGTH_COMPANY_NAME = 1;
export const MIN_AMOUNT_BRAND_WORDS = 1;
export const MIN_AMOUNT_BRAND_GOALS = 1;
export const MIN_AMOUNT_INDUSTRY_TYPES = 1;
export const MAX_LENGTH_PASSWORD = 50;
export const MIN_LENGTH_PASSWORD = 8;

// Defaults for fields
export const DEFAULT_BRIEF_EMAIL_LENGTH = 3;
export const DEFAULT_BRIEF_AMOUNT_COPY = 5;
export const DEFAULT_BRIEF_AMOUNT_IMAGES = 5;
export const DEFAULT_BRIEF_TIERS = 3;
export const DEFAULT_BRIEF_DISCOUNT_CODE = false;
export const DEFAULT_BRIEF_SMS_OPTIN = false;
export const DEFAULT_BRIEF_ONGOING_PROMOTION_PUSH = false;
export const DEFAULT_BRIEF_NEW_REMINDER = false;

// File types
export const FILETYPES_PRODUCT_CATALOG = ['text/csv'];
export const FILETYPES_COLLECTION_CATALOG = ['text/csv'];

export const eventTypes = {
  promotion: 'promotion',
  product_launch: 'product_launch',
  flow: 'flow',
  campaign: 'campaign',
};

export const aggressiveness = { min: 1, max: 10 };

export const multipleChoiceTiersOptions = (min, max) =>
  Array.from(Array(max - min + 1).keys()).map((_, i) => ({ label: `${min + i}`, value: min + i }));

export const linkStripeSubscription = process.env.REACT_APP_STRIPE_SUBSCRIPTION;
