import { bool, node, string } from 'prop-types';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { routesPaths } from 'routes/routesPaths';

export const PrivateRoute = ({ children, exact = false, path, authenticated }) => {
  const location = useLocation();

  return authenticated ? (
    <Route exact={exact} path={path}>
      {children}
    </Route>
  ) : (
    <Redirect
      to={{
        pathname: routesPaths.login,
        state: { from: location },
      }}
    />
  );
};

PrivateRoute.propTypes = {
  children: node.isRequired,
  path: string.isRequired,
  authenticated: bool,
  exact: bool,
};
