import {
  DEFAULT_BRIEF_DISCOUNT_CODE,
  DEFAULT_BRIEF_EMAIL_LENGTH,
  DEFAULT_BRIEF_NEW_REMINDER,
  DEFAULT_BRIEF_ONGOING_PROMOTION_PUSH,
  DEFAULT_BRIEF_SMS_OPTIN,
  DEFAULT_BRIEF_TIERS,
  eventTypes,
} from 'constants/constants';
import { z } from 'zod';

export const emailBriefSchemaKeys = {
  title: 'title',
  campaign_type: 'campaign_type',
  start_date: 'start_date',
  email_length: 'email_length',
  sms_opt_in: 'sms_opt_in',
  ongoing_promotion_push: 'ongoing_promotion_push',
  new_product_reminder: 'new_product_reminder',
  promotion: {
    name: 'promotion.name',
    offer: 'promotion.offer',
    on_sale: 'promotion.on_sale',
    start_date: 'promotion.start_date',
    end_date: 'promotion.end_date',
    discount_code: 'promotion.discount_code',
    tiers: 'promotion.tiers',
  },
  product_launch: {
    name: 'product_launch.name',
    type_of_product: 'product_launch.type_of_product',
    start_date: 'product_launch.start_date',
  },
  flow: {
    is_offer: 'flow.is_offer',
    offer: 'flow.offer',
    discount_code: 'flow.discount_code',
  },
};

export const useEmailBriefSchema = (campaign, parentType) => {
  return {
    schema: z.object({
      title: z.string().optional().nullable(),
      campaign_type: z.string().optional().nullable(),
      start_date: z.string().optional().nullable(),
      email_length: z.number().optional().nullable(),
      sms_opt_in: z.boolean(),
      ongoing_promotion_push: z.boolean(),
      new_product_reminder: z.boolean(),
      amount_copy: z.number(),
      amount_images: z.number(),

      promotion: z
        .object({
          name: z.string().optional().nullable(),
          offer: z.number().optional().nullable(),
          on_sale: z.number().optional().nullable(),
          start_date: z.string().optional().nullable(),
          end_date: z.string().optional().nullable(),
          discount_code: z.boolean().optional().nullable(),
          tiers: z.number().optional().nullable(),
        })
        .optional()
        .nullable(),

      product_launch: z
        .object({
          name: z.string().optional().nullable(),
          type_of_product: z.number().optional().nullable(),
          start_date: z.string().optional().nullable(),
        })
        .optional()
        .nullable(),

      flow: z
        .union([
          z.object({
            is_offer: z.literal(false),
            offer: z.null(),
            discount_code: z.literal(false),
          }),
          z.object({
            is_offer: z.literal(true),
            offer: z.number(),
            discount_code: z.boolean(),
          }),
        ])
        .optional()
        .nullable(),
    }),
    defaultValues: {
      title: campaign.title,
      campaign_type: campaign.campaign_type,
      start_date: campaign.start_date,
      email_length: campaign.email_length || DEFAULT_BRIEF_EMAIL_LENGTH,
      sms_opt_in: campaign.sms_opt_in ?? DEFAULT_BRIEF_SMS_OPTIN,
      ongoing_promotion_push:
        campaign.ongoing_promotion_push ?? DEFAULT_BRIEF_ONGOING_PROMOTION_PUSH,
      new_product_reminder: campaign.new_product_reminder ?? DEFAULT_BRIEF_NEW_REMINDER,
      amount_copy: 1,
      amount_images: 1,

      ...(parentType === eventTypes.promotion && {
        promotion: {
          name: campaign[parentType].name,
          offer: campaign[parentType].offer,
          on_sale: campaign[parentType].on_sale,
          start_date: campaign[parentType].start_date,
          end_date: campaign[parentType].end_date,
          discount_code: campaign[parentType].discount_code ?? DEFAULT_BRIEF_DISCOUNT_CODE,
          tiers: campaign[parentType].tiers || DEFAULT_BRIEF_TIERS,
        },
      }),
      ...(parentType === eventTypes.product_launch && {
        product_launch: {
          name: campaign[parentType].name,
          type_of_product: campaign[parentType].type_of_product,
          start_date: campaign[parentType].start_date,
        },
      }),
      ...(parentType === eventTypes.flow && {
        flow: {
          is_offer: campaign[parentType].is_offer,
          offer: campaign[parentType].offer,
          discount_code: campaign[parentType].discount_code ?? DEFAULT_BRIEF_DISCOUNT_CODE,
        },
      }),
    },
  };
};
