import { DatePicker, Dropdown, Input } from 'components';
import { emailBriefSchemaKeys } from 'hooks/schemas/useEmailBriefSchema';
import { useTranslation } from 'hooks/utils/useTranslation';
import { Controller } from 'react-hook-form';
import { onDateChange, prepareDateString } from 'utils/calendarHelper';
import { prepareField } from 'utils/onboardingSteps';
import styles from '../../styles.module.scss';

export const BriefProdLaunch = ({ control, setValue, productTypes }) => {
  const t = useTranslation();
  return (
    <>
      <Controller
        name={emailBriefSchemaKeys.product_launch.name}
        control={control}
        render={({ field, fieldState }) => (
          <Input
            title={t('emailLayout.brief.aboutEvent.productLaunchName')}
            className="w-full"
            inputClassName="w-full"
            id={field.name}
            error={!!fieldState.error}
            {...prepareField(field)}
            filled={!!field.value}
            disabled
          />
        )}
      />
      <Controller
        name={emailBriefSchemaKeys.product_launch.type_of_product}
        control={control}
        render={({ field, fieldState }) => (
          <Dropdown
            title={t('emailLayout.brief.aboutEvent.prodlaunchProductType')}
            options={productTypes}
            className="w-full"
            innerClassName="w-full h-4rem"
            id={field.name}
            error={!!fieldState.error}
            {...prepareField(field)}
            filled={!!field.value}
            disabled
          />
        )}
      />
      <Controller
        name={emailBriefSchemaKeys.product_launch.start_date}
        control={control}
        render={({ field, fieldState }) => (
          <DatePicker
            title={t('emailLayout.brief.aboutEvent.prodLaunchDateFrom')}
            className="w-full m-0"
            id={field.name}
            innerClassName={styles.Field}
            value={prepareDateString(field.value)}
            error={!!fieldState.error}
            onChange={onDateChange(field, setValue)}
            filled={!!field.value}
            disabled
          />
        )}
      />
    </>
  );
};
