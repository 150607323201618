import { close, Height, trashAlt, Width } from 'assets';
import classNames from 'classnames';
import { FeatureFlag } from 'components/common/FeatureFlag';
import { LoaderHOC } from 'components/hoc/LoaderHOC';
import { featureFlagKeys } from 'constants/featureFlags';
import { useTranslation } from 'hooks/utils/useTranslation';
import { Skeleton } from 'primereact/skeleton';
import { useState } from 'react';
import { EmailAlternative } from './EmailAlternative';
import { EmailDesignExample } from './EmailDesignExample';
import { EmailNote } from './EmailNote';
import styles from './styles.module.scss';

export const EmailModule = ({ module, campaignId }) => {
  const t = useTranslation();
  const [open, setOpen] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(false);
  const onImgLoad = () => setImgLoaded(true);
  const onToggleModule = () => setOpen(!open);
  const onClose = () => setOpen(false);

  return (
    <div className={classNames(styles.EmailModule, { [styles.open]: open })}>
      <button className={classNames(styles.Module)} onClick={onToggleModule}>
        <img
          src={module.url}
          alt={t('a11y.module')}
          className={classNames({ [styles.open]: open, hidden: !imgLoaded })}
          onLoad={onImgLoad}
        />
        <Skeleton height="30rem" width="100%" hidden={imgLoaded} />
        <div className={styles.Spinner}>
          <LoaderHOC isLoading={!imgLoaded} />
        </div>
      </button>
      <div className={classNames(styles.ModuleInfo, { hidden: !open })}>
        <div className={styles.Header}>
          <span className="Title Small Blue600">
            {module.section}: {module.type}
          </span>
          <FeatureFlag featureKey={featureFlagKeys.BB_DELETE_EMAIL_LAYOUT_MODULE}>
            <button className={styles.Delete}>
              <img src={trashAlt} alt={t('a11y.delete')} />
            </button>
          </FeatureFlag>
          <button className={styles.Close} onClick={onClose}>
            <img src={close} alt={t('a11y.close')} />
          </button>
        </div>
        <div className={styles.Properties}>
          <img src={Width} alt={t('a11y.width')} />
          <div>
            <span className="Body Bold Medium mr-1">{t('emailLayout.builder.width')}</span>
            <span className="Body Regular Medium">{module.width + 'px'}</span>
          </div>
          -
          <img src={Height} alt={t('a11y.height')} />
          <div>
            <span className="Body Bold Medium mr-1">{t('emailLayout.builder.height')}</span>
            <span className="Body Regular Medium">{module.height + 'px'}</span>
          </div>
        </div>
        {module.notes
          .map(note => <EmailNote note={note} key={note.pk} />)
          .reduce(
            (acc, elem, i) =>
              !acc
                ? [elem]
                : [...acc, <div className={styles.Separator} key={`separator-${i}`} />, elem],
            null
          )}

        {!!module.alternatives.length && (
          <>
            <div className="Title ExtraSmall Blue600 my-3">
              {t('emailLayout.builder.alternate')}
            </div>
            <div className={styles.Listing}>
              {module.alternatives.map(alt => (
                <div className={styles.Item} key={alt.pk}>
                  <EmailAlternative
                    alternative={alt}
                    campaignId={campaignId}
                    moduleId={module.pk}
                  />
                </div>
              ))}
            </div>
          </>
        )}
        {!!module.design_examples.length && (
          <>
            <div className="Title ExtraSmall Blue600 my-3">
              {t('emailLayout.builder.designExamples')}
            </div>
            <div className={styles.Listing}>
              {module.design_examples.map((design, i) => (
                <div className={styles.Item} key={design.pk}>
                  <EmailDesignExample designExample={design} index={i + 1} />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
