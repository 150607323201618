import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import flatten from 'flat';
import { HelmetProvider } from 'react-helmet-async';
import { SUPPORTED_LANGUAGES, DEFAULT_LANGUAGE } from './constants/constants';
import store from 'state/store';
import App from './App';
import { locales } from './locales';
import './index.scss';
import { GoogleOAuthProvider } from '@react-oauth/google';
import posthog from 'posthog-js';
import { defaultFeatureFlags } from 'constants/featureFlags';

// Posthog Setup
posthog.init(process.env.REACT_APP_POSTHOG_KEY, {
  api_host: process.env.REACT_APP_POSTHOG_URL,
  disable_session_recording: true,
  bootstrap: {
    featureFlags: defaultFeatureFlags,
  },
});

// Intercom Setup
window.Intercom('boot', {
  api_base: process.env.REACT_APP_INTERCOM_URL,
  app_id: process.env.REACT_APP_INTERCOM_ID,
});

// Internationalization setup
const usersLocale = navigator.language.split('-')[0];
const supportedUserLocale = SUPPORTED_LANGUAGES.includes(usersLocale);
const locale = supportedUserLocale ? usersLocale : DEFAULT_LANGUAGE;
const messages = locales[locale];

createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <IntlProvider messages={flatten(messages)} locale={locale} defaultLocale={DEFAULT_LANGUAGE}>
      <Provider store={store}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </GoogleOAuthProvider>
      </Provider>
    </IntlProvider>
  </React.StrictMode>
);
