export const routesPaths = {
  index: '/',
  login: '/login',
  signup: '/sign-up',
  resetPassword: '/password/reset',
  welcome: '/welcome',
  dossier: '/dossier',
  emailLayout: '/campaign/:id/layout',
  notFound: '/404',
  calendar: '/calendar',
  settings: '/settings',
  wildcard: '*',
};
