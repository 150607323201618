import { eventTypes } from 'constants/constants';
import { posthogEvents, posthogOptions } from 'constants/posthog';
import { useCompany } from 'hooks/state/useCompany';
import { useHistoryImpl } from 'hooks/utils/useHistoryImpl';
import { useTranslation } from 'hooks/utils/useTranslation';
import { posthog } from 'posthog-js';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { usePatchEventDetailsMutation } from 'services/calendar';
import { selectCalendar } from 'state/slices/calendarSlice';
import {
  formattedDateNth,
  getCalendarDate,
  prepareDateString,
  toDateStringGlobal,
} from 'utils/calendarHelper';

export const useChangeCampaignDate = campaign => {
  const t = useTranslation();
  const [patchEventDetails, { isLoading }] = usePatchEventDetailsMutation();
  const { userCompany: company } = useCompany();
  const {
    selectedDate: { month, year },
  } = useSelector(selectCalendar);
  const [showChangeDate, setShowChangeDate] = useState(false);
  const history = useHistoryImpl();

  const formattedCampaignDate = useMemo(() => formattedDateNth(campaign.start), [campaign.start]);

  const { minDate, campaignDate, maxDate } = useMemo(() => {
    const today = new Date();
    if (!campaign?.parent?.event_type)
      return { minDate: today, maxDate: null, campaignDate: prepareDateString(campaign.start) };

    return {
      [eventTypes.product_launch]: {
        minDate: today,
        maxDate: null,
        campaignDate: prepareDateString(campaign.start),
      },
      [eventTypes.promotion]: {
        minDate: campaign.isTease ? today : prepareDateString(campaign.parent?.start),
        campaignDate: prepareDateString(campaign.start),
        maxDate: campaign.isTease
          ? (() => {
              const d = prepareDateString(campaign.parent.start);
              d.setDate(d.getDate() - 1);
              return d;
            })()
          : prepareDateString(campaign.parent.end),
      },
      [eventTypes.flow]: {
        minDate: today,
        maxDate: null,
        campaignDate: prepareDateString(campaign.start),
      },
    }[campaign.parent.event_type];
  }, [campaign]);

  const onClickDate = ({ value }) => {
    const dateStr = toDateStringGlobal(value);
    const calendar = { ...getCalendarDate(campaign, { month, year }), company };
    Promise.all([
      (campaign.parent?.event_type === eventTypes.flow
        ? [{ pk: campaign.parent.pk }, ...campaign.parent.campaigns]
        : [{ pk: campaign.pk }]
      ).map(c =>
        patchEventDetails({
          body: { pk: c.pk, start: dateStr },
          calendar,
        })
      ),
    ]).then(res =>
      !res.error
        ? toast.success(t('campaignDetails.changeDate.success'))
        : toast.error(t('campaignDetails.changeDate.error'))
    );
    posthog.capture(posthogEvents.pages.calendar.campaign.CHANGE_DATE, posthogOptions());
    setShowChangeDate(false);
  };

  const switchShowDate = () => setShowChangeDate(!showChangeDate);

  const dateClassNames = {
    promotion: 'Promotion__DatePicker',
    product_launch: 'ProdLaunch__DatePicker',
    flow: 'Flow__DatePicker',
  };

  return {
    switchShowDate,
    onClickDate,
    minDate,
    campaignDate,
    maxDate,
    formattedCampaignDate,
    isLoading,
    showChangeDate,
    dateClassName: dateClassNames[campaign.parent.event_type],
    history,
  };
};
