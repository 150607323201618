export const endpoints = {
  USER_INFO: '/api/users/me/',
  GOOGLE_SIGN_IN: '/accounts/google/login/',
  GOOGLE_SIGN_OUT: '/accounts/google/logout/',
  SIGN_IN: '/api/auth/login/',
  SIGN_UP: '/api/auth/registration/',
  RECOVERY_EMAIL: 'api/auth/password/reset/',
  RECOVER_PASSWORD: 'api/auth/password/reset/confirm/',
  SETTINGS_OPTIONS: '/api/dossier/attribute-options/',
  PRPODUCT_TYPES_OPTIONS: '/api/industry-types/',
  SAVE_DOSSIER: '/api/dossier/',
  PROMOTION_OPTIONS: '/api/promotions/attribute-options/',
  PRODUCT_LAUNCH_OPTIONS: '/api/product-launches/attribute-options/',
  MONTHLY_SETTINGS: '/api/monthly-settings/',
  CALENDAR_EVENTS: '/api/calendar/',
  EVENT_DETAILS: '/api/events/',
  CAMPAIGN_EMAIL: '/api/campaign/:id/layout',
  CAMPAIGN_EMAIL_MODULES: '/api/campaign/:id/layout/modules',
  EXPORT_LAYOUT: '/api/campaign/:id/layout/export',
  SHARE_TOKEN: '/api/dossier/:id/share/',
};
