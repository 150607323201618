import { Layout } from 'components';
import { useHistoryImpl } from 'hooks/utils/useHistoryImpl';
import { useTranslation } from 'hooks/utils/useTranslation';
import { Steps } from 'primereact/steps';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { routesPaths } from 'routes/routesPaths';
import { selectAuth } from 'services/emailLayout';
import { onboardingSteps as steps } from 'utils/onboardingSteps';
import styles from './styles.module.scss';

export const OnboardingStep = ({ children, stepNumber, className }) => {
  const t = useTranslation();
  const { push } = useHistoryImpl();
  const {
    user: { company },
  } = useSelector(selectAuth);
  const completed = !!company;

  useEffect(() => {
    if (completed) {
      push(routesPaths.calendar);
    }
  }, [completed, push]);
  return (
    <Layout menuTheme={completed ? 'light' : 'dark'} className={className}>
      <div className={styles.Onboarding}>
        {!completed && <div className={styles.Background} />}
        <div className={styles.Onboarding__Container}>
          {!completed && (
            <Steps model={steps(t)} className={styles.Steps} activeIndex={stepNumber} />
          )}
          {children}
        </div>
      </div>
    </Layout>
  );
};
