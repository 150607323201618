import { Right } from 'assets';
import { Button, Layout, EmbedVideo } from 'components';
import { useHistoryImpl } from 'hooks/utils/useHistoryImpl';
import { useTranslation } from 'hooks/utils/useTranslation';
import { Card } from 'primereact/card';
import { Steps } from 'primereact/steps';
import { routesPaths } from 'routes/routesPaths';
import { onboardingSteps as steps } from 'utils/onboardingSteps';
import styles from './styles.module.scss';
const onboardingVideo = process.env.REACT_APP_ONBOARDING_VIDEO;

export const Welcome = () => {
  const t = useTranslation();
  const { push } = useHistoryImpl();

  return (
    <Layout menuTheme="dark">
      <div className={styles.Welcome}>
        <div className={styles.Background} />
        <div className={styles.Welcome__Container}>
          <h1>{t('dossier.welcomeMsg')}</h1>
          <h4 className="Body Regular Large">{t('dossier.welcomeSubtitle')}</h4>
          <Steps model={steps(t)} className={styles.Steps} activeIndex={0} />
          <Button
            title={t('dossier.getStarted')}
            icon={Right}
            onClick={() => push(routesPaths.dossier)}
            palette="Orange"
            width="26.625rem"
            height="3.75rem"
          />
          <Card className="Card" title={t('dossier.walkthroughVideo')}>
            <EmbedVideo src={onboardingVideo} />
          </Card>
        </div>
      </div>
    </Layout>
  );
};
