import { MAX_LENGTH_PASSWORD, MIN_LENGTH_PASSWORD } from 'constants/constants';
import { z } from 'zod';

export const signupSchemaKeys = {
  walkthrough: 'walkthrough',
  terms_conditions: 'terms_conditions',
  marketing_emails: 'marketing_emails',
  email: 'email',
  password1: 'password1',
  password2: 'password2',
};

export const useSignupSchema = ({ t }) => {
  return {
    schema: z
      .object({
        walkthrough: z.boolean().nullish(),
        terms_conditions: z.literal(true).nullish(),
        marketing_emails: z.boolean().nullish(),
        email: z.string().email().min(1).max(50),
        password1: z
          .string()
          .max(MAX_LENGTH_PASSWORD, t('login.errors.passwordMsgMax'))
          .min(MIN_LENGTH_PASSWORD, t('login.errors.passwordMsgMin'))
          .refine(str => /[a-zA-Z]/.test(str), { message: t('signup.errors.passwordMsgLetter') })
          .refine(str => /[0-9]/.test(str), { message: t('signup.errors.passwordMsgNumber') }),
        password2: z.string().min(1, { message: t('signup.errors.password') }),
      })
      .refine(({ password1, password2 }) => password1 === password2, {
        message: t('signup.errors.passwordMatch'),
        path: ['password2'],
      }),
    defaultValues: {
      walkthrough: false,
      terms_conditions: true,
      marketing_emails: true,
      email: '',
      password1: '',
      password2: '',
    },
  };
};
