import classNames from 'classnames';
import { Button, DatePicker, Dropdown, Input, LoaderHOC, TextCheckbox, Slider } from 'components';
import { aggressiveness } from 'constants/constants';
import { usePromotionForm } from 'hooks/form/usePromotionForm';
import { promotionsSchemaKeys } from 'hooks/schemas/usePromotionsSchema';
import { Controller } from 'react-hook-form';
import { onDateChange, prepareDateString } from 'utils/calendarHelper';
import { prepareField } from 'utils/onboardingSteps';
import styles from '../styles.module.scss';

export const Promotion = ({ t, onClose, className, item }) => {
  const {
    options: { offerChoice, saleChoice, minDate, viewDate, startDate },
    form: { handleSubmit, control, setValue, reset },
    onSubmit,
    isLoading,
  } = usePromotionForm({ onClose, item });

  return (
    <>
      <LoaderHOC isLoading={isLoading}>
        <form className={classNames(styles.AddCard, className)} onSubmit={handleSubmit(onSubmit)}>
          <div className="Body Bold Small">{t('name.label')}</div>
          <Controller
            name={promotionsSchemaKeys.name}
            control={control}
            render={({ field, fieldState }) => (
              <Input
                id={field.name}
                error={!!fieldState.error}
                special
                placeholder={t('name.placeholder')}
                inputClassName={styles.Field}
                {...prepareField(field)}
              />
            )}
          />

          <div className="Body Bold Small">{t('saleType.label')}</div>
          <Controller
            name={promotionsSchemaKeys.offer}
            control={control}
            render={({ field, fieldState }) => (
              <Dropdown
                id={field.name}
                special
                error={!!fieldState.error}
                placeholder={t('saleType.placeholder')}
                innerClassName={styles.Field}
                options={offerChoice}
                {...prepareField(field)}
              />
            )}
          />

          <div className="Body Bold Small">{t('sale.label')}</div>
          <Controller
            name={promotionsSchemaKeys.on_sale}
            control={control}
            render={({ field, fieldState }) => (
              <Dropdown
                id={field.name}
                special
                error={!!fieldState.error}
                placeholder={t('sale.placeholder')}
                innerClassName={styles.Field}
                options={saleChoice}
                {...prepareField(field)}
              />
            )}
          />

          <div className="Body Bold Small">{t('date.label')}</div>
          <div className={classNames(styles.Two, 'mb-0')}>
            <Controller
              name={promotionsSchemaKeys.start_date}
              control={control}
              render={({ field, fieldState }) => (
                <DatePicker
                  id={field.name}
                  placeholder={t('date.from')}
                  value={prepareDateString(field.value)}
                  error={!!fieldState.error}
                  onChange={onDateChange(field, setValue)}
                  minDate={minDate}
                  viewDate={viewDate}
                  special
                />
              )}
            />
            <Controller
              name={promotionsSchemaKeys.end_date}
              control={control}
              render={({ field, fieldState }) => (
                <DatePicker
                  id={field.name}
                  placeholder={t('date.to')}
                  value={prepareDateString(field.value)}
                  error={!!fieldState.error}
                  onChange={onDateChange(field, setValue)}
                  minDate={prepareDateString(startDate)}
                  viewDate={viewDate}
                  disabled={!startDate}
                  readOnlyInput
                  special
                />
              )}
            />
          </div>

          <div className="Body Bold Small">{t('emails.label')}</div>
          <Controller
            name={promotionsSchemaKeys.aggressiveness}
            control={control}
            render={({ field, fieldState }) => (
              <Slider
                min={aggressiveness.min}
                max={aggressiveness.max}
                id={field.name}
                error={!!fieldState.error}
                innerClassName={styles.Field}
                {...prepareField(field)}
              />
            )}
          />

          <Controller
            name={promotionsSchemaKeys.tease_prior_to_launch}
            control={control}
            render={({ field, fieldState }) => (
              <TextCheckbox
                id={field.name}
                checked={field.value}
                onChange={e => setValue(field.name, e.checked, { shouldValidate: true })}
                title={t('tease')}
                error={!!fieldState.error}
              />
            )}
          />

          <div className={styles.CTAGroup}>
            <Button
              type="submit"
              className={classNames(styles.Button, 'mb-2')}
              palette="Primary"
              size="Small"
              title={t('save')}
            />
            <Button
              palette="Secondary"
              size="Small"
              className={styles.Button}
              title={t('cancel')}
              onClick={() => {
                onClose();
                reset();
              }}
            />
          </div>
        </form>
      </LoaderHOC>
    </>
  );
};
