import { zodResolver } from '@hookform/resolvers/zod';
import { useGoogleLogin } from '@react-oauth/google';
import { useManualLoginSchema } from 'hooks/schemas/useManualLoginSchema';
import { useTranslation } from 'hooks/utils/useTranslation';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { api } from 'services/api';
import {
  selectAuth,
  useGetUserInfoQuery,
  useGoogleLoginMutation,
  useLoginMutation,
} from 'services/auth/auth';
import { handleLoginSignupErrors, loginSignupErrors } from 'utils/loginHelper';

export const useLoginForm = () => {
  const t = useTranslation();
  const { defaultValues, schema } = useManualLoginSchema({ t });
  const dispatch = useDispatch();
  const [terms, setTerms] = useState(true);
  const [googleLoginBackbone, { isLoading }] = useGoogleLoginMutation();
  const { authenticated } = useSelector(selectAuth);
  useGetUserInfoQuery(null, { skip: !authenticated });
  const [showRecover, setShowRecover] = useState(false);
  const [loginBackbone, { isLoading: isLoginLoading }] = useLoginMutation();
  const { push } = useHistory();

  const googleLogin = useGoogleLogin({
    onSuccess: ({ code }) =>
      googleLoginBackbone(code).then(({ error }) => {
        if (error) {
          const { error_code, non_field_errors } = error.data;
          loginSignupErrors({ t, non_field_errors, push })[error_code]?.action();
        }
      }),
    onError: () => toast.error(t('login.errors.google')),
    flow: 'auth-code',
  });

  const resetErrors = useCallback(() => dispatch(api.util.resetApiState()), [dispatch]);

  useEffect(() => resetErrors, [resetErrors]);

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const onSubmit = form.handleSubmit(handleLoginSignupErrors(loginBackbone, t, push));

  return {
    form,
    onSubmit,
    googleLogin,
    terms,
    onChangeTerms: () => setTerms(!terms),
    t,
    authenticated,
    isLoading: isLoading || isLoginLoading,
    showRecover,
    setShowRecover,
  };
};
