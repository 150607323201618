import { IconProfile } from 'assets';
import classNames from 'classnames';
import { Logo } from 'components';
import { useHistoryImpl } from 'hooks/utils/useHistoryImpl';
import { useTranslation } from 'hooks/utils/useTranslation';
import { Menubar as Menu } from 'primereact/menubar';
import { func, oneOf } from 'prop-types';
import { useSelector } from 'react-redux';
import { routesPaths } from 'routes/routesPaths';
import { selectAuth, useGoogleLogoutMutation } from 'services/auth/auth';
import styles from '../styles.module.scss';
const linkStripeProfile = process.env.REACT_APP_STRIPE_PROFILE;

export const AuthMenubar = ({ template, theme = 'light' }) => {
  const t = useTranslation();
  const { push } = useHistoryImpl();
  const [logout] = useGoogleLogoutMutation();
  const { user } = useSelector(selectAuth);

  const handleLogout = () =>
    logout().then(() => {
      localStorage.removeItem('user');
      push(routesPaths.login);
    });

  const menuItem = (item, options) => (
    <button
      type="button"
      target={item.target}
      onClick={options.onClick}
      className={styles.AuthItem}
    >
      <span className={'Body Bold Medium'}>{item.label}</span>
    </button>
  );

  const authItems = [
    {
      label: user?.name ? user?.name : t('menubar.user'),
      icon: <img src={IconProfile} alt={t('a11y.profileIcon')} />,
      dropdown: true,
      template: template('User'),
      items: [
        {
          label: t('menubar.stripeProfile'),
          template: menuItem,
          command: () =>
            window.location.replace(
              linkStripeProfile + (user?.email ? '?prefilled_email=' + user?.email : '')
            ),
        },
        ...(!!user?.company
          ? [
              {
                label: t('menubar.settings'),
                template: menuItem,
                command: () => push(routesPaths.settings),
              },
            ]
          : []),
        {
          label: t('menubar.logout'),
          template: menuItem,
          command: handleLogout,
        },
      ],
    },
  ];

  return (
    <Menu start={<Logo theme={theme} />} model={authItems} className={classNames(styles.Menubar)} />
  );
};

AuthMenubar.propTypes = {
  template: func,
  theme: oneOf(['light', 'dark']),
};
