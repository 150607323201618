import classNames from 'classnames';
import { Dropdown, Input } from 'components';
import { settingsSchemaKeys } from 'hooks/schemas/useSettingsSchema';
import { useTranslation } from 'hooks/utils/useTranslation';
import { Controller } from 'react-hook-form';
import { prepareField } from 'utils/onboardingSteps';
import styles from '../styles.module.scss';

export const About = ({ settingsOptions, control }) => {
  const t = useTranslation();

  return (
    <>
      <span className={classNames('Title Small', styles.Title)}>
        {t('dossier.aboutYou.card.aboutYouSection.title')}
      </span>
      <span className="Body Large">{t('dossier.aboutYou.card.aboutYouSection.name')}</span>
      <Controller
        name={settingsSchemaKeys.user_name}
        control={control}
        render={({ field, fieldState }) => (
          <Input
            id={field.name}
            special
            error={!!fieldState.error}
            placeholder={t('dossier.aboutYou.card.aboutYouSection.namePlaceholder')}
            {...prepareField(field)}
          />
        )}
      />
      <span className="Body Large">{t('dossier.aboutYou.card.aboutYouSection.position')}</span>
      <Controller
        name={settingsSchemaKeys.user_role}
        control={control}
        render={({ field, fieldState }) => (
          <Dropdown
            id={field.name}
            options={settingsOptions.user_role_options}
            special
            error={!!fieldState.error}
            placeholder={t('dossier.aboutYou.card.aboutYouSection.positionPlaceholder')}
            {...prepareField(field)}
          />
        )}
      />
      <span className="Body Large">{t('dossier.aboutYou.card.aboutYouSection.at')}</span>
      <Controller
        name={settingsSchemaKeys.company_name}
        control={control}
        render={({ field, fieldState }) => (
          <Input
            id={field.name}
            special
            error={!!fieldState.error}
            placeholder={t('dossier.aboutYou.card.aboutYouSection.companyPlaceholder')}
            {...prepareField(field)}
          />
        )}
      />
      <span className="Body Large">{t('dossier.aboutYou.card.aboutYouSection.revenue')}</span>
      <Controller
        name={settingsSchemaKeys.annual_revenue}
        control={control}
        render={({ field, fieldState }) => (
          <Dropdown
            id={field.name}
            special
            options={settingsOptions.annual_revenue_options}
            error={!!fieldState.error}
            placeholder={t('dossier.aboutYou.card.aboutYouSection.revenuePlaceholder')}
            {...prepareField(field)}
          />
        )}
      />
      <span className="Body Large">{t('dossier.aboutYou.card.aboutYouSection.sku')}</span>{' '}
      <Controller
        name={settingsSchemaKeys.sku}
        control={control}
        render={({ field, fieldState }) => (
          <Dropdown
            id={field.name}
            special
            options={settingsOptions.sku_options}
            error={!!fieldState.error}
            placeholder={t('dossier.aboutYou.card.aboutYouSection.skuPlaceholder')}
            {...prepareField(field)}
          />
        )}
      />
      <span className="Body Large">{t('dossier.aboutYou.card.aboutYouSection.website')}</span>{' '}
      <Controller
        name={settingsSchemaKeys.company_url}
        control={control}
        render={({ field, fieldState }) => (
          <Input
            id={field.name}
            special
            error={!!fieldState.error}
            placeholder={t('dossier.aboutYou.card.aboutYouSection.websitePlaceholder')}
            {...prepareField(field)}
          />
        )}
      />
    </>
  );
};
