import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { deleteEventFulfilled, patchEventDetailsFulfilled } from 'services/calendar';
import { getCampaignLayoutFulfilled } from 'services/emailLayout';
import {
  getMonthlySettingsFulfilled,
  putMonthlySettingsFulfilled,
} from 'services/form/monthlySettings';
import { v4 } from 'uuid';

const initialState = {
  selectedDate: { month: null, year: null },
  selectedEvent: null,
  selectedCompany: null,
  selectedShareToken: null,
  monthlySettingsForm: [],
};

const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setSelectedCompany(state, { payload: { company, token } }) {
      state.selectedCompany = company;
      state.selectedShareToken = token;
    },
    setSelectedDate(state, { payload: { month, year } }) {
      state.selectedDate = { month, year };
      state.selectedEvent = null;
    },
    setSelectedEvent(
      state,
      { payload: { title, parent, start, end, pk, description, children, type, isTease } }
    ) {
      state.selectedEvent = { title, parent, start, end, pk, description, children, type, isTease };
    },
    addOrEditList(state, { payload: { month, year, listItem, listName, itemId } }) {
      const form = state.monthlySettingsForm.find(
        form => form.month === month && form.year === year
      );
      const newItem = { id: itemId, value: listItem, open: false };

      if (!form.data[listName]) {
        form.data[listName] = [newItem];
        return;
      }
      const theItem = form.data[listName].find(item => item.id === itemId);
      if (!theItem) {
        form.data[listName] = [newItem, ...form.data[listName]];
        return;
      }

      theItem.value = listItem;
    },
    deleteListItem(state, { payload: { month, year, listName, itemId } }) {
      const form = state.monthlySettingsForm.find(
        form => form.month === month && form.year === year
      );
      if (form?.data?.[listName]) {
        const newFormList = form.data[listName].filter(item => item.id !== itemId);
        form.data[listName] = newFormList;
      }
    },
    setEmails(state, { payload: { number_of_campaigns, number_of_flows } }) {
      const { month, year } = state.selectedDate;
      const formData = state.monthlySettingsForm.find(
        form => form.year === year && form.month === month
      )?.data;
      if (!formData) return;
      if (number_of_campaigns) formData.number_of_campaigns = number_of_campaigns;
      if (number_of_flows) formData.number_of_flows = number_of_flows;
    },
    openOrCloseItem(state, { payload: { listName, itemId, open } }) {
      const item = state.monthlySettingsForm
        .find(
          form => form.month === state.selectedDate.month && form.year === state.selectedDate.year
        )
        .data[listName].find(item => item.id === itemId);
      item.open = open;
    },
    closeMonthlySettings(state, { payload: { month, year } }) {
      const form = state.monthlySettingsForm.find(
        form => form.month === month && form.year === year
      )?.data;
      if (form) {
        [...(form.promotions || []), ...(form.product_launches || [])].forEach(
          item => (item.open = false)
        );
      }
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      isAnyOf(getMonthlySettingsFulfilled),
      (state, { payload: monthlyInfo, meta }) => {
        const { month, year, company } = meta.arg.originalArgs;
        const current = state.monthlySettingsForm.find(
          form => form.month === month && form.year === year
        );

        if (current) {
          return; // For now we don't 'step' on local changes
        }

        if (monthlyInfo) {
          state.monthlySettingsForm = [
            ...state.monthlySettingsForm,
            {
              month,
              year,
              data: {
                ...monthlyInfo,
                promotions: monthlyInfo.promotions?.map(promo => ({
                  value: promo,
                  id: v4(),
                  open: false,
                })),
                product_launches: monthlyInfo.product_launches?.map(prod => ({
                  value: prod,
                  id: v4(),
                  open: false,
                })),
                month,
                year,
              },
            },
          ];
          return;
        }

        state.monthlySettingsForm = [
          ...state.monthlySettingsForm,
          {
            month: state.selectedDate.month,
            year: state.selectedDate.year,
            data: {
              month: state.selectedDate.month,
              year: state.selectedDate.year,
              company,
              promotions: [],
              product_launches: [],
              number_of_campaigns: null,
              number_of_flows: null,
            },
          },
        ];
      }
    );
    builder.addMatcher(putMonthlySettingsFulfilled, (state, { payload }) => {
      const form = state.monthlySettingsForm.find(
        form => form.month === payload.month && form.year === payload.year
      )?.data;
      if (form) form.pk = payload.pk;
    });
    builder.addMatcher(isAnyOf(patchEventDetailsFulfilled, deleteEventFulfilled), state => {
      state.selectedEvent = null;
    });
    builder.addMatcher(getCampaignLayoutFulfilled, (state, { payload }) => {
      if (payload.company) state.selectedCompany = payload.company;
    });
  },
});

export const {
  setSelectedCompany,
  setSelectedDate,
  setSelectedEvent,
  setMonthlySettings,
  addOrEditList,
  deleteListItem,
  openOrCloseItem,
  closeMonthlySettings,
  setEmails,
} = calendarSlice.actions;

export const selectCalendar = state => state.calendar;

export default calendarSlice.reducer;
