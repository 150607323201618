import { ErrorMessage } from '@hookform/error-message';
import { Button, Dialog, Input, LoaderHOC } from 'components';
import { useSendRecoveryEmailForm } from 'hooks/form/passwordRecovery/useSendRecoveryEmail';
import { emailRecoverySchemaKeys } from 'hooks/schemas/useSendRecoveryEmailSchema';
import { useTranslation } from 'hooks/utils/useTranslation';
import { Controller } from 'react-hook-form';
import styles from './styles.module.scss';

export const RecoveryEmailCard = ({ visible, onHide }) => {
  const t = useTranslation();
  const { form, onSubmit, resetRequest, isSuccess, isError, isLoading } =
    useSendRecoveryEmailForm();

  const handleHide = () => {
    onHide();
    resetRequest();
    form.reset();
  };

  if (isSuccess) {
    return (
      <Dialog visible={visible} onHide={handleHide} headerClassName={styles.hiddenHeader}>
        <div className="Body Regular Large">{t('login.labels.recover.success')}</div>
      </Dialog>
    );
  }

  if (isError) {
    return (
      <Dialog visible={visible} onHide={handleHide} headerClassName={styles.hiddenHeader}>
        <div className="Body Regular Large">{t('login.labels.recover.error')}</div>
      </Dialog>
    );
  }

  return (
    <Dialog
      visible={visible}
      onHide={handleHide}
      header={() => <div className="Title Medium Bold">{t('login.labels.recover.title')}</div>}
    >
      <form onSubmit={onSubmit}>
        <LoaderHOC isLoading={isLoading}>
          <Controller
            name={emailRecoverySchemaKeys.email}
            control={form.control}
            render={({ field }) => (
              <Input
                className="w-full"
                inputClassName="w-full mb-3"
                value={field.value}
                onChange={field.onChange}
                placeholder={t('login.labels.recover.placeholder')}
                special
                onBlur={field.onBlur}
              />
            )}
          />
          <ErrorMessage
            errors={form.formState.errors}
            name={emailRecoverySchemaKeys.email}
            render={({ message }) => <div className="Body Small Bold Red500 mb-2">{message}</div>}
          />
          <Button className="w-full" title={t('login.labels.recover.submit')} type="submit" />
        </LoaderHOC>
      </form>
    </Dialog>
  );
};
