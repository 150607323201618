import classNames from 'classnames';
import { Checkbox, MultiSelect } from 'components';
import { useTranslation } from 'hooks/utils/useTranslation';
import { Controller } from 'react-hook-form';
import { prepareField } from 'utils/onboardingSteps';
import { maxBrandGoals, maxBrandWords } from 'constants/constants';
import styles from '../styles.module.scss';
import { settingsSchemaKeys } from 'hooks/schemas/useSettingsSchema';

export const AboutCompany = ({ settingsOptions, control, setValue }) => {
  const t = useTranslation({ keyPrefix: 'dossier.aboutYou.card.aboutCompanySection.' });

  return (
    <>
      <div className={classNames('Title Small pb-0', styles.Title)}>{t('title')}</div>
      <h4 className={classNames('Body Bold Large mb-2')}>
        {t('accomplish.title')}{' '}
        <span className="Body Large Regular">{t('upTo', { max: maxBrandGoals })}</span>
      </h4>
      <Controller
        name={settingsSchemaKeys.brand_goals}
        control={control}
        render={({ field, fieldState, formState }) => (
          <div className={styles.CheckboxList}>
            {settingsOptions.brand_goal_options.map(goal => (
              <Checkbox
                key={goal.value}
                title={goal.label}
                checked={!!field.value.includes(goal.value)}
                error={!!fieldState.error?.type}
                onChange={e => {
                  setValue(
                    settingsSchemaKeys.brand_goals,
                    e.checked
                      ? [...field.value, goal.value]
                      : field.value.filter(v => v !== goal.value),
                    { shouldValidate: true, shouldDirty: true }
                  );
                }}
                className={styles.Checkbox}
                onBlur={field.onBlur}
              />
            ))}
          </div>
        )}
      />

      <h4 className={classNames('Body Bold Large mb-2')}>
        {t('brand.title')}{' '}
        <span className="Body Large Regular">{t('upTo', { max: maxBrandWords })}</span>
      </h4>
      <Controller
        name={settingsSchemaKeys.brand_words}
        control={control}
        render={({ field, fieldState }) => (
          <MultiSelect
            id={field.name}
            showSelectAll={false}
            panelHeaderTemplate={() => null}
            options={settingsOptions.brand_word_options}
            title={t('brand.label', { max: maxBrandWords })}
            error={!!fieldState.error}
            placeholder={t('brand.placeholder')}
            selectionLimit={maxBrandWords}
            {...prepareField(field)}
          />
        )}
      />
    </>
  );
};
