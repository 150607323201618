import { tagTypes } from 'constants/constants';
import { endpoints } from 'constants/endpoints';
import { api } from 'services/api';

const authApi = api.injectEndpoints({
  endpoints: builder => ({
    getUserInfo: builder.query({
      query: () => ({
        url: endpoints.USER_INFO,
        method: 'GET',
      }),
      providesTags: res => (res ? [tagTypes.USER_INFO] : []),
    }),
    patchUserInfo: builder.mutation({
      query: userInfo => ({
        url: endpoints.USER_INFO,
        method: 'PATCH',
        body: userInfo,
      }),
      invalidatesTags: res => (res ? [tagTypes.USER_INFO] : []),
    }),
    googleLogin: builder.mutation({
      query: code => ({
        url: endpoints.GOOGLE_SIGN_IN,
        method: 'POST',
        body: { code },
      }),
    }),
    googleLogout: builder.mutation({
      query: () => ({
        url: endpoints.GOOGLE_SIGN_OUT,
        method: 'DELETE',
      }),
    }),
    login: builder.mutation({
      query: body => ({
        url: endpoints.SIGN_IN,
        method: 'POST',
        body,
      }),
    }),
    signup: builder.mutation({
      query: body => ({
        url: endpoints.SIGN_UP,
        method: 'POST',
        body,
      }),
    }),
    sendRecoveryEmail: builder.mutation({
      query: body => ({
        url: endpoints.RECOVERY_EMAIL,
        method: 'POST',
        body,
      }),
    }),
    recoverPassword: builder.mutation({
      query: body => ({
        url: endpoints.RECOVER_PASSWORD,
        method: 'POST',
        body,
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetUserInfoQuery,
  useGoogleLoginMutation,
  useGoogleLogoutMutation,
  usePatchUserInfoMutation,
  useLoginMutation,
  useSignupMutation,
  useRecoverPasswordMutation,
  useSendRecoveryEmailMutation,
  endpoints: {
    getUserInfo: { matchFulfilled: getUserInfoFulfilled },
    googleLogin: { matchFulfilled: googleLoginFulfilled },
    googleLogout: { matchFulfilled: logoutFulfilled },
    signup: { matchFulfilled: signupFulfilled },
    login: { matchFulfilled: loginFulfilled },
  },
} = authApi;

export const selectAuth = state => state.auth;
