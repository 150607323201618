import { calendar, layoutLeft, trashAlt } from 'assets';
import classNames from 'classnames';
import { Button, ConfirmDialog, DatePicker, LoaderHOC } from 'components';
import { eventTypes } from 'constants/constants';
import { posthogEvents, posthogOptions } from 'constants/posthog';
import { useChangeCampaignDate } from 'hooks/calendar/useChangeCampaignDate';
import { useDeleteEvent } from 'hooks/calendar/useDeleteEvent';
import { useTranslation } from 'hooks/utils/useTranslation';
import { posthog } from 'posthog-js';
import { Tooltip } from 'primereact/tooltip';
import { useGetEventDetailsQuery } from 'services/calendar';
import { ellipsis, i18nToBold } from 'utils/formatHelper';
import { SwapOption } from '../SwapOption';
import styles from './styles.module.scss';
import { useCompany } from 'hooks/state/useCompany';
import { useState } from 'react';

export const CampaignDetails = ({ campaign }) => {
  const t = useTranslation();
  const { isOwnedCompany, selectedShareToken } = useCompany();
  const { data: eventDetails, isFetching } = useGetEventDetailsQuery({
    eventId: campaign.pk,
    shareToken: selectedShareToken,
  });
  const {
    switchShowDate,
    onClickDate,
    minDate,
    campaignDate,
    maxDate,
    formattedCampaignDate,
    isLoading: isPatching,
    showChangeDate,
    dateClassName,
    history,
  } = useChangeCampaignDate(campaign);
  const { onDelete, showDialog, onClickDelete, onCloseDialog } = useDeleteEvent({
    eventId: campaign.pk,
    startDate: campaign.start,
  });
  const [extendedTitle, setExtendedTitle] = useState(false);

  return (
    <div
      className={classNames(
        styles.CampaignDetails,
        styles[eventTypes[campaign.parent.event_type || eventTypes.campaign] || 'other']
      )}
    >
      <LoaderHOC isLoading={isFetching || isPatching}>
        {campaign.parent.title && (
          <div className={classNames('mb-3', styles.Title)}>{campaign.parent.title}</div>
        )}
        <div className={styles.Details}>
          <div className={styles.Header}>
            <span className={styles.Dot} />
            <button
              title={campaign.title}
              className={classNames(styles.CampaignTitle, 'ml-2', extendedTitle && styles.extended)}
              onClick={() => setExtendedTitle(!extendedTitle)}
            >
              {campaign.title}
            </button>
            {isOwnedCompany ? (
              <button className={styles.DeleteCampaign} onClick={onClickDelete}>
                <Tooltip target={'.' + styles.DeleteCampaign} position="top">
                  {t('campaignDetails.delete.tooltip')}
                </Tooltip>
                <img src={trashAlt} alt={t('a11y.deleteCampaign')} />
              </button>
            ) : (
              <div />
            )}
            <ConfirmDialog
              visible={showDialog}
              title={t('campaignDetails.delete.confirm.title')}
              onClose={onCloseDialog}
              onConfirm={onDelete}
              text={t('campaignDetails.delete.confirm.text', {
                campaignName: ellipsis(campaign.title, 30),
              })}
              cancelTitle={t('campaignDetails.delete.confirm.cancel')}
              confirmTitle={t('campaignDetails.delete.confirm.confirm')}
            />
          </div>
          <div className={styles.DateContainer}>
            <div className="Body Bold Medium Blue500 inline-block">{formattedCampaignDate}</div>
            {isOwnedCompany && (
              <button onClick={switchShowDate} disabled={!isOwnedCompany}>
                <div className="Body Bold Medium Orange400 inline-block">
                  {t('campaignDetails.change')}
                </div>
                <img src={calendar} alt={t('a11y.changeDate')} />
              </button>
            )}
            <div className={classNames(styles.ChangeDate, { hidden: !showChangeDate })}>
              <DatePicker
                value={campaignDate}
                showInputIcons={false}
                onChange={onClickDate}
                minDate={minDate}
                maxDate={maxDate}
                viewDate={campaignDate}
                innerClassName={dateClassName}
                disabledDates={[campaignDate]}
                readOnlyInput
                special
                inline
              />
            </div>
          </div>
          <div className="Body Regular Small Blue500">{campaign.description}</div>
          <Button
            palette="Orange"
            size="Small"
            title={t('campaignDetails.emailLayout')}
            icon={layoutLeft}
            className="w-full mt-3 mb-5"
            onClick={() => {
              posthog.capture(
                posthogEvents.pages.calendar.campaign.CLICK_EMAIL_LAYOUT,
                posthogOptions({ campaignId: campaign.pk })
              );
              history.push(
                `/campaign/${campaign.pk}/layout${
                  selectedShareToken ? `?token=${selectedShareToken}` : ''
                }`
              );
            }}
          />
          {!!eventDetails?.alternatives.length && isOwnedCompany && (
            <>
              <div className="Title Small Blue600">{t('campaignDetails.otherOptions')}</div>
              <div className="Body Regular Medium Blue600 mt-1">
                {t('campaignDetails.optionsDescription', { b: i18nToBold('Body', 'Medium') })}
              </div>
              {eventDetails?.alternatives.map(option => (
                <SwapOption option={option} key={option.pk} eventDetails={eventDetails} />
              ))}
            </>
          )}
        </div>
      </LoaderHOC>
    </div>
  );
};
