import { useMemo } from 'react';

export const useDataMapper = (data, key) => {
  return useMemo(
    () =>
      data?.[key].map(({ pk, name }) => ({
        label: name,
        value: pk,
      })),
    [data, key]
  );
};
