import { posthogOptions } from 'constants/posthog';
import { settingsSchemaKeys } from 'hooks/schemas/useSettingsSchema';
import { useHistoryImpl } from 'hooks/utils/useHistoryImpl';
import { useTranslation } from 'hooks/utils/useTranslation';
import { posthog } from 'posthog-js';
import { toast } from 'react-toastify';
import { routesPaths } from 'routes/routesPaths';

const getCsvError = err => {
  if (!Array.isArray(err?.data) || !err?.data?.length) return null;
  return Object.keys(err.data[0])[0];
};

export const useModifyCompanySettings = ({ companyId, modifySettings, posthogEvent }) => {
  const t = useTranslation();
  const { push } = useHistoryImpl();

  const onSubmit = async settings => {
    const { product_catalog, collection_catalog, industries, ...body } = settings;
    const formData = new FormData();
    Object.keys(body).forEach(key => {
      if (Array.isArray(body[key])) {
        body[key].forEach(listItem => formData.append(key, listItem));
      } else {
        formData.append(key, body[key]);
      }
    });

    if (product_catalog?.length) {
      formData.append(
        settingsSchemaKeys.product_catalog,
        product_catalog[0],
        product_catalog[0].name
      );
    } else {
      formData.append(settingsSchemaKeys.product_catalog, '');
    }
    if (collection_catalog?.length) {
      formData.append(
        settingsSchemaKeys.collection_catalog,
        collection_catalog[0],
        collection_catalog[0].name
      );
    } else {
      formData.append(settingsSchemaKeys.collection_catalog, '');
    }

    const { error } = await modifySettings({ body: formData, companyId });
    posthog.capture(posthogEvent, posthogOptions(!!error ? { error } : null));
    if (!!error) {
      if (getCsvError(error)) {
        toast.error(t(`settings.errors.csv_${getCsvError(error)}`));
      } else {
        toast.error(t('settings.errors.submit'));
      }
    } else {
      toast.success(t('settings.success.submit'));
      push(routesPaths.calendar);
    }
  };

  return { onSubmit };
};
