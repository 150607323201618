import { tagTypes } from 'constants/constants';
import { endpoints } from 'constants/endpoints';
import { api } from 'services/api';

const emailLayoutApi = api.injectEndpoints({
  endpoints: builder => ({
    getCampaignLayout: builder.query({
      query: ({ id, token }) => ({
        url: endpoints.CAMPAIGN_EMAIL.replace(':id', id) + (token ? `?token=${token}` : ''),
        method: 'GET',
      }),
      transformResponse: (res, err, arg) => ({ ...res, pk: arg.id }),
      providesTags: (_res, _err, arg) => [{ type: tagTypes.CAMPAIGN_EMAIL, id: arg.id }],
    }),
    patchCampaignLayout: builder.mutation({
      query: ({ body, id }) => ({
        url: endpoints.CAMPAIGN_EMAIL.replace(':id', id),
        body,
        method: 'PATCH',
      }),
      invalidatesTags: (_res, _err, arg) => [{ type: tagTypes.CAMPAIGN_EMAIL, id: arg.id }],
    }),
    swapModule: builder.mutation({
      query: ({ currentModule, newModule, campaignId }) => ({
        url: endpoints.CAMPAIGN_EMAIL_MODULES.replace(':id', campaignId),
        body: { current: currentModule, new: newModule },
        method: 'PUT',
      }),
      invalidatesTags: (_res, _err, arg) => [{ type: tagTypes.CAMPAIGN_EMAIL, id: arg.campaignId }],
    }),
    exportLayout: builder.mutation({
      query: campaignId => ({
        url: endpoints.EXPORT_LAYOUT.replace(':id', campaignId),
        cache: 'no-cache',
        method: 'GET',
        responseHandler: res => res.blob(),
      }),
      transformResponse: (result, meta) => {
        var hiddenElement = document.createElement('a');
        var url = window.URL || window.webkitURL;
        var blobFile = url.createObjectURL(result);
        const fileName =
          meta.response.headers
            .get('Content-Disposition')
            ?.split('filename=')[1]
            ?.replace(/['"]/g, '') || 'wireframe.sketch';
        hiddenElement.href = blobFile;
        hiddenElement.target = '_blank';
        hiddenElement.download = fileName;
        hiddenElement.click();
        return { data: null };
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetCampaignLayoutQuery,
  usePatchCampaignLayoutMutation,
  useSwapModuleMutation,
  useExportLayoutMutation,
  endpoints: {
    getCampaignLayout: { matchFulfilled: getCampaignLayoutFulfilled },
    patchCampaignLayout: { matchFulfilled: patchCampaignLayoutFulfilled },
    swapModule: { matchFulfilled: swapModuleFulfilled },
  },
} = emailLayoutApi;

export const selectAuth = state => state.auth;
