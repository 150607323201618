import classNames from 'classnames';
import styles from './styles.module.scss';

export const PlaceHolder = ({
  children,
  active = true,
  visible = true,
  text,
  error,
  textClassName = 'Subtitle Bold Large',
  palette = 'Primary',
  onClick = e => e.preventDefault(),
  size = 'Large',
  className,
  renderPlaceholder = null,
}) => {
  if (!visible) return null;

  if (active) {
    if (renderPlaceholder) return renderPlaceholder();
    return (
      <div
        className={classNames(
          styles.Placeholder,
          error && styles.Error,
          styles[size],
          styles[palette],
          className
        )}
      >
        <button onClick={onClick}>
          <span className={classNames(textClassName)}>{text}</span>
        </button>
      </div>
    );
  }

  return children;
};
