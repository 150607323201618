import { posthogEvents, posthogOptions } from 'constants/posthog';
import { useCompany } from 'hooks/state/useCompany';
import { useTranslation } from 'hooks/utils/useTranslation';
import { posthog } from 'posthog-js';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { selectAuth, useGetUserInfoQuery, usePatchUserInfoMutation } from 'services/auth/auth';
import { usePatchEventDetailsMutation } from 'services/calendar';
import { selectCalendar } from 'state/slices/calendarSlice';
import { getCalendarDate } from 'utils/calendarHelper';

export const useSwapEvent = () => {
  const t = useTranslation();
  const [showDescription, setShowDescription] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [dontAskAgain, setDontAskAgain] = useState(false);
  const { authenticated } = useSelector(selectAuth);
  useGetUserInfoQuery(null, { skip: !authenticated });
  const [patchUserInfo] = usePatchUserInfoMutation();
  const [swapEventDetails, { isLoading }] = usePatchEventDetailsMutation();
  const {
    user: {
      ask_confirmation: { swap_event: askConfirmation },
      company,
    },
  } = useSelector(selectAuth);
  const { selectedDate } = useSelector(selectCalendar);
  const { selectedEvent } = useSelector(selectCalendar);
  const { isOwnedCompany } = useCompany();

  const disableConfirmation = () => patchUserInfo({ ask_confirmation: { swap_event: false } });

  const onSwapEvent = async (eventDetails, option, dontAskAgain) => {
    setShowConfirmDialog(false);
    const body = {
      pk: selectedEvent.pk,
      main: option.pk,
      alternatives: [
        ...eventDetails.alternatives
          .filter(alt => alt.pk !== option.pk)
          .map(({ pk }) => ({
            pk,
          })),
        { pk: eventDetails.main },
      ],
    };
    const result = await swapEventDetails({
      body,
      calendar: { ...getCalendarDate(selectedEvent, selectedDate), company },
    });
    if (result.error)
      toast.error(t('campaignDetails.swap.confirm.error'), { position: toast.POSITION.TOP_CENTER });
    else {
      if (dontAskAgain) disableConfirmation();
      toast.success(t('campaignDetails.swap.confirm.success'), {
        position: toast.POSITION.TOP_CENTER,
      });
      posthog.capture(posthogEvents.pages.calendar.campaign.SWAP, posthogOptions());
    }
  };

  const onClickSwap = (eventDetails, option) =>
    askConfirmation ? setShowConfirmDialog(true) : onSwapEvent(eventDetails, option);

  const onCloseDialog = () => {
    setShowConfirmDialog(false);
    setDontAskAgain(false);
  };

  const onClickDescription = () => setShowDescription(!showDescription);

  const onClickDontAskAgain = e => setDontAskAgain(e.checked);

  return {
    onSwapEvent,
    t,
    onClickSwap,
    onCloseDialog,
    onClickDescription,
    onClickDontAskAgain,
    state: {
      showDescription,
      dontAskAgain,
      showConfirmDialog,
      isSwapEventLoading: isLoading,
      isOwnedCompany,
    },
  };
};
