import classNames from 'classnames';
import { IndustryCheckbox, PlaceHolder } from 'components';
import { ToggleQuestion } from 'components/form/ToggleQuestion';
import { ProductTypes } from 'components/ProductTypes';
import { settingsSchemaKeys } from 'hooks/schemas/useSettingsSchema';
import { useTranslation } from 'hooks/utils/useTranslation';
import { Controller } from 'react-hook-form';
import { prepareField } from 'utils/onboardingSteps';
import styles from '../styles.module.scss';

export const AboutIndustry = ({ settingsOptions, control, setValue, getValues, watch }) => {
  const t = useTranslation({ keyPrefix: 'dossier.aboutYou.card.aboutIndustrySection.' });

  const industries = watch(settingsSchemaKeys.industries);
  const onChangeCheckbox = (e, industry) => {
    if (e.checked) {
      setValue(settingsSchemaKeys.industries, [...industries, industry.value], {
        shouldValidate: true,
        shouldDirty: true,
      });
    } else {
      setValue(
        settingsSchemaKeys.industries,
        industries.filter(checked => checked !== industry.value),
        {
          shouldValidate: true,
          shouldDirty: true,
        }
      );
    }
  };

  return (
    <>
      <div className={classNames('Title Small pb-0', styles.Title)}>{t('title')}</div>
      <h4 className={classNames('Body Bold Large mb-2')}>
        {t('industry.title')} <span className="Body Large Regular">{t('select')}</span>
      </h4>
      <div className={styles.CheckboxList}>
        {settingsOptions.industry_options.map(industry => (
          <IndustryCheckbox
            key={industry.value}
            title={industry.label}
            icon={industry.icon}
            industry={industry}
            checked={!!industries.find(checked => checked === industry.value)}
            onChange={e => onChangeCheckbox(e, industry)}
            className={styles.Checkbox}
            setValue={setValue}
            getValues={getValues}
          />
        ))}
      </div>
      <h4 className={classNames('Body Bold Large mb-2')}>
        {t('products.title')} <span className="Body Large Regular">{t('select')}</span>
      </h4>
      <div className={styles.ProductTypes}>
        <Controller
          name={settingsSchemaKeys.industry_types}
          control={control}
          render={({ field, fieldState }) => (
            <PlaceHolder
              active={!industries.length}
              text={t('products.placeholder')}
              error={fieldState.error}
            >
              {industries.map(checkedIndustry => (
                <ProductTypes
                  key={checkedIndustry}
                  industry={settingsOptions.industry_options.find(
                    ({ value }) => checkedIndustry === value
                  )}
                  field={field}
                  setValue={setValue}
                  getValues={getValues}
                />
              ))}
            </PlaceHolder>
          )}
        />
      </div>
      <div className={styles.Offerings}>
        <Controller
          name={settingsSchemaKeys.bundle_offerings}
          control={control}
          render={({ field }) => (
            <ToggleQuestion
              title={t('bundle')}
              tooltipDescription={t('bundleTooltip')}
              tooltipIconLeft
              {...prepareField(field)}
            />
          )}
        />
        <Controller
          name={settingsSchemaKeys.subscription_offerings}
          control={control}
          render={({ field }) => (
            <ToggleQuestion
              title={t('subscription')}
              tooltipDescription={t('subscriptionTooltip')}
              tooltipIconLeft
              {...prepareField(field)}
            />
          )}
        />
      </div>
    </>
  );
};
