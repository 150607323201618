import styles from './styles.module.scss';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { routesPaths } from 'routes/routesPaths';
import { string } from 'prop-types';
import { BackboneLogo } from 'assets';

export const Logo = ({ className, theme }) => {
  const title = 'backbone';

  return (
    <Link to={routesPaths.index} className={styles.Link}>
      <div className={classNames(styles.Logo, styles[theme], className)}>
        <img src={BackboneLogo} className={styles.Logo__Icon} alt="logo" />
        <div className={styles.Logo__Title}>{title}</div>
      </div>
    </Link>
  );
};

Logo.propTypes = {
  className: string,
};
