import { Left, Right } from 'assets';
import classNames from 'classnames';
import { Button, OnboardingStep } from 'components';
import { posthogEvents } from 'constants/posthog';
import { useModifyCompanySettings } from 'hooks/form/useModifyCompanySettings';
import { useTranslation } from 'hooks/utils/useTranslation';
import { Settings } from 'pages/Settings';
import { Card } from 'primereact/card';
import { Link } from 'react-router-dom';
import { routesPaths } from 'routes/routesPaths';
import { useSaveSettingsMutation } from 'services/form/settings';
import styles from './styles.module.scss';

export const Dossier = () => {
  const t = useTranslation();
  const [modifySettings, { isLoading: isSaveSettingsLoading }] = useSaveSettingsMutation();
  const { onSubmit } = useModifyCompanySettings({
    companyId: null,
    modifySettings,
    posthogEvent: posthogEvents.pages.dossier.SUBMIT,
  });

  return (
    <OnboardingStep stepNumber={0}>
      <div className={styles.Header}>
        <Link to={routesPaths.welcome}>
          <img src={Left} alt={t('a11y.back')} />
        </Link>
        <div className={styles.Text}>
          <span className="Title Large">{t('dossier.aboutYou.title')}</span>
          <span className="Subtitle Regular">{t('dossier.aboutYou.subtitle')}</span>
        </div>
      </div>
      <Settings
        onSubmit={onSubmit}
        isSaveSettingsLoading={isSaveSettingsLoading}
        submitCard={() => (
          <Card className={classNames('Card Dark', styles.CardNext)}>
            <h3 className="Body Bold Large White mb-2">{t('dossier.aboutYou.nextTitle')}</h3>
            <h4 className="Body Regular Medium White font-italic mt-2">
              {t('dossier.aboutYou.nextSubtitle')}
            </h4>
            <h1 className="Subtitle Bold Large White mt-5">{t('dossier.aboutYou.nextTime')}</h1>
            <Button
              type="submit"
              icon={Right}
              title={t('dossier.aboutYou.nextBtn')}
              palette="Orange"
              className={styles.ButtonNext}
              width="100%"
            />
          </Card>
        )}
      />
    </OnboardingStep>
  );
};
