import { posthogEvents, posthogOptions } from 'constants/posthog';
import { useCompany } from 'hooks/state/useCompany';
import { useTranslation } from 'hooks/utils/useTranslation';
import { posthog } from 'posthog-js';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useDeleteEventMutation } from 'services/calendar';

export const useDeleteEvent = ({ eventId, startDate }) => {
  const t = useTranslation();
  const [deleteEvent] = useDeleteEventMutation();
  const eventDate = new Date(startDate);
  const month = eventDate?.getMonth() + 1;
  const year = eventDate?.getFullYear();
  const [showDialog, setShowDialog] = useState(false);
  const { selectedCompany: company } = useCompany();

  const onDelete = async () => {
    try {
      const res = await deleteEvent({
        eventId,
        calendar: { month, year, company },
      });

      if (!res.error) {
        toast.success(t('eventDetails.delete.success'));
      } else {
        toast.error(t('eventDetails.delete.error'));
      }
      posthog.capture(
        posthogEvents.pages.calendar.event.DELETE,
        posthogOptions(!!res.error ? { error: res.error } : null)
      );
    } catch (err) {
      toast.error(t('eventDetails.delete.error'));
    }
  };

  return {
    onDelete,
    showDialog,
    onClickDelete: () => setShowDialog(true),
    onCloseDialog: () => setShowDialog(false),
  };
};
