import { Button, Layout, LoaderHOC, Password } from 'components';
import { useRecoverPasswordForm } from 'hooks/form/passwordRecovery/useRecoverPasswordForm';
import { useQuery } from 'hooks/utils/useQuery';
import { useTranslation } from 'hooks/utils/useTranslation';
import { Card } from 'primereact/card';
import { Controller } from 'react-hook-form';
import { Redirect } from 'react-router-dom';
import { routesPaths } from 'routes/routesPaths';
import { prepareField } from 'utils/onboardingSteps';
import styles from './styles.module.scss';

export const RecoverPassword = () => {
  const t = useTranslation();
  const query = useQuery();
  const [token, uid] = [query.get('token'), query.get('uidb64')];
  const {
    form: { control },
    onSubmit,
    isLoading,
    isSuccess,
  } = useRecoverPasswordForm({ uid, token });

  if (!token || !uid) return <Redirect to={routesPaths.login} />;

  return (
    <Layout className={styles.Layout} menuTheme="light">
      <Card title={t('passwordRecovery.labels.title')} className="Card w-4">
        <LoaderHOC isLoading={isLoading}>
          {isSuccess ? (
            <div className="Body Regular Large">{t('passwordRecovery.labels.successMessage')}</div>
          ) : (
            <form className="mb-3" onSubmit={onSubmit}>
              <Controller
                name="new_password1"
                control={control}
                render={({ field, fieldState }) => (
                  <Password
                    id={field.name}
                    special
                    className="w-full mb-2"
                    inputClassName="w-full"
                    feedback={false}
                    error={fieldState.error}
                    placeholder={t('passwordRecovery.labels.password1')}
                    {...prepareField(field)}
                  />
                )}
              />
              <Controller
                name="new_password2"
                control={control}
                render={({ field, fieldState }) => (
                  <Password
                    id={field.name}
                    special
                    className="w-full mb-3"
                    inputClassName="w-full"
                    feedback={false}
                    error={fieldState.error}
                    placeholder={t('passwordRecovery.labels.password2')}
                    {...prepareField(field)}
                  />
                )}
              />
              <Button
                type="submit"
                title={t('passwordRecovery.labels.submit')}
                className="w-full mt-3"
                size="Small"
              />
            </form>
          )}
        </LoaderHOC>
      </Card>
    </Layout>
  );
};
