import { useSelector } from 'react-redux';
import { selectAuth } from 'services/emailLayout';
import { selectCalendar } from 'state/slices/calendarSlice';

export const useCompany = () => {
  const { user } = useSelector(selectAuth);
  const { selectedCompany, selectedShareToken } = useSelector(selectCalendar);

  return {
    isOwnedCompany:
      !!selectedCompany &&
      ((!!user?.company && selectedCompany === user?.company) || user?.is_staff),
    userCompany: user?.company,
    selectedCompany,
    selectedShareToken,
  };
};
