import classNames from 'classnames';
import styles from './styles.module.scss';
import { Dialog as PrimeDialog } from 'primereact/dialog';

export const Dialog = ({ visible, children, headerClassName, contentClassName, ...props }) => {
  return (
    <PrimeDialog
      visible={visible}
      draggable={false}
      headerClassName={classNames(styles.Dialog__Header, headerClassName)}
      contentClassName={classNames(styles.Dialog__Content, contentClassName)}
      className={styles.Dialog}
      {...props}
    >
      {children}
    </PrimeDialog>
  );
};
