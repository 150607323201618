import classNames from 'classnames';
import { Button as Btn } from 'primereact/button';
import { array, bool, func, oneOf, string } from 'prop-types';
import styles from './styles.module.scss';

export const Button = ({
  children,
  type = 'button',
  disabled,
  onClick,
  title,
  icon,
  iconClassName,
  iconPos = 'Right',
  className,
  contentClassName,
  palette = 'Primary',
  theme = 'Dark',
  width,
  height,
  size = 'Large',
  ...props
}) => {
  const getIcon = icon =>
    icon && <img src={icon} className={classNames(styles.Icon, iconClassName)} alt="button-icon" />;

  return (
    <Btn
      type={type}
      disabled={disabled}
      onClick={onClick}
      iconPos={iconPos}
      {...props}
      className={classNames(
        styles.Button,
        styles[size],
        styles[theme],
        !disabled && styles[palette],
        { [styles.Disabled]: disabled },
        className
      )}
      style={{ width, height }}
    >
      <div className={classNames(styles.Content, contentClassName)}>
        {'Left' === iconPos && getIcon(icon)}
        {title && <span className="CTA">{title}</span>}
        {'Right' === iconPos && getIcon(icon)}
      </div>
    </Btn>
  );
};

Button.propTypes = {
  onClick: func,
  disabled: bool,
  type: string,
  title: string,
  props: array,
  palette: oneOf(['Orange', 'Primary', 'Secondary']),
  size: oneOf(['Large', 'Small']),
};
