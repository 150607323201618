import { TooltipAlt as TooltipIcon } from 'assets';
import { Tooltip as PrimeTooltip } from 'primereact/tooltip';
import styles from './styles.module.scss';

export const Tooltip = ({ children, description = '', position, className, autoHide = true }) => {
  return (
    <div className={className}>
      <PrimeTooltip
        target={'.' + styles.Tooltip__Icon}
        className={styles.Tooltip}
        autoHide={autoHide}
      >
        {children}
      </PrimeTooltip>
      <img
        src={TooltipIcon}
        alt="Tooltip"
        className={styles.Tooltip__Icon}
        data-pr-tooltip={description}
        data-pr-position={position}
      />
    </div>
  );
};
