import styles from './styles.module.scss';
import classNames from 'classnames';
import { PrimeIcons } from 'primereact/api';

export const MenuButton = ({ item, options, className, theme, ...props }) => {
  const renderIcon = icon => {
    if (!icon) return null;
    if (typeof icon == 'string') return <i className={icon} />;
    return icon;
  };

  return (
    <button
      className={classNames(styles.MenuButton, styles[className], styles[theme], styles.btnHover)}
      target={item.target}
      onClick={options.onClick}
      {...props}
    >
      {renderIcon(item.icon)}
      <span className={options.labelClassName}>{item.label}</span>
      {item.dropdown && <i className={PrimeIcons.ANGLE_DOWN} />}
    </button>
  );
};
