import classNames from 'classnames';
import { useRef } from 'react';
import { getIcon } from 'utils/icons';
import { Box } from './Box';
import styles from './styles.module.scss';

export const Checkbox = ({
  checked,
  onChange,
  title,
  icon,
  className,
  error,
  onBlur,
  ...props
}) => {
  const ref = useRef(null);

  const onClick = () => ref.current.click();

  return (
    <div
      className={classNames(
        styles.Checkbox,
        checked && styles.Checked,
        error && styles.Error,
        className
      )}
      onClick={onClick}
      tabIndex={0}
      role="button"
      onKeyDown={onClick}
    >
      {icon && <img src={getIcon(icon)} alt="checkbox icon" />}
      <label className={classNames('Body Regular Medium')}>{title}</label>
      <Box innerRef={ref} checked={checked} onChange={onChange} onBlur={onBlur} {...props} />
    </div>
  );
};
