import { IconGoogle } from 'assets';
import classNames from 'classnames';
import { useTranslation } from 'hooks/utils/useTranslation';
import { func, string } from 'prop-types';
import styles from './styles.module.scss';

export const GoogleButton = ({ onClick, className, title, disabled = false, ...props }) => {
  const t = useTranslation();
  return (
    <button
      onClick={onClick}
      className={classNames(styles.GoogleButton, className)}
      disabled={disabled}
      {...props}
    >
      {title}
      <img src={IconGoogle} alt={t('a11y.googleLogo')} />
    </button>
  );
};

GoogleButton.propTypes = {
  onClick: func,
  className: string,
};
