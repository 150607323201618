import { zodResolver } from '@hookform/resolvers/zod';
import { useSendRecoveryEmailSchema } from 'hooks/schemas/useSendRecoveryEmailSchema';
import { useForm } from 'react-hook-form';
import { useSendRecoveryEmailMutation } from 'services/auth/auth';

export const useSendRecoveryEmailForm = () => {
  const { defaultValues, schema } = useSendRecoveryEmailSchema();
  const [sendRecoveryEmail, { isSuccess, isError, reset: resetRequest, isLoading }] =
    useSendRecoveryEmailMutation();

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const onSubmit = async body => {
    const res = await sendRecoveryEmail(body);
    if (!res.error) {
      form.reset();
      resetRequest();
    }
  };

  return {
    form,
    onSubmit: form.handleSubmit(onSubmit),
    resetRequest,
    isSuccess,
    isLoading,
    isError,
  };
};
