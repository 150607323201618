import { Left, Right } from 'assets';
import classNames from 'classnames';
import { Button, Layout, LoaderHOC } from 'components';
import { posthogEvents } from 'constants/posthog';
import { useModifyCompanySettings } from 'hooks/form/useModifyCompanySettings';
import { useTranslation } from 'hooks/utils/useTranslation';
import { Settings } from 'pages/Settings';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { routesPaths } from 'routes/routesPaths';
import { selectAuth } from 'services/emailLayout';
import {
  useGetCompanySettingsQuery,
  useUpdateCompanySettingsMutation,
} from 'services/form/settings';
import styles from './styles.module.scss';

export const CompanySettings = ({ className }) => {
  const t = useTranslation();
  const {
    user: { company },
  } = useSelector(selectAuth);
  const { data: savedSettings, isLoading, isError } = useGetCompanySettingsQuery(company);
  const [modifySettings, { isLoading: isSaveSettingsLoading }] = useUpdateCompanySettingsMutation();
  const { onSubmit } = useModifyCompanySettings({
    companyId: company,
    modifySettings,
    posthogEvent: posthogEvents.pages.dossier.UPDATE,
  });

  useEffect(() => {
    if (isError) {
      toast.error(t('settings.errors.companyInfo'));
    }
  }, [isError, t]);

  return (
    <Layout menuTheme={'light'} className={className}>
      <div className={styles.CompanySettings}>
        <div className={styles.Header}>
          <Link to={routesPaths.calendar}>
            <img src={Left} alt={t('a11y.back')} />
          </Link>
          <div className={styles.Text}>
            <span className="Title Large">{t('settings.title')}</span>
          </div>
        </div>
        <LoaderHOC isLoading={isLoading}>
          <Settings
            settings={savedSettings}
            isSaveSettingsLoading={isSaveSettingsLoading}
            onSubmit={onSubmit}
            className={'mt-3'}
            submitCard={formState => (
              <Button
                disabled={!formState.isDirty}
                type="submit"
                icon={Right}
                title={t('settings.labels.submit')}
                palette="Orange"
                className={classNames(styles.UpdateButton)}
              />
            )}
          />
        </LoaderHOC>
      </div>
    </Layout>
  );
};
