//Pageviews and pageleaves are tracked automatically
export const posthogEvents = {
  pages: {
    signUp: {
      SIGN_UP: 'Sign up',
    },
    login: {
      LOGIN: 'Login',
    },
    welcome: {
      DEMO_VIDEO: 'Click Demo Video',
    },
    dossier: {
      VIEW: 'View Dossier Page',
      SUBMIT: 'Complete Dossier',
      UPDATE: 'Update Dossier Settings',
    },
    calendar: {
      strategy: {
        promotion: {
          SUBMIT: 'Enter Promotion',
          DELETE: 'Delete Promotion (Strategy)',
        },
        productLaunch: {
          SUBMIT: 'Enter Product Launch',
          DELETE: 'Delete Product Launch (Strategy)',
        },
        EDIT_CAMPAIGN: 'Change Campaign Quota',
        EDIT_QUOTA: 'Change Email Quota',
        SUBMIT: 'Generate New Email Calendar',
      },
      campaign: {
        SWAP: 'Swap Campaign',
        CHANGE_DATE: 'Change Campaign Date',
        CLICK_EMAIL_LAYOUT: 'Click "See Email Layout"',
      },
      event: {
        DELETE: 'Delete Event',
      },
      SHARE: 'Share Calendar', //TODO
    },
    emailLayout: {
      brief: {
        SUBMIT: 'Generate Email Layout',
      },
      module: {
        SWAP: 'Swap Module in Email Layout',
        CLICK_SUGGESTED_CONTENT: 'Click Suggested Content',
        CLICK_PREVIEW_DESIGN_EXAMPLE: 'Click "Preview" button on Design Examples',
      },
      CLICK_PREVIEW: 'Click "Preview" on Email Layout',
      EXPORT_FIGMA: 'Export to Figma',
      SHARE: 'Share Email Layout', //TODO
    },
  },
};

export const posthogOptions = properties => ({
  module: 'backbone',
  ...properties,
});

export const componentStates = {
  CLOSE: 'close',
  OPEN: 'open',
};
