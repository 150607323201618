import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import styles from './styles.module.scss';
import { bool, func, string, object, oneOfType } from 'prop-types';

export const Input = ({
  className,
  inputClassName,
  value,
  title,
  placeholder,
  onChange,
  readOnly,
  error,
  filled,
  id,
  disabled = false,
  special = false,
  innerRef,
  ...props
}) => {
  return (
    <span
      className={classNames(
        styles.InputContainer,
        !!error && styles.Error,
        filled && styles.Filled,
        disabled && styles.Disabled,
        special && styles.Special,
        className
      )}
    >
      <label htmlFor={id}>{title}</label>
      <InputText
        id={id}
        type="text"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        readOnly={readOnly}
        aria-describedby={id + '-help'}
        className={classNames(styles.Input, inputClassName)}
        disabled={disabled}
        ref={innerRef}
        {...props}
      />
      {error?.message && <span className="Body Small Regular Red500">{error.message}</span>}
    </span>
  );
};

Input.propTypes = {
  className: string,
  title: string,
  placeholder: string,
  onChange: func,
  readOnly: bool,
  id: string,
  'aria-describedby': string,
  error: oneOfType([object, bool]),
  filled: bool,
  disabled: bool,
  special: bool,
};
