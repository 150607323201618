import { close } from 'assets';
import { Button, Dropdown, LoaderHOC, SlidingPanel, Tooltip } from 'components';
import { posthogEvents, posthogOptions } from 'constants/posthog';
import { useMonthlySettingsForm } from 'hooks/form/useMonthlySettingsForm';
import { monthlySettingsSchemaKeys } from 'hooks/schemas/useMonthlySettingsSchema';
import { useCompany } from 'hooks/state/useCompany';
import { useMonthlySettingsSlice } from 'hooks/useMonthlySettingsSlice';
import { useTranslation } from 'hooks/utils/useTranslation';
import { posthog } from 'posthog-js';
import { ProgressBar } from 'primereact/progressbar';
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useGetMonthlySettingsQuery } from 'services/form/monthlySettings';
import { selectCalendar, setEmails } from 'state/slices/calendarSlice';
import { i18nToBold } from 'utils/formatHelper';
import { prepareField } from 'utils/onboardingSteps';
import { FlowsSection } from './FlowsSection';
import styles from './styles.module.scss';
const { EDIT_CAMPAIGN, EDIT_QUOTA } = posthogEvents.pages.calendar.strategy;

export const MonthlySettings = ({ active, onClose }) => {
  const t = useTranslation({ keyPrefix: 'monthlySettings.' });
  const {
    selectedDate: { month, year },
  } = useSelector(selectCalendar);
  const { selectedCompany: company, isOwnedCompany } = useCompany();
  const { isLoading } = useGetMonthlySettingsQuery(
    { company, month, year },
    { skip: !company || !month || !year || !active }
  );
  const monthlyInfo = useMonthlySettingsSlice(month, year);
  const {
    form: { control, handleSubmit },
    onSubmit,
    isLoading: loadingSubmitForm,
    options: { amountOptions, monthName },
  } = useMonthlySettingsForm({ monthlyInfo, onClose });
  const dispatch = useDispatch();

  const onChangeEmails = (field, posthogEvent) => e => {
    dispatch(setEmails({ [field.name]: e.target.value }));
    posthog.capture(posthogEvent, posthogOptions({ field_value: e.target.value }));
    field.onChange(e);
  };

  if (!isOwnedCompany) return null;

  return (
    <SlidingPanel active={active}>
      <div className={styles.MonthlySettings}>
        <LoaderHOC isLoading={isLoading}>
          <div className={styles.Header}>
            <div className="Title Small Regular">{t('title')}</div>
            <button className={styles.Close} onClick={onClose}>
              <img src={close} alt={'Close'} />
            </button>
          </div>
          <div className={styles.EmailQuota}>
            <div className={styles.Title}>
              <div className="Body Bold Large">{t('emailQuota', { month: monthName })}</div>
              <Tooltip description={t('tooltip.emailQuota')} position="left" />
            </div>
            <div className={styles.AmountInput}>
              <span className="Body Regular Medium">
                {t('campaignsThisMonth', { b: i18nToBold('Body', 'Medium') })}
              </span>
              <Controller
                name={monthlySettingsSchemaKeys.number_of_campaigns}
                control={control}
                render={({ field, fieldState }) => (
                  <Dropdown
                    id={field.name}
                    options={amountOptions}
                    placeholder="---"
                    className={styles.Dropdown}
                    error={!!fieldState.error}
                    {...prepareField(field)}
                    onChange={onChangeEmails(field, EDIT_CAMPAIGN)}
                  />
                )}
              />
              <Tooltip description={t('tooltip.campaigns')} position="left" />
            </div>
            <div className={styles.AmountInput}>
              <span className="Body Regular Medium">
                {t('flowsThisMonth', { b: i18nToBold('Body', 'Medium') })}
              </span>
              <Controller
                name={monthlySettingsSchemaKeys.number_of_flows}
                control={control}
                render={({ field, fieldState }) => (
                  <Dropdown
                    id={field.name}
                    options={amountOptions}
                    placeholder="---"
                    className={styles.Dropdown}
                    error={fieldState.error}
                    {...prepareField(field)}
                    onChange={onChangeEmails(field, EDIT_QUOTA)}
                  />
                )}
              />
              <Tooltip description={t('tooltip.flows')} position="left" />
            </div>
          </div>
          <FlowsSection />
          <form onSubmit={handleSubmit(onSubmit)} className={styles.CTASection} id="emailForm">
            <Button
              type="submit"
              form="emailForm"
              palette="Orange"
              size="Small"
              title={t('saveUpdate')}
              className={styles.Button}
            />
          </form>
        </LoaderHOC>
      </div>
      <ProgressBar mode="indeterminate" className="GlobalProgressBar" hidden={!loadingSubmitForm} />
    </SlidingPanel>
  );
};
