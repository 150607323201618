import { tagTypes } from 'constants/constants';
import { endpoints } from 'constants/endpoints';
import { api } from 'services/api';

const shareApi = api.injectEndpoints({
  endpoints: builder => ({
    getShareToken: builder.query({
      query: id => ({
        url: endpoints.SHARE_TOKEN.replace(':id', id),
        method: 'GET',
      }),
      providesTags: (result, error, id) => {
        return result ? [{ type: tagTypes.SHARE_TOKEN, id }] : [];
      },
    }),
  }),
  overrideExisting: true,
});

export const { useGetShareTokenQuery } = shareApi;

export const selectShare = state => state.share;
