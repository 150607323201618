import { close, TooltipAlt } from 'assets';
import { useTranslation } from 'hooks/utils/useTranslation';
import styles from './styles.module.scss';

export const TipBanner = ({ text, onClose, active }) => {
  const t = useTranslation();

  if (!active) return null;

  return (
    <>
      <div className={styles.TipBanner}>
        <div className={styles.Logo}>
          <img src={TooltipAlt} alt={t('a11y.tip')} />
        </div>
        <div className={styles.Content}>
          <div>
            <span className="Body Bold Large Blue500 mr-1">{t('global.tip')}</span>
            <span className="Body Regular Large Blue600">{text}</span>
          </div>
          <button className={styles.Close} onClick={onClose}>
            <img src={close} alt={t('a11y.close')} />
          </button>
        </div>
      </div>
    </>
  );
};
