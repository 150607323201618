import { z } from 'zod';

export const emailRecoverySchemaKeys = {
  email: 'email',
};

export const useSendRecoveryEmailSchema = () => {
  return {
    schema: z.object({ email: z.string().email() }),
    defaultValues: {
      email: '',
    },
  };
};
