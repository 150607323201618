import { zodResolver } from '@hookform/resolvers/zod';
import { posthogEvents, posthogOptions } from 'constants/posthog';
import { promotionsSchemaKeys, usePromotionsSchema } from 'hooks/schemas/usePromotionsSchema';
import { useDataMapper } from 'hooks/utils/useDataMapper';
import { posthog } from 'posthog-js';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useGetPromotionOptionsQuery } from 'services/form/monthlySettings';
import { addOrEditList, selectCalendar } from 'state/slices/calendarSlice';
import { v4 } from 'uuid';

export const usePromotionForm = ({ onClose, item }) => {
  const dispatch = useDispatch();
  const { data, isLoading } = useGetPromotionOptionsQuery();
  const offerChoice = useDataMapper(data, 'offer_choice_options');
  const saleChoice = useDataMapper(data, 'on_sale_choice_options');
  const {
    selectedDate: { month, year },
  } = useSelector(selectCalendar);
  const [id] = useState(item?.id ?? v4());

  const { schema, defaultValues } = usePromotionsSchema();

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: item?.value ?? defaultValues,
  });

  const onSubmit = data => {
    dispatch(addOrEditList({ month, year, listItem: data, listName: 'promotions', itemId: id }));
    onClose();
    posthog.capture(posthogEvents.pages.calendar.strategy.promotion.SUBMIT, posthogOptions());
  };

  const viewDate = new Date(year, month - 1, 1, 0);
  const minDate = new Date() > viewDate ? new Date() : viewDate;
  const startDate = form.watch(promotionsSchemaKeys.start_date);

  return {
    options: { offerChoice, saleChoice, viewDate, minDate, startDate },
    form,
    onSubmit,
    isLoading,
  };
};
