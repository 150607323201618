import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { Right } from 'assets';
import classNames from 'classnames';
import {
  Button,
  CampaignDetails,
  Donut,
  ErrorCard,
  EventDetails,
  MonthlySettings,
  PlaceHolder,
  ShareDialog,
} from 'components';
import { eventTypes } from 'constants/constants';
import { useCalendarEvents } from 'hooks/calendar/useCalendarEvents';
import { useCompany } from 'hooks/state/useCompany';
import { useHistoryImpl } from 'hooks/utils/useHistoryImpl';
import { useTranslation } from 'hooks/utils/useTranslation';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { routesPaths } from 'routes/routesPaths';
import { useGetUserInfoQuery } from 'services/auth/auth';
import { closeMonthlySettings, selectCalendar, setSelectedEvent } from 'state/slices/calendarSlice';
import styles from './styles.module.scss';
import { selectAuth } from 'services/emailLayout';

export const MainCalendar = ({ month, year, shareToken, className }) => {
  const dispatch = useDispatch();
  const t = useTranslation({ keyPrefix: 'calendar.' });
  const [activeMonthlySettings, setActiveMonthlySettings] = useState(false);
  const { selectedCompany, userCompany, isOwnedCompany } = useCompany();
  const auth = useSelector(selectAuth);
  const { selectedEvent } = useSelector(selectCalendar);
  const { isSuccess } = useGetUserInfoQuery(null, { skip: userCompany || !auth?.user });
  const { events, stats, isError } = useCalendarEvents({ company: selectedCompany, shareToken });
  const { push } = useHistoryImpl();
  const [showShare, setShowShare] = useState(false);

  useEffect(() => {
    if (isSuccess && !userCompany) {
      toast.error(t('errors.getCompany'));
    }
  }, [userCompany, isSuccess, t]);

  if (isError) {
    return <ErrorCard />;
  }

  return (
    <div className={classNames(styles.MainCalendar, className)}>
      <div className={styles.Calendar}>
        <FullCalendar
          initialDate={new Date(year, month - 1)}
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          dayMaxEvents={true}
          moreLinkContent={moreLinkContentArg => (
            <div className="Body Bold Small Red300 text-center">{`${moreLinkContentArg.num} more +`}</div>
          )}
          customButtons={{
            shareButton: {
              text: t('share.cta'),
              click: () => {
                setShowShare(true);
              },
            },
          }}
          headerToolbar={{ left: 'title prev,next', right: 'shareButton', center: '' }}
          dayHeaderClassNames={styles.DayHeader}
          displayEventTime={false}
          eventOrder={(a, b) =>
            b.duration - a.duration ||
            ((!!b.children && 1) || 0) - ((!!a.children && 1) || 0) ||
            a.title.localeCompare(b.title)
          }
          datesSet={dateInfo => {
            const { currentStart } = dateInfo.view;
            if (currentStart.getMonth() + 1 === month && currentStart.getFullYear() === year)
              return;
            dispatch(closeMonthlySettings({ month, year }));
            push(
              routesPaths.calendar +
                '?' +
                new URLSearchParams({
                  year: currentStart.getFullYear(),
                  month: currentStart.getMonth() + 1,
                  company: selectedCompany,
                  ...(shareToken && { token: shareToken }),
                }).toString()
            );
          }}
          eventClick={eventInfo => {
            const { extendedProps, title } = eventInfo.event;

            dispatch(
              setSelectedEvent({
                title,
                parent: { ...extendedProps.parent },
                children: extendedProps.children,
                pk: extendedProps.self.pk,
                description: extendedProps.self.description,
                start: eventInfo.event.startStr,
                end: eventInfo.event.endStr,
                type: extendedProps.self.event_type || eventTypes.campaign,
                isTease: extendedProps.self.isTease,
              })
            );
          }}
          events={events}
        />
      </div>
      <div className={styles.SideColumn}>
        <div className={classNames('SimpleCard', styles.MonthlySettings)}>
          <h1 className="Title ExtraSmall mt-0 mb-0">{t('thisMonth')}</h1>
          <Donut stats={stats} />
          {!!stats.total && (
            <div className={classNames('Body Bold Small Blue100', styles.MonthlySummary)}>
              {stats.info?.map(({ bgColor, label }) => (
                <div className={styles.Reference} key={label}>
                  <div style={{ background: bgColor }} />
                  <div key={label}>{label}</div>
                </div>
              ))}
            </div>
          )}
          <Button
            type="submit"
            disabled={!isOwnedCompany}
            icon={Right}
            title={t('monthlySettings')}
            palette="Orange"
            className={styles.ButtonNext}
            width="100%"
            onClick={() => setActiveMonthlySettings(true)}
          />
        </div>

        <div className={classNames('w-full', styles.SwapOptions)}>
          <PlaceHolder
            text={!events ? t('nothingHere') : t('selectCampaignPlaceholder')}
            textClassName="Body Bold Large"
            className={classNames(styles.Placeholder)}
            active={!selectedEvent}
          >
            {selectedEvent?.type === eventTypes.campaign ? (
              <CampaignDetails campaign={selectedEvent} />
            ) : (
              <EventDetails event={selectedEvent} />
            )}
          </PlaceHolder>
        </div>
        <MonthlySettings
          active={activeMonthlySettings && isOwnedCompany}
          onClose={() => setActiveMonthlySettings(false)}
        />
        {showShare && <ShareDialog visible={showShare} onHide={() => setShowShare(false)} />}
      </div>
    </div>
  );
};
