import { useTranslation } from 'hooks/utils/useTranslation';
import { Button, Dialog, TextCheckbox } from 'components';
import styles from './styles.module.scss';

export const ConfirmDialog = ({
  onConfirm,
  title,
  onClose,
  dontAskAgain,
  visible,
  cancelTitle,
  confirmTitle,
  text,
  ...props
}) => {
  const t = useTranslation();
  return (
    <Dialog
      visible={visible}
      onHide={onClose}
      header={() => <div className="Title Small">{title}</div>}
      {...(dontAskAgain && {
        footer: () => (
          <div className={styles.Dialog__Footer}>
            <TextCheckbox
              title={t('campaignDetails.swap.confirm.dontAskAgain')}
              checked={dontAskAgain.value}
              onChange={dontAskAgain.onClick}
            />
          </div>
        ),
      })}
      {...props}
    >
      {!!text && <p className="Body Regular Medium text-center mt-0">{text}</p>}
      <div className={styles.Dialog__Buttons}>
        <Button
          size="Small"
          palette="Secondary"
          title={cancelTitle}
          className={styles.Dialog__Button}
          onClick={onClose}
        />
        <Button
          size="Small"
          title={confirmTitle}
          className={styles.Dialog__Button}
          onClick={onConfirm}
        />
      </div>
    </Dialog>
  );
};
