import { LoaderHOC, Logo } from 'components';
import { Redirect } from 'react-router-dom';
import { routesPaths } from 'routes/routesPaths';
import { useGetUserInfoQuery } from 'services/auth/auth';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { selectAuth } from 'services/emailLayout';

export const Home = () => {
  const { authenticated } = useSelector(selectAuth);
  const { data, isLoading } = useGetUserInfoQuery(null, { skip: !authenticated });

  return (
    <div className="w-screen h-screen">
      <Logo className={styles.Logo} />
      <LoaderHOC isLoading={isLoading}>
        <Redirect to={!!data?.company ? routesPaths.calendar : routesPaths.welcome} />;
      </LoaderHOC>
    </div>
  );
};
