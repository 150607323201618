import { getIcon } from 'utils/icons';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { Checkbox } from 'primereact/checkbox';
import { useRef } from 'react';

export const ProductType = ({ description, checked, onChange, ...props }) => {
  const ref = useRef(null);

  return (
    <>
      <div
        className={classNames(styles.ProductType, checked && styles.Selected)}
        aria-checked={checked}
        onClick={() => {
          ref?.current.click();
        }}
        tabIndex={0}
        onKeyDown={() => ref.current.click()}
        role="checkbox"
      >
        <span className={classNames('Body', 'Regular', 'Small')}>{description}</span>
        {<img className={styles.Icon} src={getIcon(!checked ? 'Add' : 'Close')} alt="chip icon" />}
        <Checkbox
          className={styles.InputCheckbox}
          checked={checked}
          onChange={onChange}
          inputRef={ref}
          {...props}
        />
      </div>
    </>
  );
};
