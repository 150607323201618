import classNames from 'classnames';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useGetCalendarEventsQuery } from 'services/calendar';
import { selectCalendar } from 'state/slices/calendarSlice';
import styles from 'styles/_export.scss';
import { useStats } from './useStats';

const campaignToEvent =
  ({ color: { dot, text, hover }, parent }) =>
  (campaign, selectedPk) => ({
    title: [campaign.title, parent?.title].filter(Boolean).join(' - '),
    start: campaign.start,
    interactive: true,
    color: dot,
    display: 'list-item',
    textColor: text,
    classNames: classNames('Body', 'Regular', 'Small', hover, {
      selected: campaign.pk === selectedPk,
    }),
    extendedProps: {
      parent,
      self: { ...campaign, isTease: campaign.start.localeCompare(parent?.start) < 0 },
    },
  });

const toCalendarEvent =
  ({ color: { background, text, dot, hover } }) =>
  (event, selectedPk) =>
    [
      {
        title: event.title,
        start: event.start,
        interactive: true,
        end: event.end && event.end !== event.start ? event.end + 'T12:00' : null,
        color: background,
        textColor: text,
        classNames: classNames('Body', 'Bold', 'Small', hover, 'Event', {
          selected: event.pk === selectedPk,
        }),
        extendedProps: {
          self: event,
          children: event.campaigns,
        },
      },
      ...(event.campaigns?.map(campaign =>
        campaignToEvent({ color: { dot, text, hover }, parent: event })(campaign, selectedPk)
      ) || []),
    ];

const calendarTypes = {
  promotion: toCalendarEvent({
    color: {
      background: styles.red200,
      text: styles.red600,
      dot: styles.red500,
      hover: 'PromotionEvent__Campaign',
    },
  }),
  product_launch: toCalendarEvent({
    color: {
      background: styles.green300,
      text: styles.green600,
      dot: styles.green500,
      hover: 'ProdLaunchEvent__Campaign',
    },
  }),
  flow: toCalendarEvent({
    color: {
      background: styles.purple200,
      text: styles.purple600,
      dot: styles.purple500,
      hover: 'FlowEvent__Campaign',
    },
  }),
  campaign: campaignToEvent({
    color: { dot: styles.blue500, text: styles.purple600, hover: 'CampaignEvent' },
  }),
  other: toCalendarEvent({
    color: {
      background: styles.orange200,
      text: styles.orange600,
      dot: styles.orange500,
      hover: 'OtherEvent__Campaign',
    },
  }),
};

const toCalendarEvents = (events, selectedPk) => {
  const eventMapper = event => {
    return (calendarTypes[event.event_type] || calendarTypes.other)(event, selectedPk);
  };

  return events.flatMap(eventMapper);
};

export const useCalendarEvents = ({ company, shareToken }) => {
  const {
    selectedDate: { month, year },
    selectedEvent,
  } = useSelector(selectCalendar);

  const { currentData: currentEvents, error: isErrorCurrent } = useGetCalendarEventsQuery(
    { company, month, year, shareToken },
    { skip: !company || !month || !year }
  );

  const [previousMonth, nextMonth] = useMemo(() => {
    if (year && month) {
      const previous = new Date(year, month - 1);
      const next = new Date(year, month - 1);
      previous.setMonth(previous.getMonth() - 1);
      next.setMonth(next.getMonth() + 1);
      return [previous, next];
    }
    return [null, null];
  }, [year, month]);

  const { currentData: previousEvents, isError: isErrorPrevious } = useGetCalendarEventsQuery(
    {
      company,
      month: previousMonth?.getMonth() + 1,
      year: previousMonth?.getFullYear(),
      shareToken,
    },
    { skip: !company || !previousMonth }
  );
  const { currentData: nextEvents, isError: isErrorNextMonth } = useGetCalendarEventsQuery(
    { company, month: nextMonth?.getMonth() + 1, year: nextMonth?.getFullYear(), shareToken },
    { skip: !company || !nextMonth }
  );

  const totalEvents = useMemo(() => {
    return [...(previousEvents || []), ...(currentEvents || []), ...(nextEvents || [])];
  }, [previousEvents, currentEvents, nextEvents]);

  const calendarEvents = useMemo(() => {
    return !totalEvents?.length ? [] : toCalendarEvents(totalEvents, selectedEvent?.pk);
  }, [totalEvents, selectedEvent?.pk]);

  const stats = useStats(calendarEvents);

  return {
    events: calendarEvents,
    stats,
    isError: isErrorCurrent || isErrorNextMonth || isErrorPrevious,
  };
};
