import { Close } from 'assets';
import classNames from 'classnames';
import { AboutCampaign, AboutParent, Addons, Button, SlidingPanel } from 'components';
import { useEmailBriefForm } from 'hooks/form/useEmailBriefForm';
import { ProgressBar } from 'primereact/progressbar';
import { useEffect } from 'react';
import styles from './styles.module.scss';

export const EmailBrief = ({ active, onClose, campaign, parentType, mustComplete, campaignId }) => {
  const {
    form: { handleSubmit, control, setValue, watch, reset },
    options: { offerChoice, saleChoice, productTypes, isTierDisabled },
    aboutParentTitle,
    t,
    onSubmit,
    isLoading,
  } = useEmailBriefForm({ campaign, parentType, campaignId, onClose });

  useEffect(() => {
    if (!active) reset();
  }, [active, reset]);

  return (
    <SlidingPanel active={active} className={styles.Panel} greyedOut={mustComplete && active}>
      <div className={classNames(styles.EmailBrief)}>
        <div className={styles.Header}>
          <div className="Title Regular Medium">{t('emailLayout.brief.title')}</div>
          {!mustComplete && (
            <button className={styles.Close} onClick={onClose}>
              <img src={Close} alt={t('a11y.close')} />
            </button>
          )}
        </div>
        <div className="Title ExtraSmall">{t('emailLayout.brief.aboutCampaign.title')}</div>
        <AboutCampaign control={control} saleChoice={saleChoice} setValue={setValue} />

        <AboutParent
          parentType={parentType}
          control={control}
          saleChoice={saleChoice}
          offerChoice={offerChoice}
          productTypes={productTypes}
          setValue={setValue}
          watch={watch}
          aboutParentTitle={aboutParentTitle}
          isTierDisabled={isTierDisabled}
        />

        <div className="Separator my-1" />
        <Addons control={control} />
        <form onSubmit={handleSubmit(onSubmit)} className={styles.CTASection} id="emailForm">
          <Button
            title={t('emailLayout.brief.save')}
            palette="Orange"
            size="Small"
            theme="Dark"
            className="w-full"
            type="submit"
            disabled={isLoading}
          />
        </form>
      </div>
      <ProgressBar mode="indeterminate" className="GlobalProgressBar" hidden={!isLoading} />
    </SlidingPanel>
  );
};
