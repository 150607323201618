import { getCacheCalendarEventsTag, tagTypes } from 'constants/constants';

const { endpoints } = require('constants/endpoints');
const { api } = require('./api');

const calendarApi = api.injectEndpoints({
  endpoints: builder => ({
    getCalendarEvents: builder.query({
      query: ({ company, month, year, shareToken }) => ({
        url: `${endpoints.CALENDAR_EVENTS}?${new URLSearchParams({
          year,
          month,
          company,
          token: shareToken,
        }).toString()}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 180,
      transformResponse: months => (months.length && months[0].events) || [],
      providesTags: (result, error, arg) =>
        result ? [getCacheCalendarEventsTag(arg.company, arg.month, arg.year)] : [],
    }),
    getEventDetails: builder.query({
      query: ({ eventId, shareToken }) => ({
        url: `${endpoints.EVENT_DETAILS}${eventId}/${shareToken ? `?token=${shareToken}` : ''}`,
        method: 'GET',
      }),
      providesTags: (result, error, eventId) => {
        return result ? [{ type: tagTypes.EVENT_DETAILS, id: eventId }] : [tagTypes.EVENT_DETAILS];
      },
    }),
    patchEventDetails: builder.mutation({
      query: ({ body: swappedEvent, calendar }) => ({
        url: `${endpoints.EVENT_DETAILS}${swappedEvent.pk}/`,
        method: 'PATCH',
        body: swappedEvent,
      }),
      invalidatesTags: (res, err, arg) => {
        return res
          ? [
              { type: tagTypes.EVENT_DETAILS, id: arg.body.pk },
              getCacheCalendarEventsTag(
                arg.calendar.company,
                arg.calendar.month,
                arg.calendar.year
              ),
            ]
          : [];
      },
    }),
    deleteEvent: builder.mutation({
      query: ({ eventId, calendar }) => ({
        url: `${endpoints.EVENT_DETAILS}${eventId}/`,
        method: 'DELETE',
      }),
      async onQueryStarted(
        { eventId, calendar: { company, month, year } },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled;
          dispatch(
            api.util.updateQueryData('getCalendarEvents', { company, month, year }, draft => {
              const newEvents = draft
                .filter(ev => ev.pk !== eventId)
                .map(ev => ({ ...ev, campaigns: ev.campaigns?.filter(c => c.pk !== eventId) }));
              draft.length = 0;
              Object.assign(draft, newEvents);
            })
          );
        } catch ({ error }) {}
      },
    }),
  }),
});

export const {
  useGetCalendarEventsQuery,
  useGetEventDetailsQuery,
  usePatchEventDetailsMutation,
  useDeleteEventMutation,
  endpoints: {
    patchEventDetails: { matchFulfilled: patchEventDetailsFulfilled },
    deleteEvent: { matchFulfilled: deleteEventFulfilled },
  },
} = calendarApi;
