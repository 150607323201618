import { z } from 'zod';

export const manualLoginSchemaKeys = {
  email: 'email',
  password: 'password',
};

export const useManualLoginSchema = ({ t }) => {
  return {
    schema: z.object({
      email: z.string().email().max(50).min(1, t('login.errors.emailMsg')),
      password: z
        .string()
        .max(50, t('login.errors.passwordMsgMax'))
        .min(8, t('login.errors.passwordMsgMin'))
        .refine(str => /[a-zA-Z]/.test(str), { message: t('login.errors.passwordMsgLetter') })
        .refine(str => /[0-9]/.test(str), { message: t('login.errors.passwordMsgNumber') }),
    }),
    defaultValues: {
      email: '',
      password: '',
    },
  };
};
