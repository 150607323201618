import { getCacheCalendarEventsTag, tagTypes } from 'constants/constants';
import { endpoints } from 'constants/endpoints';
import { api } from 'services/api';

const monthlysettingsApi = api.injectEndpoints({
  endpoints: builder => ({
    getPromotionOptions: builder.query({
      query: () => ({
        url: endpoints.PROMOTION_OPTIONS,
        method: 'GET',
      }),
      providesTags: [tagTypes.PROMOTION_OPTIONS],
    }),
    getProductLaunchOptions: builder.query({
      query: () => ({
        url: endpoints.PRODUCT_LAUNCH_OPTIONS,
        method: 'GET',
      }),
      providesTags: [tagTypes.PRODUCT_LAUNCH_OPTIONS],
    }),
    getMonthlySettings: builder.query({
      query: ({ company, month, year }) => ({
        url:
          endpoints.MONTHLY_SETTINGS +
          '?' +
          new URLSearchParams({ company, month, year }).toString(),
        method: 'GET',
      }),
      transformResponse: res => (res?.length ? res[0] : undefined),
    }),
    putMonthlySettings: builder.mutation({
      query: ({ monthlyInfo }) => ({
        url: endpoints.MONTHLY_SETTINGS,
        method: 'POST',
        body: {
          ...monthlyInfo.data,
          promotions: monthlyInfo.data.promotions.map(({ value }) => value),
          product_launches: monthlyInfo.data.product_launches.map(({ value }) => value),
        },
      }),
      invalidatesTags: (result, err, arg) => {
        const { company, month, year } = arg.monthlyInfo.data;
        return result ? [getCacheCalendarEventsTag(company, month, year)] : [];
      },
    }),
  }),
});

export const {
  useGetProductLaunchOptionsQuery,
  useGetPromotionOptionsQuery,
  useGetMonthlySettingsQuery,
  usePutMonthlySettingsMutation,
  endpoints: {
    getMonthlySettings: { matchFulfilled: getMonthlySettingsFulfilled },
    putMonthlySettings: { matchFulfilled: putMonthlySettingsFulfilled },
  },
} = monthlysettingsApi;
