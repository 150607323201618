import { useDonut } from 'hooks/calendar/useDonut';
import { useTranslation } from 'hooks/utils/useTranslation';
import { Chart } from 'primereact/chart';
import styles from './styles.module.scss';

export const Donut = ({ stats }) => {
  const t = useTranslation();
  const { data, options, plugins } = useDonut(stats, t('calendar.emails'));

  return (
    <div className="card flex justify-content-center my-3">
      <div className={styles.Donut__Container}>
        <Chart type="doughnut" data={data} options={options} plugins={plugins} />
      </div>
    </div>
  );
};
