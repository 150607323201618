import { Link } from 'react-router-dom';

export const i18nToBold =
  (textType, textSize) =>
  (...chunks) =>
    <b className={`${textType} Bold ${textSize}`}>{chunks}</b>;

export const i18nToItalic = (...chunks) => <span className="font-italic">{chunks}</span>;

export const i18nToLink =
  (link, textType, textSize) =>
  (...chunks) =>
    (
      <Link
        className={`${textType} Bold ${textSize} Orange400 no-underline`}
        to={{ pathname: link }}
        target="_blank"
      >
        {chunks}
      </Link>
    );

export const ellipsis = (str, maxLength) => {
  return str.length > maxLength ? str.slice(0, maxLength - 1) + '...' : str;
};
