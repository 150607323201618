import { MenuButton } from 'components';
import { useSelector } from 'react-redux';
import { selectAuth } from 'services/auth/auth';
import { AuthMenubar } from './AuthMenubar';
import { LoginMenubar } from './LoginMenubar';

export const Menubar = ({ theme = 'light' }) => {
  const { authenticated } = useSelector(selectAuth);

  const template = className => (item, options) =>
    <MenuButton item={item} options={options} className={className} theme={theme} />;

  if (authenticated) return <AuthMenubar template={template} theme={theme} />;

  return <LoginMenubar template={template} theme={theme} />;
};
