import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { openOrCloseItem } from 'state/slices/calendarSlice';
import { ExpandItem } from '../ExpandItem';
import { GuideText } from '../GuideText';
import { PlaceHolder } from '../Placeholder';

export const AddToForm = ({ EditComponent, t, list, listName }) => {
  const [showForm, setShowForm] = useState(false);
  const dispatch = useDispatch();

  return (
    <>
      <PlaceHolder
        active={true}
        text={t('placeholder')}
        textClassName="Body Bold Large"
        palette="Orange"
        size="Small"
        className={'mb-3'}
        onClick={() => setShowForm(true)}
      ></PlaceHolder>
      {!list?.length && !showForm && <GuideText text={t('noItem')} />}
      {showForm && <EditComponent onClose={() => setShowForm(false)} t={t} />}
      {list?.map(item => (
        <ExpandItem
          key={item.id}
          text={item.value.name}
          open={item.open}
          listName={listName}
          itemId={item.id}
          onClick={() => dispatch(openOrCloseItem({ open: true, listName, itemId: item.id }))}
        >
          <EditComponent
            t={t}
            item={item}
            onClose={() => dispatch(openOrCloseItem({ open: false, listName, itemId: item.id }))}
          />
        </ExpandItem>
      ))}
    </>
  );
};
