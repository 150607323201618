import variables from 'styles/_export.scss';

export const useDonut = (stats, unit) => {
  const amount = stats.total;

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: !!amount,
      },
    },
    centerText: {
      display: true,
      amount,
      unit,
    },
  };

  const drawInnerText = chart => {
    const { width, height, ctx } = chart;
    const { amount, unit } = chart.config.options.centerText;
    const defaultText = '--';

    const draw = (text, font, offsetY) => {
      ctx.font = font;
      ctx.textBaseline = 'middle';
      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 2 + offsetY;
      ctx.fillStyle = variables.blue100;
      ctx.fillText(text, textX, textY);
    };

    ctx.restore();

    if (amount) {
      draw(amount, variables.fontDonutAmount, -8);
      draw(unit, variables.fontDonutUnit, 24);
    } else {
      draw(defaultText, variables.fontDonutAmount, 0);
    }

    ctx.save();
  };

  const defaultData = {
    labels: ['N/A'],
    datasets: [
      {
        data: [1],
        backgroundColor: [variables.blue500],
        hoverBackgroundColor: [variables.blue500],
        borderWidth: 0,
        cutout: '80%',
        animation: {
          animateRotate: false,
        },
      },
    ],
  };

  return {
    options,
    data: stats.donut || defaultData,
    plugins: [
      {
        beforeDraw: chart => {
          if (chart.config.options.centerText.display) {
            drawInnerText(chart);
          }
        },
      },
    ],
  };
};
