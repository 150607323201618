import { posthogEvents, posthogOptions } from 'constants/posthog';
import { posthog } from 'posthog-js';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteListItem, selectCalendar } from 'state/slices/calendarSlice';

export const useDeleteMonthlySettingsEvent = ({ listName, itemId }) => {
  const dispatch = useDispatch();
  const { selectedDate } = useSelector(selectCalendar);
  const [showDialog, setShowDialog] = useState(false);
  const posthogDelete = {
    promotions: posthogEvents.pages.calendar.strategy.promotion.DELETE,
    product_launches: posthogEvents.pages.calendar.strategy.productLaunch.DELETE,
  };

  const onDeleteItem = () => {
    dispatch(deleteListItem({ ...selectedDate, listName, itemId }));
    posthog.capture(posthogDelete[listName], posthogOptions());
  };

  return {
    onDeleteItem,
    showDialog,
    onClickDelete: () => setShowDialog(true),
    onCloseDialog: () => setShowDialog(false),
  };
};
