import { posthog } from 'posthog-js';
import { useEffect } from 'react';
import { useState } from 'react';

export const FeatureFlagAsync = ({ children, featureKey }) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    posthog.onFeatureFlags(function () {
      setEnabled(posthog.isFeatureEnabled(featureKey));
    });
  }, [featureKey]);

  return enabled ? children : null;
};
