import classNames from 'classnames';
import styles from './styles.module.scss';

export const Overlay = ({ children, active, className }) => {
  if (!active) return;

  return (
    <div
      className={classNames([
        'p-dialog-mask',
        'p-dialog-center',
        'p-component-overlay',
        'p-component-overlay-enter',
        'p-dialog-visible',
        'z-5',
      ])}
    >
      <div className={classNames(styles.Overlay__Wrap, className, { [styles.active]: active })}>
        <div className={styles.Overlay}>{children}</div>
      </div>
    </div>
  );
};
