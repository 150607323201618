import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import {
  ACCESS_TOKEN,
  APPLICATION_JSON,
  CLIENT,
  CONTENT_TYPE,
  MULTIPART_FORMDATA,
  UID,
} from 'constants/headers';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const state = getState();
    const { user } = state.auth;

    if (headers.get(CONTENT_TYPE) === MULTIPART_FORMDATA) {
      headers.delete(CONTENT_TYPE);
    } else {
      if (!headers.has(CONTENT_TYPE)) headers.set(CONTENT_TYPE, APPLICATION_JSON);
    }

    if (user?.token) {
      headers.set(ACCESS_TOKEN, 'Token ' + user.token);
    }
    return headers;
  },
});

export const baseQueryWithReauth = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  const headers = result?.meta?.response?.headers;
  const token = headers?.get(ACCESS_TOKEN);

  if (token) {
    const session = {
      token,
      uid: headers.get(UID),
      client: headers.get(CLIENT),
    };

    if (result.data) result.data.info = session;
  }

  return result;
};
