import { Layout } from 'components';
import { useCompany } from 'hooks/state/useCompany';
import { useQuery } from 'hooks/utils/useQuery';
import { ErrorPage } from 'pages/ErrorPage';
import { MainCalendar } from 'pages/MainCalendar';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { routesPaths } from 'routes/routesPaths';
import { setSelectedCompany, setSelectedDate } from 'state/slices/calendarSlice';
import styles from './styles.module.scss';

export const Calendar = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const { userCompany, isOwnedCompany, selectedCompany } = useCompany();
  const [year, month, company, token] = [
    query.get('year') ? Number(query.get('year')) : new Date().getFullYear(),
    query.get('month') ? Number(query.get('month')) : new Date().getMonth() + 1,
    query.get('company') ? Number(query.get('company')) : userCompany,
    query.get('token'),
  ];

  useEffect(() => {
    dispatch(setSelectedDate({ year, month }));
  }, [year, month, dispatch]);

  useEffect(() => {
    dispatch(setSelectedCompany({ company, token }));
  }, [company, token, dispatch]);

  if (!query.get('year') || !query.get('month') || !Number(query.get('company'))) {
    if (!Number(query.get('company')) && !userCompany) return <Redirect to={routesPaths.login} />;
    return (
      <Redirect
        to={`${routesPaths.calendar}?year=${year}&month=${month}&company=${company}${
          token ? `&token=${token}` : ''
        }`}
      />
    );
  }

  if (selectedCompany && !isOwnedCompany && !token) {
    return <ErrorPage />;
  }

  return (
    <Layout menuTheme={'light'}>
      <div className={styles.Container}>
        <MainCalendar
          year={year}
          month={month}
          company={company}
          shareToken={token}
          className="pt-3"
        />
      </div>
    </Layout>
  );
};
