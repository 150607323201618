import { calendar, chevronDown } from 'assets';
import classNames from 'classnames';
import { Calendar } from 'primereact/calendar';
import styles from './styles.module.scss';

export const DatePicker = ({
  id,
  placeholder,
  onChange,
  value,
  title,
  innerClassName,
  innerRef,
  error,
  minDate,
  appendTo = 'self',
  disabled,
  viewDate,
  showInputIcons = true,
  special,
  filled,
  className,
  ...props
}) => {
  return (
    <div
      className={classNames(
        styles.DatePicker,
        {
          [styles.error]: error,
          [styles.Special]: special,
          [styles.Filled]: filled,
          [styles.Disabled]: disabled,
        },
        className
      )}
    >
      {title && <div className={classNames('Body Bold Small', styles.Title)}>{title}</div>}
      {showInputIcons && <img src={calendar} alt="calendar" className={styles.calendarImg} />}
      <Calendar
        id={id}
        className={classNames('h-full', innerClassName)}
        inputClassName={classNames('Body Bold Medium', styles.Input, {
          [styles.error]: error,
          [styles.Special]: special,
        })}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        ref={innerRef}
        minDate={minDate}
        panelClassName={styles.Panel}
        appendTo={appendTo}
        disabled={disabled}
        viewDate={viewDate}
        {...props}
      />
      {showInputIcons && <img src={chevronDown} alt="dropdown" className={styles.chevronImg} />}
    </div>
  );
};
