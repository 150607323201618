import { Close, zoomIn } from 'assets';
import classNames from 'classnames';
import { useTranslation } from 'hooks/utils/useTranslation';
import { useState } from 'react';
import styles from './styles.module.scss';

export const ImageViewer = ({ images, onClose }) => {
  const t = useTranslation();
  const [zoomed, setZoomed] = useState(false);

  return (
    <div
      className={classNames(styles.ImageViewer, { [styles.zoomed]: zoomed })}
      onClick={e => {
        if (e.target !== e.currentTarget) return;
        onClose();
      }}
      onKeyDown={() => {}}
      role="presentation"
    >
      <button
        className={classNames(styles.Zoomable, { [styles.OneImage]: images.length === 1 })}
        onClick={() => setZoomed(!zoomed)}
      >
        <div className={styles.Images}>
          {images.map(image => (
            <img key={image} src={image} alt={t('a11y.template')} />
          ))}
        </div>
      </button>
      <div className={styles.ImageViewer__Menu}>
        <button onClick={onClose} className={styles.Button}>
          <img src={Close} alt={t('a11y.close')} />
        </button>
        <button onClick={() => setZoomed(!zoomed)} className={styles.Button}>
          <img src={zoomIn} alt={t('a11y.zoom')} />
        </button>
      </div>
    </div>
  );
};
