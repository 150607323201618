import { chevronDown, chevronUp, swap2 } from 'assets';
import classNames from 'classnames';
import { ConfirmDialog, LoaderHOC } from 'components';
import { useSwapEvent } from 'hooks/calendar/useSwapEvent';
import { BlockUI } from 'primereact/blockui';
import styles from './styles.module.scss';

export const SwapOption = ({ className, option, eventDetails }) => {
  const {
    onSwapEvent,
    onClickSwap,
    onCloseDialog,
    onClickDescription,
    onClickDontAskAgain,
    t,
    state: { showConfirmDialog, showDescription, dontAskAgain, isSwapEventLoading, isOwnedCompany },
  } = useSwapEvent();

  return (
    <>
      <div className={classNames(styles.SwapOption, className)}>
        <div className={styles.Option}>
          <div className={styles.Header}>
            <div className={styles.Dot} />
            <div className={classNames('Body Bold Medium Blue600 my-1', styles.CampaignName)}>
              {option.title}
            </div>
            <button disabled={!isOwnedCompany} onClick={onClickDescription}>
              <img
                src={showDescription ? chevronUp : chevronDown}
                alt={t('a11y.expandCampaignOption')}
              />
            </button>
          </div>
          {showDescription && (
            <div className={classNames(styles.Description, 'Body Regular Small Blue500')}>
              {option.description}
            </div>
          )}
        </div>
        <button className={styles.SwapButton} onClick={() => onClickSwap(eventDetails, option)}>
          <img src={swap2} alt={t('a11y.swapOption')} />
        </button>
      </div>
      <ConfirmDialog
        visible={showConfirmDialog}
        title={t('campaignDetails.swap.confirm.title')}
        onClose={onCloseDialog}
        onConfirm={() => onSwapEvent(eventDetails, option, dontAskAgain)}
        dontAskAgain={{ onClick: onClickDontAskAgain, value: dontAskAgain }}
        cancelTitle={t('campaignDetails.swap.confirm.cancel')}
        confirmTitle={t('campaignDetails.swap.confirm.confirm')}
      />
      <BlockUI fullScreen blocked={isSwapEventLoading} template={<LoaderHOC isLoading={true} />} />
    </>
  );
};
