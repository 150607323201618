import { zodResolver } from '@hookform/resolvers/zod';
import { useRecoverPasswordSchema } from 'hooks/schemas/useRecoverPasswordSchema';
import { useHistoryImpl } from 'hooks/utils/useHistoryImpl';
import { useTranslation } from 'hooks/utils/useTranslation';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { routesPaths } from 'routes/routesPaths';
import { useRecoverPasswordMutation } from 'services/auth/auth';

export const useRecoverPasswordForm = ({ uid, token }) => {
  const [recoverPassword, { isLoading, isSuccess }] = useRecoverPasswordMutation();
  const t = useTranslation();
  const { schema, defaultValues } = useRecoverPasswordSchema({ uid, token, t });
  const history = useHistoryImpl();

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const onSubmit = async body => {
    const res = await recoverPassword(body);
    if (res.error) {
      toast.error(t('passwordRecovery.labels.error'));
    } else {
      toast.success(t('passwordRecovery.labels.success'));
      setTimeout(() => history.push(routesPaths.login), 2000);
    }
  };

  return { form, onSubmit: form.handleSubmit(onSubmit), isLoading, isSuccess };
};
