import { Expand, swap2 } from 'assets';
import { Button, ConfirmDialog, ImageViewer, LoaderHOC, Overlay } from 'components';
import { useSwapModule } from 'hooks/calendar/useSwapModule';
import { useTranslation } from 'hooks/utils/useTranslation';
import { BlockUI } from 'primereact/blockui';
import { useState } from 'react';
import styles from './styles.module.scss';
import { useCompany } from 'hooks/state/useCompany';

export const EmailAlternative = ({ alternative, campaignId, moduleId }) => {
  const t = useTranslation();
  const [open, setOpen] = useState(false);
  const { isOwnedCompany } = useCompany();
  const { showConfirmDialog, onCloseDialog, onSwapModule, onClickSwap, isSwapModuleLoading } =
    useSwapModule({
      campaignId,
    });

  return (
    <>
      <div className={styles.EmailAlternative}>
        <div className={styles.Preview}>
          <img
            className={styles.Image}
            src={alternative.preview}
            alt={t('a11y.alternativePreview')}
          />
        </div>
        <Button
          size="Small"
          palette="Secondary"
          theme="Dark"
          disabled={!isOwnedCompany}
          title={t('emailLayout.builder.swap')}
          icon={swap2}
          className={styles.SwapButton}
          onClick={onClickSwap}
        />
        <button className={styles.ViewImage} onClick={() => setOpen(true)}>
          <img src={Expand} alt={t('a11y.expand')} />
        </button>
      </div>
      <Overlay active={open}>
        <ImageViewer images={[alternative.url]} onClose={() => setOpen(false)} />
      </Overlay>
      <ConfirmDialog
        visible={showConfirmDialog}
        title={t('emailLayout.swap.confirm.title')}
        onClose={onCloseDialog}
        onConfirm={() => onSwapModule(moduleId, alternative.pk)}
        cancelTitle={t('emailLayout.swap.confirm.cancel')}
        confirmTitle={t('emailLayout.swap.confirm.confirm')}
      />
      <BlockUI fullScreen blocked={isSwapModuleLoading} template={<LoaderHOC isLoading={true} />} />
    </>
  );
};
