import { posthogEvents, posthogOptions } from 'constants/posthog';
import { useTranslation } from 'hooks/utils/useTranslation';
import { posthog } from 'posthog-js';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useSwapModuleMutation } from 'services/emailLayout';

export const useSwapModule = ({ campaignId }) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [swapModule, { isLoading }] = useSwapModuleMutation();
  const t = useTranslation();

  const onCloseDialog = () => setShowConfirmDialog(false);

  const onClickSwap = () => setShowConfirmDialog(true);

  const onSwapModule = async (currentModule, newModule) => {
    onCloseDialog();
    const res = await swapModule({ currentModule, newModule, campaignId });
    if (res.error) {
      toast.error(t('emailLayout.swap.error'));
    } else {
      toast.success(t('emailLayout.swap.success'));
    }
    posthog.capture(posthogEvents.pages.emailLayout.module.SWAP, posthogOptions());
  };

  return {
    showConfirmDialog,
    onCloseDialog,
    onSwapModule,
    onClickSwap,
    isSwapModuleLoading: isLoading,
  };
};
