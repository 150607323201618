export const parseDateTitle = date => {
  const lang = 'en-US';
  return [
    date.toLocaleString(lang, { month: 'long' }),
    date.toLocaleString(lang, { day: 'numeric' }) + ',',
    date.toLocaleString(lang, { weekday: 'long' }),
  ].join(' ');
};

export const toDateStringGlobal = value =>
  [
    value.getFullYear(),
    `${value.getMonth() + 1}`.padStart(2, '0'),
    `${value.getDate()}`.padStart(2, '0'),
  ].join('-');

export const onDateChange = (field, setValue) => e => {
  const value = e.target.value;
  const newDate = toDateStringGlobal(value);
  if (value)
    setValue(field.name, newDate, {
      shouldValidate: true,
      shouldDirty: true,
    });
};

export const prepareDateString = value => {
  if (!value) return undefined;
  const date = new Date(value.concat('T00:00'));
  return date;
};

export const nth = function (d) {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const formattedDateNth = dateStr => {
  const date = new Date(dateStr);
  date.setDate(date.getDate() + 1);
  return [
    date.toLocaleDateString('en-US', {
      month: 'short',
    }),
    date.getDate() + nth(date.getDate()),
  ].join(' ');
};

export const getCalendarDate = (selectedEvent, selectedDate) => {
  if (selectedEvent.parent?.start) {
    const date = prepareDateString(selectedEvent.parent.start);
    return { year: date.getFullYear(), month: date.getMonth() + 1 };
  } else {
    return { ...selectedDate };
  }
};
