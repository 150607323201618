import { Calendar, Dossier, EmailLayout, ErrorPage, Home, Login, Signup, Welcome } from 'pages';
import { CompanySettings } from 'pages/CompanySettings';
import { RecoverPassword } from 'pages/RecoverPassword';
import { routesPaths } from './routesPaths';

export const routes = [
  {
    path: routesPaths.index,
    component: <Home />,
    exact: true,
    private: true,
  },
  {
    path: routesPaths.login,
    component: <Login />,
    private: false,
  },
  {
    path: routesPaths.signup,
    component: <Signup />,
    private: false,
  },
  {
    path: routesPaths.resetPassword,
    component: <RecoverPassword />,
    private: false,
  },
  {
    path: routesPaths.welcome,
    component: <Welcome />,
    private: true,
    exact: true,
  },
  {
    path: routesPaths.emailLayout,
    component: <EmailLayout />,
    private: false,
  },
  {
    path: routesPaths.calendar,
    component: <Calendar />,
    private: false,
    exact: false,
  },
  {
    path: routesPaths.dossier,
    component: <Dossier />,
    private: true,
    exact: true,
  },
  {
    path: routesPaths.settings,
    component: <CompanySettings />,
    private: true,
    exact: true,
  },
  {
    // Always at the bottom of the list
    path: routesPaths.wildcard,
    component: <ErrorPage />,
    private: false,
  },
];
