import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { posthogEvents, posthogOptions } from 'constants/posthog';
import { posthog } from 'posthog-js';

import {
  getUserInfoFulfilled,
  googleLoginFulfilled,
  loginFulfilled,
  logoutFulfilled,
  signupFulfilled,
} from 'services/auth/auth';
import { getLoggedInUser, removeLoggedInUser, setLoggedInUser } from 'utils/auth';

const loggedInUser = getLoggedInUser();

const defaultState = {
  authenticated: false,
  user: {
    username: null,
    name: null,
    email: null,
    company: null,
    token: null,
    is_staff: null,
    ask_confirmation: null,
  },
};

const initialState = {
  ...defaultState,
  authenticated: !!loggedInUser,
  user: loggedInUser,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: builder => {
    builder.addMatcher(
      isAnyOf(googleLoginFulfilled, loginFulfilled, signupFulfilled),
      (_state, { payload: { key, pk } }) => {
        const user = { token: key, pk };
        setLoggedInUser(user);
        posthog.capture(posthogEvents.pages.login.LOGIN, posthogOptions());
        if (pk) posthog.identify(pk);
        return {
          ..._state,
          authenticated: true,
          user,
        };
      }
    );
    builder.addMatcher(getUserInfoFulfilled, (_state, { payload: userInfo }) => {
      const user = { ..._state.user, ...userInfo };
      setLoggedInUser(user);
      posthog.people.set_once({ email: userInfo.email, url: userInfo.url });
      window.Intercom('boot', {
        app_id: process.env.REACT_APP_INTERCOM_ID,
        email: userInfo.email,
        name: userInfo.name,
        user_id: userInfo.pk,
        company: {
          company_id: userInfo.company,
        },
      });
      return {
        ..._state,
        user,
      };
    });
    builder.addMatcher(logoutFulfilled, () => {
      removeLoggedInUser();
      posthog.reset();
      window.Intercom('shutdown');
      return defaultState;
    });
  },
});

export const actions = authSlice.actions;

export default authSlice.reducer;
