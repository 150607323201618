import { zodResolver } from '@hookform/resolvers/zod';
import { useGoogleLogin } from '@react-oauth/google';
import { useSignupSchema } from 'hooks/schemas/useSignupSchema';
import { useTranslation } from 'hooks/utils/useTranslation';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { api } from 'services/api';
import { useGetUserInfoQuery, useGoogleLoginMutation, useSignupMutation } from 'services/auth/auth';
import { selectAuth } from 'services/emailLayout';
import { handleLoginSignupErrors, loginSignupErrors } from 'utils/loginHelper';

export const useSignupForm = () => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const { defaultValues, schema } = useSignupSchema({ t });
  const [googleLoginBackbone, { isLoading }] = useGoogleLoginMutation();
  const { authenticated } = useSelector(selectAuth);
  useGetUserInfoQuery(null, { skip: !authenticated });
  const [terms, setTerms] = useState(true);
  const [signup, { isLoading: isSignupLoading }] = useSignupMutation();
  const { push } = useHistory();

  const googleSignup = useGoogleLogin({
    onSuccess: ({ code }) =>
      googleLoginBackbone(code).then(({ error }) => {
        if (error) {
          const { error_code, non_field_errors } = error.data;
          loginSignupErrors({ t, non_field_errors, push })[error_code]?.action();
        }
      }),
    onError: () => toast.error(t('login.errors.google')),
    flow: 'auth-code',
  });

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const resetErrors = useCallback(() => dispatch(api.util.resetApiState()), [dispatch]);

  useEffect(() => resetErrors, [resetErrors]);

  const onSubmit = form.handleSubmit(handleLoginSignupErrors(signup, t, push));

  return {
    form,
    googleSignup,
    extraFields: false,
    authenticated,
    isLoading: isLoading || isSignupLoading,
    terms,
    onSubmit,
    toggleTerms: () => setTerms(!terms),
  };
};
