import { Iframe } from 'components';
import { posthogEvents, posthogOptions } from 'constants/posthog';
import { posthog } from 'posthog-js';
import styles from './styles.module.scss';

export const EmbedVideo = ({ src, ...props }) => (
  <div className={styles.Video} {...props}>
    <Iframe
      width="853"
      height="480"
      src={src}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded video"
      onInferredClick={() =>
        posthog.capture(posthogEvents.pages.welcome.DEMO_VIDEO, posthogOptions())
      }
    />
  </div>
);
