import { zodResolver } from '@hookform/resolvers/zod';
import { posthogEvents, posthogOptions } from 'constants/posthog';
import { useMonthlySettingsSchema } from 'hooks/schemas/useMonthlySettingsSchema';
import { useArrayGenerator } from 'hooks/utils/useArrayGenerator';
import { useTranslation } from 'hooks/utils/useTranslation';
import { posthog } from 'posthog-js';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { usePutMonthlySettingsMutation } from 'services/form/monthlySettings';
import { selectCalendar, setEmails } from 'state/slices/calendarSlice';

export const useMonthlySettingsForm = ({ monthlyInfo, onClose }) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const { defaultValues, schema } = useMonthlySettingsSchema();
  const [putMonthlySettings, { isLoading }] = usePutMonthlySettingsMutation();
  const { selectedDate } = useSelector(selectCalendar);
  const monthName = useMemo(() => {
    const date = new Date();
    date.setMonth(selectedDate.month - 1);
    return date.toLocaleString('en-US', { month: 'long' });
  }, [selectedDate.month]);
  const amountOptions = useArrayGenerator(50);

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    const campaigns = monthlyInfo?.data.number_of_campaigns;
    const flows = monthlyInfo?.data.number_of_flows;
    form.setValue('number_of_campaigns', campaigns, {
      shouldTouch: true,
    });
    form.setValue('number_of_flows', flows, {
      shouldTouch: true,
    });
    form.clearErrors(campaigns && 'number_of_campaigns', flows && 'number_of_flows');
  }, [monthlyInfo?.data.number_of_campaigns, monthlyInfo?.data.number_of_flows, form]);

  useEffect(() => {
    form.clearErrors();
  }, [selectedDate.month, selectedDate.year, form]);

  const onSubmit = async data => {
    dispatch(setEmails(data));

    const res = await putMonthlySettings({
      monthlyInfo,
    });

    if (res.error) {
      toast.error(t('monthlySettings.errors.submit'));
    } else {
      toast.success(t('monthlySettings.success'));
      posthog.capture(posthogEvents.pages.calendar.strategy.SUBMIT, posthogOptions());
    }

    onClose();
  };

  return { form, onSubmit, isLoading, options: { monthName, amountOptions } };
};
