import { Dropdown } from 'components';
import { ToggleQuestion } from 'components/form/ToggleQuestion';
import { emailBriefSchemaKeys } from 'hooks/schemas/useEmailBriefSchema';
import { useTranslation } from 'hooks/utils/useTranslation';
import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { prepareField } from 'utils/onboardingSteps';

export const BriefFlow = ({ control, offerChoice, watch, setValue }) => {
  const t = useTranslation();
  const isOffer = watch(emailBriefSchemaKeys.flow.is_offer);

  useEffect(() => {
    const options = { shouldValidate: true, shouldDirty: true };
    setValue(emailBriefSchemaKeys.flow.offer, null, options);
    setValue(emailBriefSchemaKeys.flow.discount_code, false, options);
  }, [isOffer, setValue]);

  return (
    <>
      <Controller
        name={emailBriefSchemaKeys.flow.is_offer}
        control={control}
        render={({ field }) => (
          <ToggleQuestion
            title={t('emailLayout.brief.aboutEvent.flowOffer')}
            tooltipDescription={t('emailLayout.brief.aboutEvent.tooltipFlowOffer')}
            tooltipPosition="left"
            {...prepareField(field)}
          />
        )}
      />
      <Controller
        name={emailBriefSchemaKeys.flow.offer}
        control={control}
        render={({ field, fieldState, formState }) => (
          <Dropdown
            title={t('emailLayout.brief.aboutEvent.flowOfferType')}
            options={offerChoice}
            className="w-full"
            innerClassName="w-full h-4rem"
            id={field.name}
            error={!!fieldState.error && formState.isSubmitted}
            {...prepareField(field)}
            filled={!!field.value}
            disabled={!watch(emailBriefSchemaKeys.flow.is_offer)}
          />
        )}
      />
      <Controller
        name={emailBriefSchemaKeys.flow.discount_code}
        control={control}
        render={({ field }) => (
          <ToggleQuestion
            title={t('emailLayout.brief.aboutEvent.flowDiscountCode')}
            tooltipDescription={t('emailLayout.brief.aboutEvent.tooltipFlowDiscountCode')}
            tooltipPosition="left"
            {...prepareField(field)}
            disabled={!watch(emailBriefSchemaKeys.flow.is_offer)}
          />
        )}
      />
    </>
  );
};
