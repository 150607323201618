import { zodResolver } from '@hookform/resolvers/zod';
import { eventTypes } from 'constants/constants';
import { posthogEvents, posthogOptions } from 'constants/posthog';
import { emailBriefSchemaKeys, useEmailBriefSchema } from 'hooks/schemas/useEmailBriefSchema';
import { useDataMapper } from 'hooks/utils/useDataMapper';
import { useTranslation } from 'hooks/utils/useTranslation';
import { posthog } from 'posthog-js';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { usePatchCampaignLayoutMutation } from 'services/emailLayout';
import {
  useGetProductLaunchOptionsQuery,
  useGetPromotionOptionsQuery,
} from 'services/form/monthlySettings';

export const useEmailBriefForm = ({ campaign, parentType, campaignId, onClose }) => {
  const t = useTranslation();
  const { data: promotionData } = useGetPromotionOptionsQuery();
  const { data: productLaunchData } = useGetProductLaunchOptionsQuery();
  const offerChoice = useDataMapper(promotionData, 'offer_choice_options');
  const saleChoice = useDataMapper(promotionData, 'on_sale_choice_options');
  const productTypes = useDataMapper(productLaunchData, 'product_launch_type_choice_options');
  const { schema, defaultValues } = useEmailBriefSchema(campaign, parentType);
  const [patchCampaignLayout, { isLoading }] = usePatchCampaignLayoutMutation();

  const aboutParentTitle = useMemo(() => {
    if (!parentType) return null;
    const formattedTitle = parentType
      .split('_')
      .map(text => text[0].toUpperCase() + text.substring(1))
      .join(' ');
    return formattedTitle;
  }, [parentType]);

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues,
  });
  const { watch, setValue } = form;

  const onSubmit = async body => {
    const res = await patchCampaignLayout({ body, id: campaignId });
    if (res.error) {
      toast.error(t('emailLayout.brief.submit.error'));
    } else {
      toast.success(t('emailLayout.brief.submit.success'));
      onClose();
      posthog.capture(posthogEvents.pages.emailLayout.brief.SUBMIT, posthogOptions());
    }
  };

  const tiers = watch(emailBriefSchemaKeys.promotion.tiers);

  const selectedOffer = watch(emailBriefSchemaKeys.promotion.offer);

  const tieredDiscount = 'tiered discount';

  const isTierDisabled = useMemo(() => {
    const isTieredDiscountCampaign =
      offerChoice?.find(({ value }) => selectedOffer === value)?.label.toLowerCase() !==
      tieredDiscount;
    return parentType === eventTypes.promotion && isTieredDiscountCampaign;
  }, [offerChoice, parentType, selectedOffer]);

  useEffect(() => {
    if (isTierDisabled && tiers) {
      setValue(emailBriefSchemaKeys.promotion.tiers, null, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [setValue, isTierDisabled, offerChoice, campaign, tiers]);

  return {
    form,
    options: { offerChoice, saleChoice, productTypes, isTierDisabled },
    aboutParentTitle,
    onSubmit,
    t,
    isLoading,
  };
};
