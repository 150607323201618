import { useMemo } from 'react';

export const useArrayGenerator = num => {
  return useMemo(
    () =>
      Array.from({ length: num }, (_, i) => i + 1).map(n => ({
        label: n,
        value: n,
      })),
    [num]
  );
};
