import { ToggleQuestion } from 'components/form/ToggleQuestion';
import { emailBriefSchemaKeys } from 'hooks/schemas/useEmailBriefSchema';
import { useTranslation } from 'hooks/utils/useTranslation';
import { Controller } from 'react-hook-form';
import { prepareField } from 'utils/onboardingSteps';
import styles from '../styles.module.scss';

export const Addons = ({ control }) => {
  const t = useTranslation();

  return (
    <>
      <div className="Title Small">{t('emailLayout.brief.addons.title')}</div>
      <div className={styles.Addons}>
        <Controller
          name={emailBriefSchemaKeys.sms_opt_in}
          control={control}
          render={({ field }) => (
            <ToggleQuestion
              title={t('emailLayout.brief.addons.smsOptIn')}
              tooltipDescription={t('emailLayout.brief.addons.tooltipSmsOptIn')}
              tooltipPosition="left"
              {...prepareField(field)}
            />
          )}
        />
        <Controller
          name={emailBriefSchemaKeys.ongoing_promotion_push}
          control={control}
          render={({ field }) => (
            <ToggleQuestion
              title={t('emailLayout.brief.addons.ongoingPromotionPush')}
              tooltipDescription={t('emailLayout.brief.addons.tooltipOngoingPromotionPush')}
              tooltipPosition="left"
              {...prepareField(field)}
            />
          )}
        />
        <Controller
          name={emailBriefSchemaKeys.new_product_reminder}
          control={control}
          render={({ field }) => (
            <ToggleQuestion
              title={t('emailLayout.brief.addons.newProductReminder')}
              tooltipDescription={t('emailLayout.brief.addons.tooltipNewProductReminder')}
              tooltipPosition="left"
              {...prepareField(field)}
            />
          )}
        />
      </div>
    </>
  );
};
