import { linkStripeSubscription } from 'constants/constants';
import { toast } from 'react-toastify';
import { routesPaths } from 'routes/routesPaths';

export const loginSignupErrors = ({ push, t, errors }) => ({
  ERR000: {
    name: 'INTERNAL_SERVER_ERROR',
    action: () => toast.error(t('login.errors.backend')),
  },
  ERR001: {
    name: 'BAD_REQUEST_ERROR',
    action: () =>
      Object.keys(errors)
        .flatMap(err => errors[err])
        .forEach(err => {
          toast.error(err, { autoClose: 5000 });
        }),
  },
  ERR002: {
    name: 'STRIPE_NO_ACTIVE_ACCOUNT_ERROR',
    action: () => {
      window.open(linkStripeSubscription);
      push(routesPaths.login);
    },
  },
  ERRO003: {
    name: 'GOOGLE_LOGIN_ERROR',
    action: () => toast.error(t('login.errors.google')),
  },
});

export const handleLoginSignupErrors = (apiCall, t, push) => async data => {
  const res = await apiCall(data);
  if (res.error) {
    const { error_code, ...errors } = res.error.data;

    loginSignupErrors({ t, errors, push })[error_code]?.action();
  }
};
