import { DatePicker, Input, Slider } from 'components';
import { aggressiveness } from 'constants/constants';
import { emailBriefSchemaKeys } from 'hooks/schemas/useEmailBriefSchema';
import { useTranslation } from 'hooks/utils/useTranslation';
import { Controller } from 'react-hook-form';
import { onDateChange, prepareDateString } from 'utils/calendarHelper';
import { prepareField } from 'utils/onboardingSteps';
import styles from '../styles.module.scss';

export const AboutCampaign = ({ control, setValue, saleChoice }) => {
  const t = useTranslation();

  return (
    <div className={styles.AboutCampaign}>
      <Controller
        name={emailBriefSchemaKeys.title}
        control={control}
        render={({ field, fieldState }) => (
          <Input
            id={field.name}
            error={!!fieldState.error}
            title={t('emailLayout.brief.aboutCampaign.campaignName')}
            className="w-full"
            inputClassName="w-full"
            {...prepareField(field)}
            filled={!!field.value}
            disabled
          />
        )}
      />

      <Controller
        name={emailBriefSchemaKeys.campaign_type}
        control={control}
        render={({ field, fieldState }) => (
          <Input
            title={t('emailLayout.brief.aboutCampaign.campaignType')}
            id={field.name}
            error={!!fieldState.error}
            className="w-full"
            inputClassName="w-full"
            {...prepareField(field)}
            filled={!!field.value}
            disabled
          />
        )}
      />
      <Controller
        name={emailBriefSchemaKeys.start_date}
        control={control}
        render={({ field, fieldState }) => (
          <DatePicker
            title={t('emailLayout.brief.aboutCampaign.sendDate')}
            id={field.name}
            innerClassName={styles.Field}
            value={prepareDateString(field.value)}
            error={!!fieldState.error}
            onChange={onDateChange(field, setValue)}
            filled={!!field.value}
            disabled
          />
        )}
      />
      <div>
        <div className="Body Bold Small">{t('emailLayout.brief.aboutCampaign.emailLength')}</div>
        <Controller
          name={emailBriefSchemaKeys.email_length}
          control={control}
          render={({ field, fieldState }) => (
            <Slider
              showText={false}
              className="my-2"
              min={aggressiveness.min}
              max={aggressiveness.max}
              id={field.name}
              error={!!fieldState.error}
              {...prepareField(field)}
            />
          )}
        />
      </div>
    </div>
  );
};
