import classNames from 'classnames';
import { useHistoryImpl } from 'hooks/utils/useHistoryImpl';
import { useTranslation } from 'hooks/utils/useTranslation';
import styles from './styles.module.scss';

export const ErrorCard = () => {
  const { goBack } = useHistoryImpl();
  const t = useTranslation();

  return (
    <div className="h-screen">
      <div className={classNames(styles.NotFound, 'text-center p-4')}>
        <div className="Title ExtraLarge pb-3">{t('notFound.title')}</div>
        <div className="Body Bold Large pb-3">{t('notFound.description')}</div>
        <button
          onClick={goBack}
          className={classNames(styles.PreviousBtn, 'Body Bold Large Orange400 no-underline')}
        >
          {t('notFound.goBack')}
        </button>
      </div>
    </div>
  );
};
