import { eventTypes } from 'constants/constants';
import { useTranslation } from 'hooks/utils/useTranslation';
import { useSelector } from 'react-redux';
import { selectCalendar } from 'state/slices/calendarSlice';
import styles from 'styles/_export.scss';
import { prepareDateString } from 'utils/calendarHelper';

export const useStats = events => {
  const t = useTranslation();
  const { selectedDate } = useSelector(selectCalendar);

  const isCurrentMonthCampaign = e => {
    const eventDate = prepareDateString(e.start);
    const { event_type } = e.extendedProps.self;
    return (
      eventDate.getFullYear() === selectedDate.year &&
      eventDate.getMonth() + 1 === selectedDate.month &&
      (!event_type || event_type === eventTypes.campaign)
    );
  };

  const countEachCampaign = (partialSumObj, current) => {
    const { self, parent } = current.extendedProps;
    const eventType = parent?.event_type || self.event_type;
    partialSumObj[eventType] += 1;
    return partialSumObj;
  };

  const campaignCount = events?.filter(isCurrentMonthCampaign).reduce(countEachCampaign, {
    [eventTypes.campaign]: 0,
    [eventTypes.flow]: 0,
    [eventTypes.product_launch]: 0,
    [eventTypes.promotion]: 0,
  });

  const stats = [
    {
      type: eventTypes.campaign,
      count: campaignCount[eventTypes.campaign],
      label: t('calendar.campaigns'),
      bgColor: styles.blue400,
    },
    {
      type: eventTypes.promotion,
      count: campaignCount[eventTypes.promotion],
      label: t('calendar.promotions'),
      bgColor: styles.red500,
    },
    {
      type: eventTypes.product_launch,
      count: campaignCount[eventTypes.product_launch],
      label: t('calendar.productLaunches'),
      bgColor: styles.green500,
    },
    {
      type: eventTypes.flow,
      count: campaignCount[eventTypes.flow],
      label: t('calendar.flows'),
      bgColor: styles.purple500,
    },
  ];
  const total = stats.reduce((acc, stat) => acc + stat.count, 0);

  return {
    total,
    info: stats,
    donut: total && {
      labels: stats.map(({ label }) => label),
      datasets: [
        {
          data: stats.map(({ count }) => count),
          backgroundColor: stats.map(({ bgColor }) => bgColor),
          hoverBackgroundColor: stats.map(({ bgColor }) => bgColor),
          borderWidth: 2,
          borderColor: styles.blue600,
          cutout: '80%',
          animation: {
            animateRotate: false,
          },
        },
      ],
    },
  };
};
