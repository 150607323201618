import classNames from 'classnames';
import { Button, DatePicker, Dropdown, Input, LoaderHOC, Slider, TextCheckbox } from 'components';
import { aggressiveness } from 'constants/constants';
import { useProductLaunchForm } from 'hooks/form/useProductLaunchForm';
import { productLaunchesSchemaKeys } from 'hooks/schemas/useProductLaunchesSchema';
import { Controller } from 'react-hook-form';
import { onDateChange, prepareDateString } from 'utils/calendarHelper';
import { prepareField } from 'utils/onboardingSteps';
import styles from '../styles.module.scss';

export const ProductLaunch = ({ t, onClose, className, item }) => {
  const {
    options: { productTypes, minDate, viewDate },
    form: { handleSubmit, control, setValue, reset },
    isLoading,
    onSubmit,
  } = useProductLaunchForm({ onClose, item });

  return (
    <>
      <LoaderHOC isLoading={isLoading}>
        <form className={classNames(styles.AddCard, className)} onSubmit={handleSubmit(onSubmit)}>
          <div className="Body Bold Small">{t('name.label')}</div>
          <Controller
            name={productLaunchesSchemaKeys.name}
            control={control}
            render={({ field, fieldState }) => (
              <Input
                id={field.name}
                error={!!fieldState.error}
                special
                placeholder={t('name.placeholder')}
                inputClassName={styles.Field}
                {...prepareField(field)}
              />
            )}
          />

          <div className="Body Bold Small">{t('productType.label')}</div>
          <Controller
            name={productLaunchesSchemaKeys.type_of_product}
            control={control}
            render={({ field, fieldState }) => (
              <Dropdown
                id={field.name}
                special
                error={!!fieldState.error}
                placeholder={t('productType.placeholder')}
                innerClassName={styles.Field}
                options={productTypes}
                {...prepareField(field)}
              />
            )}
          />

          <div className="Body Bold Small">{t('launchDate.label')}</div>
          <Controller
            name={productLaunchesSchemaKeys.launch_date}
            control={control}
            render={({ field, fieldState }) => (
              <DatePicker
                id={field.name}
                placeholder={t('launchDate.placeholder')}
                className={styles.Field}
                value={prepareDateString(field.value)}
                error={!!fieldState.error}
                onChange={onDateChange(field, setValue)}
                minDate={minDate}
                viewDate={viewDate}
                special
              />
            )}
          />

          <div className="Body Bold Small">{t('emails.label')}</div>
          <Controller
            name={productLaunchesSchemaKeys.aggressiveness}
            control={control}
            render={({ field, fieldState }) => (
              <Slider
                min={aggressiveness.min}
                max={aggressiveness.max}
                id={field.name}
                error={!!fieldState.error}
                innerClassName={styles.Field}
                {...prepareField(field)}
              />
            )}
          />

          <Controller
            name={productLaunchesSchemaKeys.tease_prior_to_launch}
            control={control}
            render={({ field, fieldState }) => (
              <TextCheckbox
                id={field.name}
                checked={field.value}
                onChange={e => setValue(field.name, e.checked)}
                title={t('tease')}
                error={!!fieldState.error}
              />
            )}
          />

          <Controller
            name={productLaunchesSchemaKeys.limited_edition}
            control={control}
            render={({ field, fieldState }) => (
              <TextCheckbox
                id={field.name}
                checked={field.value}
                onChange={e => setValue(field.name, e.checked)}
                title={t('limitedEdition')}
                error={!!fieldState.error}
              />
            )}
          />
          <div className={styles.CTAGroup}>
            <Button
              type="submit"
              className={classNames(styles.Button, 'mb-2')}
              palette="Primary"
              size="Small"
              title={t('save')}
            />
            <Button
              palette="Secondary"
              size="Small"
              title={t('cancel')}
              className={styles.Button}
              onClick={() => {
                onClose();
                reset();
              }}
            />
          </div>
        </form>
      </LoaderHOC>
    </>
  );
};
