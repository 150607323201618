import { maxNumberField } from 'constants/constants';
import { z } from 'zod';

export const productLaunchesSchemaKeys = {
  name: 'name',
  launch_date: 'launch_date',
  aggressiveness: 'aggressiveness',
  type_of_product: 'type_of_product',
  tease_prior_to_launch: 'tease_prior_to_launch',
  limited_edition: 'limited_edition',
  monthly_setting: 'monthly_setting',
};

export const useProductLaunchesSchema = () => {
  return {
    schema: z.object({
      name: z.string().min(1),
      launch_date: z.string(),
      aggressiveness: z.number().max(maxNumberField),
      type_of_product: z.number(),
      tease_prior_to_launch: z.boolean(),
      limited_edition: z.boolean(),
    }),
    defaultValues: {
      name: '',
      launch_date: null,
      aggressiveness: 1,
      type_of_product: null,
      tease_prior_to_launch: false,
      limited_edition: false,
    },
  };
};
