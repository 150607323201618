import classNames from 'classnames';
import { Logo } from 'components';
import { useHistoryImpl } from 'hooks/utils/useHistoryImpl';
import { useTranslation } from 'hooks/utils/useTranslation';
import { Menubar as Menu } from 'primereact/menubar';
import { routesPaths } from 'routes/routesPaths';
import styles from '../styles.module.scss';

export const LoginMenubar = ({ template, theme }) => {
  const t = useTranslation();
  const { push } = useHistoryImpl();

  const noAuthItems = [
    {
      label: t('menubar.login'),
      template: template('Login'),
      command: () => push(routesPaths.login),
    },
    {
      label: t('menubar.signup'),
      template: template('Signup'),
      command: () => push(routesPaths.signup),
    },
  ];

  return (
    <Menu
      start={<Logo theme={theme} />}
      model={noAuthItems}
      className={classNames(styles.Menubar)}
    />
  );
};
