import classNames from 'classnames';
import { Menubar } from 'components';
import styles from './styles.module.scss';

export const Layout = ({ children, className, menuTheme }) => {
  return (
    <>
      <div className={classNames(styles.Layout, className)}>
        <Menubar theme={menuTheme} />
        <div className={styles.Page}>{children}</div>
      </div>
    </>
  );
};
