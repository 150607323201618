import { linkAlt as link } from 'assets';
import { Button, LoaderHOC } from 'components';
import { useCompany } from 'hooks/state/useCompany';
import { useTranslation } from 'hooks/utils/useTranslation';
import { InputText as Input } from 'primereact/inputtext';
import { toast } from 'react-toastify';
import { useGetShareTokenQuery } from 'services/share';
import { Dialog } from '../Dialog';
import styles from './styles.module.scss';

export const ShareDialog = ({ visible, onHide, ...props }) => {
  const { selectedCompany: company, isOwnedCompany } = useCompany();
  const { data, isLoading, isError } = useGetShareTokenQuery(company, { skip: !isOwnedCompany });
  const t = useTranslation();

  const url = new URL(window.location);
  const token = isOwnedCompany ? data?.token : url.searchParams.get('token');
  url.searchParams.set('token', token);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url);
    toast.success(t('calendar.share.success'));
  };

  return (
    <Dialog
      headerClassName={styles.Header}
      visible={visible}
      onHide={onHide}
      header={t('calendar.share.title')}
      {...props}
    >
      <div className={styles.Content}>
        <LoaderHOC isLoading={isLoading}>
          <div className={styles.Clipboard}>
            {isError ? (
              <div className="Body Large Bold">{t('calendar.share.error')}</div>
            ) : (
              <>
                <Input
                  onFocus={e => {
                    e.target?.select();
                  }}
                  value={url}
                  className={styles.Input}
                />
                <Button
                  size="Small"
                  palette="Orange"
                  title={t('calendar.share.clipboard')}
                  onClick={copyToClipboard}
                  iconPos="Left"
                  icon={link}
                />
              </>
            )}
          </div>
        </LoaderHOC>
      </div>
    </Dialog>
  );
};
