import classNames from 'classnames';
import { ProgressSpinner } from 'primereact/progressspinner';
import './styles.scss';

export const LoaderHOC = ({ children, isLoading, className }) => {
  if (isLoading)
    return (
      <div className={classNames('centered', className)}>
        <ProgressSpinner className="custom-spinner" animationDuration=".5s" />
      </div>
    );

  return children;
};
