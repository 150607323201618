import { settingsSchemaKeys } from 'hooks/schemas/useSettingsSchema';
import { useGetProductTypesOptionsQuery } from 'services/form/settings';

export const useCleanIndustryTypes = ({ setValue, getValues, industry, checked }) => {
  const { data: types } = useGetProductTypesOptionsQuery(industry?.value, {
    skip: !industry || !checked,
  });

  const cleanIndustryTypes = checked => {
    !checked &&
      types?.length &&
      setValue(
        settingsSchemaKeys.industry_types,
        getValues(settingsSchemaKeys.industry_types).filter(v => !types.some(({ pk }) => pk === v)),
        { shouldValidate: true, shouldDirty: true }
      );
  };

  return { cleanIndustryTypes };
};
