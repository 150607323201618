import { useIntl } from 'react-intl';
import { i18nToBold, i18nToItalic } from 'utils/formatHelper';

export const useTranslation = opts => {
  const intl = useIntl();

  return (message, values) =>
    intl.formatMessage(
      { id: (opts?.keyPrefix || '') + message },
      { br: '\n', b: i18nToBold(), italic: i18nToItalic, ...values },
      opts
    );
};
