import { eventTypes } from 'constants/constants';
import styles from '../styles.module.scss';
import { BriefFlow } from './BriefFlow';
import { BriefProdLaunch } from './BriefProdLaunch';
import { BriefPromotion } from './BriefPromotion';

export const AboutParent = ({
  parentType,
  control,
  offerChoice,
  saleChoice,
  productTypes,
  setValue,
  watch,
  aboutParentTitle,
  isTierDisabled,
}) => {
  if (!eventTypes[parentType]) return null;

  const parents = {
    [eventTypes.promotion]: (
      <BriefPromotion
        control={control}
        saleChoice={saleChoice}
        offerChoice={offerChoice}
        setValue={setValue}
        isTierDisabled={isTierDisabled}
      />
    ),
    [eventTypes.product_launch]: (
      <BriefProdLaunch control={control} setValue={setValue} productTypes={productTypes} />
    ),
    [eventTypes.flow]: (
      <BriefFlow control={control} offerChoice={offerChoice} watch={watch} setValue={setValue} />
    ),
  };

  return (
    <>
      {aboutParentTitle && (
        <>
          <div className="Separator my-1" />
          <div className="Title ExtraSmall">{aboutParentTitle}</div>
        </>
      )}
      <div className={styles.AboutEvent}>{parents[parentType]}</div>
    </>
  );
};
