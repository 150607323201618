import { zodResolver } from '@hookform/resolvers/zod';
import classNames from 'classnames';
import { LoaderHOC } from 'components';
import { useSettingsSchema } from 'hooks/schemas/useSettingsSchema';
import { useTranslation } from 'hooks/utils/useTranslation';
import { Card } from 'primereact/card';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useGetSettingsOptionsQuery } from 'services/form/settings';
import { About } from './About';
import { AboutCompany } from './AboutCompany';
import { AboutIndustry } from './AboutIndustry';
import styles from './styles.module.scss';
import { UploadCatalogs } from './UploadCatalogs';

export const Settings = ({ settings, isSaveSettingsLoading, onSubmit, submitCard, className }) => {
  const t = useTranslation();
  const { schema, defaultValues } = useSettingsSchema(settings);
  const {
    data: settingsOptions,
    isLoading: isGetSettingsLoading,
    isError,
  } = useGetSettingsOptionsQuery();

  const { control, handleSubmit, setValue, getValues, formState, register, resetField, watch } =
    useForm({
      resolver: zodResolver(schema),
      defaultValues,
    });

  useEffect(() => {
    if (isError) toast.error(t('dossier.errors.getSettings'));
  }, [isError, t]);

  return (
    <>
      <LoaderHOC isLoading={isSaveSettingsLoading || isGetSettingsLoading || isError}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'contents' }}>
          <Card className={classNames('Card', styles.Card, className)}>
            <About settingsOptions={settingsOptions} control={control} />
            <div className="Separator" />
            <AboutIndustry
              settingsOptions={settingsOptions}
              control={control}
              setValue={setValue}
              getValues={getValues}
              watch={watch}
            />
            <div className="Separator" />
            <UploadCatalogs register={register} resetField={resetField} watch={watch} />
            <div className="Separator" />
            <AboutCompany settingsOptions={settingsOptions} control={control} setValue={setValue} />
          </Card>
          {submitCard(formState)}
        </form>
      </LoaderHOC>
    </>
  );
};
