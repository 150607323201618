import { LoaderHOC } from 'components/hoc/LoaderHOC';
import { ProductType } from 'components/ProductType';
import { settingsSchemaKeys } from 'hooks/schemas/useSettingsSchema';
import { useGetProductTypesOptionsQuery } from 'services/form/settings';
import styles from './styles.module.scss';

export const ProductTypes = ({ industry, field, setValue }) => {
  const { data: productTypes, isLoading, isError } = useGetProductTypesOptionsQuery(industry.value);
  return (
    <LoaderHOC isLoading={isLoading || isError}>
      <div className={styles.ProductTypes}>
        <div className="Separator__Vertical" />
        <div className={styles.ProductTypes__Container}>
          <span className="Body Bold Small">{industry.label}</span>
          <div className={styles.ProductTypes__Types}>
            {productTypes?.map(({ pk, name }) => (
              <ProductType
                key={pk}
                description={name}
                checked={field.value.includes(pk)}
                onChange={e => {
                  setValue(
                    settingsSchemaKeys.industry_types,
                    e.checked ? [...field.value, pk] : field.value.filter(v => v !== pk),
                    { shouldValidate: true, shouldDirty: true }
                  );
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </LoaderHOC>
  );
};
