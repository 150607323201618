import { useCleanIndustryTypes } from 'hooks/form/useCleanIndustryTypes';
import { Checkbox } from '../Checkbox';

export const IndustryCheckbox = ({
  industry,
  setValue,
  getValues,
  onChange,
  checked,
  ...props
}) => {
  const { cleanIndustryTypes } = useCleanIndustryTypes({ setValue, getValues, industry, checked });
  return (
    <Checkbox
      {...props}
      checked={checked}
      onChange={e => {
        cleanIndustryTypes(e.checked);
        onChange(e);
      }}
    />
  );
};
