import { ProductLaunch, Promotion } from 'components';
import { AddToForm } from 'components/common/AddToForm';
import { useMonthlySettingsSlice } from 'hooks/useMonthlySettingsSlice';
import { useTranslation } from 'hooks/utils/useTranslation';
import { TabPanel, TabView } from 'primereact/tabview';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCalendar } from 'state/slices/calendarSlice';
import styles from './styles.module.scss';

export const FlowsSection = () => {
  const t = useTranslation({ keyPrefix: 'monthlySettings.' });
  const tPromotion = useTranslation({ keyPrefix: 'monthlySettings.promotionForm.' });
  const tProductLaunch = useTranslation({ keyPrefix: 'monthlySettings.productLaunchesForm.' });
  const [activeFlowIndex, setActiveFlowIndex] = useState(0);
  const { selectedDate } = useSelector(selectCalendar);
  const monthlySettingsForm = useMonthlySettingsSlice(selectedDate.month, selectedDate.year);

  return (
    <>
      <TabView
        activeIndex={activeFlowIndex}
        onTabChange={({ index }) => setActiveFlowIndex(index)}
        panelContainerClassName={styles.PanelContainer}
        className={styles.TabView}
      >
        <TabPanel header={t('promotions')}>
          <AddToForm
            EditComponent={Promotion}
            t={tPromotion}
            list={monthlySettingsForm?.data.promotions}
            listName="promotions"
          />
        </TabPanel>
        <TabPanel header={t('productLaunches')}>
          <AddToForm
            EditComponent={ProductLaunch}
            t={tProductLaunch}
            list={monthlySettingsForm?.data.product_launches}
            listName="product_launches"
          />
        </TabPanel>
      </TabView>
    </>
  );
};
