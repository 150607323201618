import classNames from 'classnames';
import { Tooltip } from 'components';
import styles from './styles.module.scss';

export const MultipleChoice = ({
  options,
  title,
  tooltipDescription,
  value,
  onChange,
  disabled,
  ...props
}) => {
  return (
    <>
      <div
        className={classNames(styles.MultipleChoice, {
          [styles.Disabled]: disabled,
          [styles.Active]: value,
        })}
      >
        <span className="Body Regular Medium">{title}</span>
        <Tooltip description={tooltipDescription} className="mr-auto" position="left" />
        {options?.map(option => (
          <button
            className={styles.Choice}
            key={option.value}
            onClick={() => !disabled && onChange({ checked: option.value })}
          >
            <div className="Body Bold Small">{option.label}</div>
            <label>
              <input type="checkbox" checked={value === option.value} readOnly {...props} />
              {value === option.value && <div className={styles.Check} />}
            </label>
          </button>
        ))}
      </div>
    </>
  );
};
