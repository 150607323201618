import { arrowUp } from 'assets';
import classNames from 'classnames';
import styles from './styles.module.scss';

export const GuideText = ({ text, className }) => {
  return (
    <div className={classNames(styles.GuideText, className)}>
      <img src={arrowUp} alt="up" />
      <div className="Body Bold Large Blue400">{text}</div>
    </div>
  );
};
