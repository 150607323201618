import classNames from 'classnames';
import { useRef } from 'react';
import { Box } from '../Checkbox/Box';
import styles from './styles.module.scss';

export const TextCheckbox = ({
  id,
  checked,
  onChange,
  title,
  className,
  error,
  onBlur,
  ...props
}) => {
  const ref = useRef(null);

  const onClick = () => ref.current.click();

  return (
    <div
      className={classNames(styles.Container, { [styles.error]: error }, className)}
      onClick={onClick}
      tabIndex={0}
      role="button"
      onKeyDown={onClick}
    >
      <Box
        id={id}
        innerRef={ref}
        checked={checked}
        onChange={onChange}
        onBlur={onBlur}
        {...props}
      />
      <span className="Body Regular Medium px-1 text-left">{title}</span>
    </div>
  );
};
