import { chevronDown } from 'assets';
import classNames from 'classnames';
import { componentStates, posthogEvents, posthogOptions } from 'constants/posthog';
import { useTranslation } from 'hooks/utils/useTranslation';
import { posthog } from 'posthog-js';
import { useState } from 'react';
import styles from './styles.module.scss';

export const EmailNote = ({ note }) => {
  const [showContent, setShowContent] = useState(false);
  const onClickShow = () => {
    posthog.capture(
      posthogEvents.pages.emailLayout.module.CLICK_SUGGESTED_CONTENT,
      posthogOptions({ componentState: showContent ? componentStates.CLOSE : componentStates.OPEN })
    );
    setShowContent(!showContent);
  };
  const t = useTranslation();

  return (
    <>
      <div>
        <div className="Subtitle Regular Large">{note.title}</div>
        <div className="Body Regular Medium mb-3 mt-1">{note.subtitle}</div>
        <button className={styles.ShowContent} onClick={onClickShow}>
          <div className="Body Bold Medium Blue500">
            {t('emailLayout.builder.suggestedContent')}
          </div>
          <img
            src={chevronDown}
            className={classNames(styles.ShowIcon, { [styles.Open]: showContent })}
            alt={t('a11y.showContent')}
          />
        </button>
        {showContent && (
          <div className={styles.Content}>
            {note.suggested_content.split('\n').map((part, i) => (
              <div className="Body Bold Regular Blue500" key={i}>
                {part}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
