import { trashAlt } from 'assets';
import classNames from 'classnames';
import { CampaignLink, ConfirmDialog } from 'components';
import { eventTypes } from 'constants/constants';
import { useDeleteEvent } from 'hooks/calendar/useDeleteEvent';
import { useTranslation } from 'hooks/utils/useTranslation';
import { Tooltip } from 'primereact/tooltip';
import { useMemo } from 'react';
import { ellipsis } from 'utils/formatHelper';
import styles from './styles.module.scss';
import { useCompany } from 'hooks/state/useCompany';

export const EventDetails = ({ event }) => {
  const t = useTranslation();
  const { isOwnedCompany } = useCompany();
  const { onDelete, showDialog, onClickDelete, onCloseDialog } = useDeleteEvent({
    eventId: event.pk,
    startDate: event.start,
  });

  const campaigns = useMemo(
    () => [...event.children].sort((a, b) => a.start.localeCompare(b.start)),
    [event.children]
  );

  return (
    <div className={classNames(styles.EventDetails, styles[eventTypes[event.type || 'other']])}>
      <div className={classNames('mb-3', styles.Title)}>
        {event.title}
        {isOwnedCompany && (
          <button className={styles.Delete} onClick={onClickDelete}>
            <img src={trashAlt} alt={t('a11y.deleteCampaign')} />
          </button>
        )}
      </div>
      <Tooltip target={'.' + styles.Delete} position="top">
        {t('eventDetails.delete.tooltip')}
      </Tooltip>
      <ConfirmDialog
        visible={showDialog}
        title={t('eventDetails.delete.confirm.title')}
        text={t('eventDetails.delete.confirm.text', {
          eventName: ellipsis(event.title, 30),
        })}
        onClose={onCloseDialog}
        onConfirm={onDelete}
        cancelTitle={t('eventDetails.delete.confirm.cancel')}
        confirmTitle={t('eventDetails.delete.confirm.confirm')}
      />
      <div>
        <span className="Body Regular Small Blue500 mr-1">{t('eventDetails.knowMore')}</span>
        <a
          href="https://www.longplaybrands.com/"
          className="Body Bold Small Orange400 no-underline"
        >
          {t('eventDetails.learnMore')}
        </a>
      </div>
      <div className="Body Regular Medium Blue600 my-3">{event.description}</div>
      <div className={styles.Details}>
        {campaigns?.map(campaign => (
          <CampaignLink event={event} campaign={campaign} key={campaign.pk} />
        ))}
      </div>
    </div>
  );
};
