export const featureFlagKeys = {
  BB_MAINTENANCE_MODE: 'BB_MAINTENANCE_MODE',
  BB_DELETE_EMAIL_LAYOUT_MODULE: 'BB_DELETE_EMAIL_LAYOUT_MODULE',
  BB_SHARE_EMAIL_LAYOUT: 'BB_SHARE_EMAIL_LAYOUT',
  BB_MANUAL_SIGN_IN: 'BB_MANUAL_SIGN_IN',
};

export const defaultFeatureFlags = {
  [featureFlagKeys.BB_MAINTENANCE_MODE]: false,
  [featureFlagKeys.BB_DELETE_EMAIL_LAYOUT_MODULE]: false,
  [featureFlagKeys.BB_SHARE_EMAIL_LAYOUT]: false,
  [featureFlagKeys.BB_MANUAL_SIGN_IN]: false,
};
