import { MAX_LENGTH_PASSWORD, MIN_LENGTH_PASSWORD } from 'constants/constants';
import { z } from 'zod';

export const useRecoverPasswordSchema = ({ uid, token, t }) => {
  return {
    schema: z
      .object({
        new_password1: z
          .string()
          .max(MAX_LENGTH_PASSWORD, t('login.errors.passwordMsgMax'))
          .min(MIN_LENGTH_PASSWORD, t('login.errors.passwordMsgMin'))
          .refine(str => /[a-zA-Z]/.test(str), { message: t('signup.errors.passwordMsgLetter') })
          .refine(str => /[0-9]/.test(str), { message: t('signup.errors.passwordMsgNumber') }),
        new_password2: z.string().min(1, { message: t('signup.errors.password') }),
        uid: z.string(),
        token: z.string(),
      })
      .refine(({ new_password1, new_password2 }) => new_password1 === new_password2, {
        message: t('signup.errors.passwordMatch'),
        path: ['new_password2'],
      }),
    defaultValues: {
      new_password1: '',
      new_password2: '',
      uid,
      token,
    },
  };
};
