import classNames from 'classnames';
import { useTranslation } from 'hooks/utils/useTranslation';
import { Slider as PrimeSlider } from 'primereact/slider';
import styles from './styles.module.scss';

export const Slider = ({
  value,
  onChange,
  min,
  max,
  step,
  innerRef,
  showText = true,
  className,
}) => {
  const t = useTranslation();
  const amountNotches = (max - min) / (step || 1);
  return (
    <div className={classNames(styles.SliderContainer, className)}>
      {showText && <span className="Body Bold Small">{t('global.few')}</span>}
      <span className="Body Bold Large ml-2">-</span>
      <div className={styles.SliderNotches}>
        <PrimeSlider
          value={value}
          onChange={e => onChange(e.value)}
          min={min}
          max={max}
          step={step}
          ref={innerRef}
          defaultValue={min}
          className={styles.Slider}
        />
        <div className={styles.Notches}>
          {[...Array(amountNotches)].map((_, i) => (
            <div className={styles.Notch} key={i} />
          ))}
        </div>
      </div>
      <span className="Body Bold Large mr-2">+</span>
      {showText && <span className="Body Bold Small">{t('global.many')}</span>}
    </div>
  );
};
