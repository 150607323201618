import { zodResolver } from '@hookform/resolvers/zod';
import { posthogEvents, posthogOptions } from 'constants/posthog';
import { useProductLaunchesSchema } from 'hooks/schemas/useProductLaunchesSchema';
import { useDataMapper } from 'hooks/utils/useDataMapper';
import { posthog } from 'posthog-js';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useGetProductLaunchOptionsQuery } from 'services/form/monthlySettings';
import { addOrEditList, selectCalendar } from 'state/slices/calendarSlice';
import { v4 } from 'uuid';

export const useProductLaunchForm = ({ onClose, item }) => {
  const { data, isLoading } = useGetProductLaunchOptionsQuery();
  const { schema, defaultValues } = useProductLaunchesSchema();
  const {
    selectedDate: { month, year },
  } = useSelector(selectCalendar);
  const productTypes = useDataMapper(data, 'product_launch_type_choice_options');
  const dispatch = useDispatch();
  const [id] = useState(item?.id ?? v4());

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: item?.value ?? defaultValues,
  });

  const onSubmit = data => {
    dispatch(
      addOrEditList({ month, year, listItem: data, listName: 'product_launches', itemId: id })
    );
    onClose();
    posthog.capture(posthogEvents.pages.calendar.strategy.productLaunch.SUBMIT, posthogOptions());
  };

  const viewDate = new Date(year, month - 1, 1, 0);
  const minDate = new Date() > viewDate ? new Date() : viewDate;

  return {
    options: { productTypes, minDate, viewDate },
    onSubmit,
    isLoading,
    form,
  };
};
