import { DatePicker, Dropdown, Input } from 'components';
import { MultipleChoice } from 'components/form/MultipleChoice';
import { ToggleQuestion } from 'components/form/ToggleQuestion';
import { multipleChoiceTiersOptions } from 'constants/constants';
import { emailBriefSchemaKeys } from 'hooks/schemas/useEmailBriefSchema';
import { useTranslation } from 'hooks/utils/useTranslation';
import { Controller } from 'react-hook-form';
import { onDateChange, prepareDateString } from 'utils/calendarHelper';
import { prepareField } from 'utils/onboardingSteps';
import styles from '../../styles.module.scss';

export const BriefPromotion = ({ control, setValue, offerChoice, saleChoice, isTierDisabled }) => {
  const t = useTranslation();
  return (
    <>
      <Controller
        name={emailBriefSchemaKeys.promotion.name}
        control={control}
        render={({ field, fieldState }) => (
          <Input
            title={t('emailLayout.brief.aboutEvent.promotionName')}
            className="w-full"
            inputClassName="w-full"
            id={field.name}
            error={!!fieldState.error}
            {...prepareField(field)}
            filled={!!field.value}
            disabled
          />
        )}
      />
      <div className={styles.Two}>
        <Controller
          name={emailBriefSchemaKeys.promotion.offer}
          control={control}
          render={({ field, fieldState }) => (
            <Dropdown
              title={t('emailLayout.brief.aboutEvent.saleType')}
              options={offerChoice}
              id={field.name}
              error={!!fieldState.error}
              className="w-full"
              innerClassName="w-full h-4rem"
              filled={!!field.value}
              {...prepareField(field)}
              disabled
            />
          )}
        />
        <Controller
          name={emailBriefSchemaKeys.promotion.on_sale}
          control={control}
          render={({ field, fieldState }) => (
            <Dropdown
              title={t('emailLayout.brief.aboutEvent.productType')}
              options={saleChoice}
              id={field.name}
              error={!!fieldState.error}
              className="w-full"
              innerClassName="w-full h-4rem"
              filled={!!field.value}
              {...prepareField(field)}
              disabled
            />
          )}
        />
      </div>
      <div className={styles.Two}>
        <Controller
          name={emailBriefSchemaKeys.promotion.start_date}
          control={control}
          render={({ field, fieldState }) => (
            <DatePicker
              title={t('emailLayout.brief.aboutEvent.dateFrom')}
              className="w-full"
              id={field.name}
              innerClassName={styles.Field}
              value={prepareDateString(field.value)}
              error={!!fieldState.error}
              onChange={onDateChange(field, setValue)}
              filled={!!field.value}
              disabled
            />
          )}
        />
        <Controller
          name={emailBriefSchemaKeys.promotion.end_date}
          control={control}
          render={({ field, fieldState }) => (
            <DatePicker
              title={t('emailLayout.brief.aboutEvent.dateTo')}
              className="w-full"
              id={field.name}
              innerClassName={styles.Field}
              value={prepareDateString(field.value)}
              error={!!fieldState.error}
              onChange={onDateChange(field, setValue)}
              filled={!!field.value}
              disabled
            />
          )}
        />
      </div>
      <Controller
        name={emailBriefSchemaKeys.promotion.discount_code}
        control={control}
        render={({ field }) => (
          <ToggleQuestion
            title={t('emailLayout.brief.aboutEvent.discountCode')}
            tooltipDescription={t('emailLayout.brief.aboutEvent.tooltipDiscountCode')}
            tooltipPosition="left"
            {...prepareField(field)}
          />
        )}
      />
      <Controller
        name={emailBriefSchemaKeys.promotion.tiers}
        control={control}
        render={({ field }) => (
          <MultipleChoice
            title={t('emailLayout.brief.aboutEvent.tiersAmount')}
            tooltipDescription={t('emailLayout.brief.aboutEvent.tooltipTiersAmount')}
            options={multipleChoiceTiersOptions(2, 5)}
            value={field.value}
            onChange={e => setValue(field.name, e.checked, { shouldValidate: true })}
            disabled={isTierDisabled}
          />
        )}
      />
    </>
  );
};
