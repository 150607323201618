import { Close, Upload } from 'assets';
import classNames from 'classnames';
import { Button } from 'components';
import { useTranslation } from 'hooks/utils/useTranslation';
import { useMemo } from 'react';
import { useRef } from 'react';
import styles from './styles.module.scss';
const MAX_LENGTH_FILENAME = 40;

export const FileUpload = ({
  accept,
  title,
  palette,
  register,
  resetField,
  name,
  watch,
  ...props
}) => {
  const hiddenInputRef = useRef(null);
  const t = useTranslation();
  const { onChange, onBlur, ref, name: formName } = register(name);
  const currentFile = watch(name)?.[0];
  const fileName = useMemo(() => {
    const name = currentFile?.name;
    if (!name) return null;
    const viewName =
      name.length > MAX_LENGTH_FILENAME
        ? name.substring(0, MAX_LENGTH_FILENAME).concat('...')
        : name;
    return viewName;
  }, [currentFile]);

  const handleClick = event => {
    event.preventDefault();
    hiddenInputRef.current.click();
  };

  return (
    <div className={styles.FileUpload}>
      <div className={styles.Header}>
        <h4 className={classNames('Body Bold Large mb-2 mt-0 flex')}>{title}</h4>
      </div>
      <div className="flex flex-start align-items-center">
        <div className="Body Large mr-auto">{fileName ?? 'No File Selected'}</div>
        <div className={styles.Buttons}>
          <Button
            palette={!currentFile ? 'Secondary' : 'Orange'}
            className={classNames('w-fit')}
            contentClassName="text-overflow-ellipsis"
            onClick={handleClick}
            title={t('global.upload')}
            iconPos="Left"
            icon={Upload}
          />
          <Button
            palette="Secondary"
            disabled={!currentFile}
            className={classNames('w-fit h-fit p-2 border-circle')}
            onClick={() => resetField(name)}
            iconPos="Left"
            icon={Close}
          />
          <input
            onChange={onChange}
            onBlur={onBlur}
            className="hidden"
            type="file"
            accept={accept}
            name={formName}
            {...props}
            ref={e => {
              ref(e);
              hiddenInputRef.current = e;
            }}
          />
        </div>
      </div>
    </div>
  );
};
